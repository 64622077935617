 
import React, { useEffect, useState } from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { Grid } from '@mui/material';
import Widget from '../../../components/Widget';
import FolderBlueDark from '../Icons/FolderBlueDark';
import FolderPessa from '../Icons/FolderPessa';
import FolderPessaDark from '../Icons/FolderPessaDark';
import FolderPessa2 from '../Icons/FolderPessa2';
import FolderPessa3 from '../Icons/FolderPessa3';
import FolderPessa4 from '../Icons/FolderPessa4';
import FolderPessa5 from '../Icons/FolderPessa5';
import FolderPessa6 from '../Icons/FolderPessa6';
// import AdministracionSettings from '../Icons/AdministracionSettings';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    '& .react-swipeable-view-container': {
      transition:
        'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important',
    },
    paddingBottom: 0,
  },
  codeComponent: {
    flexGrow: 1,
    borderRadius: '10px',
    backgroundColor: 'red',
    border: '3px solid black',
    '&:: -webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 7px rgba(0,0,0,.3)',
      borderRadius: '10px',
      backgroundColor: '#F5F5F5',
    },
    '&:: -webkit-scrollbar': {
      width: '5px',
      backgroundColor: '#F5F5F5',
      borderRadius: '10px',
    },
    '&:: -webkit-scrollbar-thumb': {
      width: '4px',
      height: '5px',
      borderRadius: '10px',
      '-webkit-box-shadow': 'inset 0 0 7px rgba(0,0,0,.3)',
      backgroundColor: 'white',
    },
  },
  padding: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  folderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function CustomizedAdministracion({ classes }) {
  const theme = useTheme();
  const [tienePermiso, setTienePermiso] = useState(false);

  useEffect(() => {
    setTienePermiso(
      sessionStorage.getItem('level') === '1'  
        ? true
        : false,
    );
 
  }, []);

  return (
    <Widget>
      <Grid container>
        <Grid margin={2}>
          {/* <AdministracionSettings
              title='Catálogo Maestro'
              link='/#/app/administracion/catalogo-maestro'
            /> */}
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Catálogo Maestro'
              link='#/app/administracion/catalogo-maestro'
            />
          ) : (
            // <FolderPessa
            //   title='Catálogo Maestro'
            //   link='/#/app/administracion/catalogo-maestro'
            // />
            <FolderPessa
              title='Catálogo Maestro'
              link='#/app/administracion/catalogo-maestro'
            />
          )}
        </Grid>
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Catálogo de Clientes'
              link='#/app/administracion/catalogo-clientes'
            />
          ) : (
            <FolderPessa2
              title='Catálogo de Clientes'
              link='#/app/administracion/catalogo-clientes'
            />
          )}
        </Grid>
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Catálogo de Proveedores'
              link='#/app/administracion/catalogo-proveedores'
            />
          ) : (
            <FolderPessa3
              title='Catálogo de Proveedores'
              link='#/app/administracion/catalogo-proveedores'
            />
          )}
        </Grid>
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Catálogo de Transportistas'
              link='#/app/administracion/catalogo-transportistas'
            />
          ) : (
            <FolderPessa4
              title='Catálogo de Transportistas'
              link='#/app/administracion/catalogo-transportistas'
            />
          )}
        </Grid>
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Catálogo de Productos'
              link='#/app/administracion/catalogo-productos'
            />
          ) : (
            <FolderPessa5
              title='Catálogo de Productos'
              link='#/app/administracion/catalogo-productos'
            />
          )}
        </Grid> 
        {tienePermiso ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Usuarios'
              link='#/app/administracion/usuarios'
            />
          ) : (
            <FolderPessa6
              title='Usuarios'
              link='#/app/administracion/usuarios'
            />
          )}
        </Grid> 
        ) : <Grid margin={2}> </Grid> }
      </Grid>
    </Widget>
  );
}

export default withStyles(styles)(CustomizedAdministracion);
