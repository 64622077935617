import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = () => {
  return (
    <div className='spinner-container'>
      <div className='spinner'></div>
    </div>
  );
};

export default LoadingScreen;
