import React, { useEffect, useState } from 'react';
import ViewInfoCatalogo from 'components/ViewInfo/ViewInfoCatalogo';
import { Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Fab } from '@mui/material';
import axios from 'axios';
import Widget from 'components/Widget/Widget';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function CatalogoClientes() {
  const [loading, setLoading] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(null);
  const [edit, setEdit] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    marca: '',
    nombre: '',
    razonsoc: '',
    rfc: '',
  };
  const [registro, setRegistro] = useState({ ...initialRegistro });
  const [documentos, setDocumentos] = useState([]);

  const columns = [
    { label: 'ID', name: 'clienteid', options: { display: false } },
    { label: 'Marca', name: 'marca' },
    { label: 'Nombre', name: 'nombre' },
    { label: 'Razón Social', name: 'razonsoc' },
    { label: 'RFC', name: 'rfc' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <Edit />
            </Fab>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);
    axios
      .post('/administracion/cliente/detail', registro)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumentos();
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        // limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = documentos.find((x) => x.clienteid === id);
    setRegistro({
      ...registro,
      detailid: id,
      marca: detalle.marca || '',
      nombre: detalle.nombre || '',
      razonsoc: detalle.razonsoc || '',
      rfc: detalle.rfc || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/administracion/delete/cliente/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/administracion/cliente/elementos', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            {error ? <AlertError message={error} /> : ''}
            <ViewInfoCatalogo
              title='Catálogo de Clientes'
              columns={columns}
              data={documentos}
              formato='catalogoClientes'
              edit={edit}
              setEdit={setEdit}
              handleSubmit={handleSubmit}
              initialRegistro={initialRegistro}
              registro={registro}
              setRegistro={setRegistro}
              handleDelete={handleDelete}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              error={error}
              state={state}
              dispatch={dispatch}
              getDocumentos={getDocumentos}
              id={'clienteid'}
            />
          </Widget>
        </>
      )}
    </>
  );
}

export default CatalogoClientes;
