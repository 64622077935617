import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
  } from '@mui/material';
  import axios from 'axios';
  import AlertError from 'components/AlertError/AlertError';
  import React, { useEffect } from 'react';
  import { useState } from 'react';
 
  
  const ModalUser = ({
    open,
    // title = 'Nuevo Registro',
    edit,
    formato = 'fecha',
    handleSubmit,
    dispatch,
    registro,
    setRegistro, 
    setSignFile,
    load = false,
    err = null,
    setErr = null,
    classes
  }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null); 
    const [tipoRol, setTipoRol] = useState([]); 
    const fileInput = React.useRef(null);
  
 
    // COMBO TIPO DE PRODUCTO
 
  
    const getTipoRol = () => {
      setLoading(true);
      try {
        axios
          .get('catalogo/roles', {})
          .then((res) => {
            if (res !== null) {
              setTipoRol(res.data.data);
              setLoading(false);
            }
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      } catch (error) {
        setError(error);
      }
    };
  
    const handleFile = (e) => {
      setSignFile( e.target.files[0]);
    }
   
    const tipoRolProps = {
      options: tipoRol.map((option) => ({
        id: option.id,
        name: option.nombre,
      })),
      getOptionLabel: (option) => option.name,
    };
    
    const handleOptionChange = (changeEvent) => {
      setRegistro({
        ...registro,
        avatar: changeEvent.target.value,
      });
    };

  
    useEffect(() => { 
      getTipoRol(); 
    }, []);
  
    return (
      <>
        <Dialog
          open={open}
          onClose={() => {
            setErr(null);
            dispatch({ type: 'CLOSE_INPUT_MODAL' });
          }}
          aria-labelledby='form-dialog-title'
        >
          {loading || load ? (
            <Box sx={{ m: 20 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <DialogTitle id='form-dialog-title'>
                {edit===1 ? 'Editar Registro' : (edit===2 ? 'Cambiar Password ' : 'Nuevo Registro')}
              </DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container spacing={2}>
                    {error || err ? (
                      <Grid item xs={12}>
                        <AlertError message={error || err} />
                      </Grid>
                    ) : (
                      ''
                    )}
                    {
                      { 
                        NuevoUsuario: (
                          <>
                            <Grid item xs={12}>
                              <TextField
                                id='cuenta'
                                label='Cuenta'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    cuenta: e.target.value,
                                  })
                                }
                                name='cuenta'
                                value={registro.cuenta || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id='nombre'
                                label='Nombre'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    nombre: e.target.value,
                                  })
                                }
                                name='nombre'
                                value={registro.nombre || ''}
                              />
                            </Grid>

                            <Grid item xs={12}>
                                    <Autocomplete 
                                      fullWidth
                                      id='rold'
                                      {...tipoRolProps}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          label='Rol'
                                        />
                                      )}
                                      value={registro.rolid || null}
                                      onChange={(event, newValue) => {
                                        setRegistro({
                                          ...registro,
                                          rolid: newValue,
                                        });
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                  <label>
                                      <input
                                        type="radio"
                                        value="M"
                                        checked={registro.avatar === 'M'}
                                        onChange={handleOptionChange}
                                      />
                                      Avatar Hombre
                                    </label>

                                    <label>
                                      <input
                                        type="radio"
                                        value="F"
                                        checked={registro.avatar === 'F'}
                                        onChange={handleOptionChange}
                                      />
                                      Avatar Mujer
                                    </label>

                                  </Grid>

                            <Grid item xs={12}>

                            <TextField
                              label='Password'
                              type='password'
                              variant='outlined'
                              style={{ marginBottom: 35 }} 
                              value={registro.password || ''}
                              name='Password'
                              onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    password: e.target.value,
                                  })
                                }
                                    />
                            
                            </Grid>
                          </>
                        ) ,
                        CatalogoUsuarios: (
                          <>
                            <Grid item xs={12}>
                              <TextField
                                id='cuenta'
                                label='Cuenta'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    cuenta: e.target.value,
                                  })
                                }
                                name='cuenta'
                                value={registro.cuenta || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id='nombre'
                                label='Nombre'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    nombre: e.target.value,
                                  })
                                }
                                name='nombre'
                                value={registro.nombre || ''}
                              />
                            </Grid>

                            <Grid item xs={12}>
                                    <Autocomplete 
                                      fullWidth
                                      id='rolid'
                                      {...tipoRolProps}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          label='Rol'
                                        />
                                      )}
                                      
                                      value={registro.rolid || null}
                                      onChange={(event, newValue) => {
                                        setRegistro({
                                          ...registro,
                                          rolid: newValue,
                                        });
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                    />
                                  </Grid>

                                  <Grid item xs={12}>
                                  <label>
                                      <input
                                        type="radio"
                                        value="M"
                                        checked={registro.avatar === 'M'}
                                        onChange={handleOptionChange}
                                      />
                                      Avatar Hombre
                                    </label>

                                    <label>
                                      <input
                                        type="radio"
                                        value="F"
                                        checked={registro.avatar === 'F'}
                                        onChange={handleOptionChange}
                                      />
                                      Avatar Mujer
                                    </label>

                                  </Grid>
                             
                          </>
                        ) ,
                        ChangePassword: (
                          <>
                          <TextField
                              label='Password'
                              type='password'
                              variant='outlined'
                              
                              value={registro.password || ''}
                              name='ChangePassword'
                              onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    password: e.target.value,
                                  })
                                }
                                    />
                          </>
                        ) ,
                        Firma: (
                          <>
                          <label
                               
                              style={{ cursor: 'pointer' }} >
                              {'Upload  Firma'}
                              <input
                                style={{ display: 'none' }}
                                accept='image/*'
                                type='file'
                                ref={fileInput} 
                                onChange={handleFile}
                              />
                            </label> 
                          </>
                        )     
                      }[formato]
                    }
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setErr(null);
                      dispatch({ type: 'CLOSE_INPUT_MODAL' });
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button color='primary' variant='contained' type='submit'>
                    Guardar
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Dialog>
      </>
    );
  };
  
  export default ModalUser;
  