import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { isEditable } from 'functions';
import { Delete, FileUpload, Visibility } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function ReclamacionProveedor() {
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef();
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [unidadMedida, setUnidadMedida] = useState([]);
  const [materiaPrima, setMateriaPrima] = useState([]);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEnd: false,
  });
  const [header, setHeader] = useState({
    reclamoaprovid: 0,
    reclamoaproc: '',
    fecha: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    reportenom: '',
    reporta: '',
    lugarinc: '',
    proveedor: '',
    totalrecl: '',
    unidadmedid: 0,
    unidadmed: '',
    disposicionfinprod: '',
    descripcionnoconf: '',
    horaini: '',
    horafin: '',
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    reclamoaprovid: id,
    materiaprim: null,
    descripcionprod: '',
    identificacionprod: '',
    cantidadprod: 0,
    unidadmed: null,
  });
  const [terminar, setTerminar] = useState({
    userid: sessionStorage.getItem('user_id'),
    id: id,
    accion: 0,
    totalprodrecl: '',
    unidadmed: null,
  });

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Materia Prima', name: 'materiaprim' },
    { label: 'Producto', name: 'descripcionprod' },
    { label: 'Identificador', name: 'identificacionprod' },
    { label: 'Cantidad', name: 'cantidadprod' },
    { label: 'Unidad de Medida', name: 'unidadmed' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'nomarchivo' },
    {
      label: 'Ver',
      name: 'id',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target='_blank'>
              <Fab color='primary' size='small'>
                <Visibility />
              </Fab>
            </a>
          );
        },
      },
    },
  ];

  const materiaPrimaProps = {
    options: materiaPrima.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const unidadMedidaProps = {
    options: unidadMedida.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getMateriaPrima = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/tipomateriaprima', {})
        .then((res) => {
          // if (res !== null) {
          setMateriaPrima(res.data?.data);
          setError(null);
          setLoading(false);
          // }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getUnidadMedida = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/unidadesmedida', {})
        .then((res) => {
          // if (res !== null) {
          setUnidadMedida(res.data?.data);
          setError(null);
          setLoading(false);
          // }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/laboratorio/reclamoaprov/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setArchivos(res.data?.data?.Files);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleUpdateHeader = (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      reportenom: header.reportenom,
      reporta: header.reporta,
      lugarinc: header.lugarinc,
      proveedor: header.proveedor,
      disposicionfinprod: header.disposicionfinprod,
      descripcionnoconf: header.descripcionnoconf,
    };

    axios
      .post('/laboratorio/reclamoaprov/header', body)
      .then((res) => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      materiaprim: { id: detalle.materiaprimid, name: detalle.materiaprim },
      descripcionprod: detalle.descripcionprod,
      identificacionprod: detalle.identificacionprod,
      cantidadprod: detalle.cantidadprod,
      unidadmed: { id: detalle.unidadmedid, name: detalle.unidadmed },
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/reclamoaprov/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = (e) => {
    e.preventDefault();
    const body = {
      ...terminar,
      unidadmedid: terminar.unidadmed.id,
    };
    axios
      .post('/laboratorio/reclamoaprov/document/finalizar', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push('/app/etapas/laboratorio');
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
    //   }
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      ...registro,
      unidadmedid: registro.unidadmed.id,
      materiaprimid: registro.materiaprim.id,
    };

    axios
      .post('/laboratorio/reclamoaprov/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.dataTransfer.files[i].name,
        type: e.dataTransfer.files[i].type,
        file: e.dataTransfer.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        file: e.target.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleDeleteFile = (id) => {
    const aux = files;
    for (let i = 0; i < aux.length; i++) {
      if (aux[i].id == id) {
        aux.splice(i, 1);
      }
    }
    setFiles([...aux]);
  };

  const handleSubmitArchivos = async () => {
    if (files.length > 0) {
      setLoading(true);
      let err = false;
      let message = '';

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('files', files[i].file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        await axios
          .post(`/upload/reclamacion-proveedor/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 'error') {
              err = true;
              message = res.data?.message;
            } else {
              err = false;
              setFiles(null);
            }
            getRegistro();
            setLoading(false);
          })
          .catch((error) => {
            err = true;
            message = error.response?.data?.message;
            setLoading(false);
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se han guardado los archivos exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'No se han cargado archivos.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
  };

  const getTotalReclamos = () => {
    let total = 0;
    for (let i = 0; i < detalleRegistro.length; i++) {
      total += parseFloat(detalleRegistro[i].cantidadprod);
    }
    return total;
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      reclamoaprovid: id,
      materiaprim: null,
      descripcionprod: '',
      identificacionprod: '',
      cantidadprod: 0,
      unidadmedid: null,
    });
  };

  const limpiarTerminar = () => {
    setTerminar({
      ...terminar,
      userid: sessionStorage.getItem('user_id'),
      id: id,
      accion: 0,
      totalprodrecl: '',
      unidadmed: null,
    });
  };

  useEffect(() => {
    getUnidadMedida();
    getMateriaPrima();
    getRegistro();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.reclamoaproc}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                {/* <Grid item lg={4} xs={6}>
                  <TextField
                    label='Reporte'
                    fullWidth
                    disabled
                    value={header.reportenom}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Reporta'
                    fullWidth
                    disabled
                    value={header.reporta}
                  />
                </Grid> */}
                {/* <Grid item lg={8} xs={12}>
                  <TextField
                    label='Lugar Incidente'
                    fullWidth
                    disabled
                    value={header.lugartinc}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Proveedor'
                    fullWidth
                    disabled
                    value={header.proveedor}
                  />
                </Grid> */}
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Total'
                    fullWidth
                    disabled
                    value={header.totalrecl}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Unidad de Medida'
                    fullWidth
                    disabled
                    value={header.unidadmed}
                  />
                </Grid>
                {/* <Grid item lg={4} xs={6}>
                  <TextField
                    label='Disposición'
                    fullWidth
                    disabled
                    value={header.disposicionfinprod}
                  />
                </Grid> */}
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Typography variant='h3' color='text' noWrap sx={{ mt: 1.5 }}>
                Información Reporte
              </Typography>
              <form onSubmit={handleUpdateHeader}>
                <Grid container spacing={2} sx={{ mt: 1.5 }}>
                  <Grid item lg={4} xs={6}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='reportenom'
                      name='reportenom'
                      label='Reporte'
                      value={header.reportenom || ''}
                      onChange={(e) => {
                        setHeader({ ...header, reportenom: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} xs={6}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='reporta'
                      name='reporta'
                      label='Reporta'
                      value={header.reporta || ''}
                      onChange={(e) => {
                        setHeader({ ...header, reporta: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='proveedor'
                      name='proveedor'
                      label='Proveedor'
                      value={header.proveedor || ''}
                      onChange={(e) => {
                        setHeader({ ...header, proveedor: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='lugarinc'
                      name='lugarinc'
                      label='Lugar del Incidente'
                      value={header.lugarinc || ''}
                      onChange={(e) => {
                        setHeader({ ...header, lugarinc: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='disposicionfinprod'
                      name='disposicionfinprod'
                      label='Disposición'
                      value={header.disposicionfinprod || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          disposicionfinprod: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='descripcionnoconf'
                      name='descripcionnoconf'
                      label='Descripción de la No Conformidad'
                      multiline
                      minRows={2}
                      value={header.descripcionnoconf || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          descripcionnoconf: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                {!disabledDoc ? (
                  <Box sx={{ float: 'right', mt: 2 }}>
                    <Button variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reclamación a Proveedor
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistro();
                        setEdit(false);
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='materiaprim'
                                  {...materiaPrimaProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Materia Prima'
                                    />
                                  )}
                                  value={registro.materiaprim}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      materiaprim: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='descripcionprod'
                                  label='Descripción del Producto'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      descripcionprod: e.target.value,
                                    })
                                  }
                                  name='descripcionprod'
                                  value={registro.descripcionprod || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='identificacionprod'
                                  label='Identificador del Producto'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      identificacionprod: e.target.value,
                                    })
                                  }
                                  name='identificacionprod'
                                  value={registro.identificacionprod || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number', min: 0 }}
                                  id='cantidadprod'
                                  label='Cantidad'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      cantidadprod: e.target.value,
                                    })
                                  }
                                  name='cantidadprod'
                                  value={registro.cantidadprod || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='unidadmed'
                                  {...unidadMedidaProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Unidad de Medida'
                                    />
                                  )}
                                  value={registro.unidadmed}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      unidadmed: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .id,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Grid item xs={12}>
              <Widget>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap>
                    Evidencias
                  </Typography>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        setEdit(false);
                        handleSubmitArchivos();
                      }}
                    >
                      Guardar Archivos
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ margin: '0 auto' }}>
                      <div
                        className={`dropzone ${drag ? 'drag' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragEnd}
                        onDrop={handleDrop}
                      >
                        <input
                          type='file'
                          onChange={(e) => handleUpload(e)}
                          hidden
                          multiple
                          ref={inputRef}
                        />
                        <Fab
                          color='primary'
                          variant='extended'
                          aria-label='upload'
                          size='small'
                          onClick={() => inputRef.current.click()}
                        >
                          <FileUpload /> Cargar Archivo
                        </Fab>
                        <Typography
                          sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                        >
                          o arrastra los archivos
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      {files?.map((file) => (
                        <Chip
                          key={file.name}
                          label={file.name}
                          onDelete={() => handleDeleteFile(file.id)}
                        ></Chip>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <Widget>
              <Typography
                variant='h3'
                color='text'
                noWrap
                sx={{ mt: 2, mb: 2 }}
              >
                Archivos
              </Typography>
              <MUIDataTable
                title='Archivos'
                data={archivos}
                columns={columnsFiles}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={() => {
                    limpiarTerminar();
                    setTerminar({
                      ...terminar,
                      totalprodrecl: getTotalReclamos(),
                      unidadmed: header.unidadmedid
                        ? {
                            id: header.unidadmedid,
                            name: header.unidadmed,
                          }
                        : null,
                    });
                    dispatch({ type: 'OPEN_MODAL_END' });
                  }}
                >
                  Actualización Estatus
                </Button>
              </Box>
              <Dialog
                open={state.toggleModalEnd}
                onClose={() => {
                  limpiarTerminar();
                  dispatch({ type: 'CLOSE_MODAL_END' });
                }}
                aria-labelledby='form-dialog-title'
              >
                {loading ? (
                  <Box sx={{ m: 20 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <DialogTitle id='form-dialog-title'>
                      Actualización Estatus
                    </DialogTitle>
                    <form onSubmit={handleTerminar}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={8}>
                            {/* <Typography variant='h6'>Acción</Typography> */}
                            <ToggleButtonGroup
                              disabled={disabledDoc}
                              color='primary'
                              value={terminar.accion || '0'}
                              exclusive
                              onChange={(e, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  accion: newValue,
                                });
                              }}
                              aria-label='Acción'
                            >
                              <ToggleButton value='0'>En Proceso</ToggleButton>
                              <ToggleButton value='1'>Cerrado</ToggleButton>
                              <ToggleButton value='2'>Rechazado</ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled
                              inputProps={{ type: 'number', min: 0 }}
                              id='totalprodrecl'
                              label='Total'
                              fullWidth
                              name='totalprodrecl'
                              value={terminar.totalprodrecl || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={disabledDoc}
                              fullWidth
                              id='unidadmed'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida'
                                />
                              )}
                              value={terminar.unidadmed}
                              onChange={(event, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  unidadmed: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            limpiarTerminar();
                            dispatch({ type: 'CLOSE_MODAL_END' });
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          // onClick={handleTerminar}
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Dialog>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default ReclamacionProveedor;
