import React from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ModalRMP from 'components/ModalRMP/ModalRMP';
import { Link } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const ViewInfo = ({
  title,
  columns,
  data,
  handleSubmit,
  recepcion,
  setRecepcion,
  loading,
  loadingSave = false,
  error,
  setError,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            '& a': {
              textDecoration: 'none',
              color: '#fff',
            },
          }}
        >
          <Typography variant='h3' color='text' noWrap>
            {title}
          </Typography>
          <Button
            variant='contained'
            sx={{ float: 'right' }}
            onClick={() => dispatch({ type: 'OPEN_INPUT_MODAL' })}
          >
            Nuevo
          </Button>
          <ModalRMP
            open={state.toggleInputModal}
            dispatch={dispatch}
            handleSubmit={handleSubmit}
            recepcion={recepcion}
            setRecepcion={setRecepcion}
            err={error}
            setErr={setError}
            load={loadingSave}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading ? <LinearProgress /> : ''}
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ responsive: 'standard', selectableRows: false }}
        />
      </Grid>
    </Grid>
  );
};

export default ViewInfo;
