import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, formatTime, isEditable } from 'functions';
import { AccessTime, Delete } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function TiemposMuertos() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [horaParo, setHoraParo] = useState(null);
  const [maquina, setMaquina] = useState([]);
  const [lineaProd, setLineaProd] = useState([]);
  const [causa, setCausa] = useState([]);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const [header, setHeader] = useState({
    tiempomuerid: 0,
    tiempomuer: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    documentoid: 0,
    fecha: '',
    fechaprod: '',
    horaarract: '',
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    tiempomuerid: id,
    linea: null,
    maquina: null,
    causa: null,
    notas: '',
    horaini: null,
    horafin: null,
    observaciones: '',
  });

  const columns = [
    {
      label: 'Id',
      name: 'tiempomuerid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Línea Producción', name: 'linea' },
    { label: 'Máquina', name: 'maquina' },
    { label: 'Causa', name: 'causa' },
    { label: 'Notas', name: 'notas' },
    {
      label: 'Hora Inicia',
      name: 'horaini',
      options: {
        customBodyRender: (value) => {
          return value ? formatTime(value) : null;
        },
      },
    },
    {
      label: 'Hora Fin',
      name: 'horafin',
      options: {
        customBodyRender: (value) => {
          return value ? formatTime(value) : null;
        },
      },
    },
    { label: 'Tiempo Total (minutos)', name: 'tiempotot' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const lineaProdProps = {
    options: lineaProd.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const maquinaProps = {
    options: maquina.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const causaProps = {
    options: causa.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineaProd = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/lineaprod', {})
        .then((res) => {
          if (res !== null) {
            setLineaProd(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getMaquina = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/maquina', {})
        .then((res) => {
          if (res !== null) {
            setMaquina(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getCausa = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/causa', {})
        .then((res) => {
          if (res !== null) {
            setCausa(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/enlatado/tiempom/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      ...registro,
      lineaid: registro.linea.id,
      maquina: registro.maquina.id,
      causaid: registro.causa.id,
      horaini: registro.horaini
        ? registro.horaini.$d
          ? formatDatetime(registro.horaini.$d)
          : formatDatetime(registro.horaini)
        : null,
      horafin: registro.horafin
        ? registro.horafin.$d
          ? formatDatetime(registro.horafin.$d)
          : formatDatetime(registro.horafin)
        : null,
    };

    axios
      .post('/enlatado/tiempom/detail', body)
      .then((res) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.tiempomuerid === id);
    setRegistro({
      ...registro,
      detailid: id,
      linea: { id: detalle.lineaid, name: detalle.linea },
      maquina: { id: detalle.maquinaid, name: detalle.maquina },
      causa: { id: detalle.causaid, name: detalle.causa },
      notas: detalle.notas,
      horaini: detalle.horaini ? new Date(detalle.horaini) : null,
      horafin: detalle.horafin ? new Date(detalle.horafin) : null,
      observaciones: detalle.observaciones,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/enlatado/delete/tiempom/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleTerminar = (e) => {
    // Swal.fire({
    //   title: '¿Está seguro de terminar el proceso?',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Sí',
    //   confirmButtonColor: '#196b86',
    // }).then((result) => {
    //   if (result.isConfirmed) {

    e.preventDefault(e);
    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      horaparo: horaParo
        ? horaParo.$d
          ? formatDatetime(horaParo.$d)
          : formatDatetime(horaParo)
        : null,
    };
    console.log(body);

    axios
      .post('/enlatado/tiempom/document/finalizar', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push('/app/etapas/enlatado');
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      });
    //   }
    // });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      tiempomuerid: id,
      linea: null,
      maquina: null,
      causa: null,
      notas: '',
      horaini: null,
      horafin: null,
      observaciones: '',
    });
  };

  useEffect(() => {
    getLineaProd();
    getMaquina();
    getCausa();
    getRegistro();
  }, []);
  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.tiempomuer}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Arranque'
                    fullWidth
                    disabled
                    value={formatTime(header.horaarract)}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Tiempos Muertos
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistro();
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='linea'
                                  {...lineaProdProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Línea de Producción'
                                    />
                                  )}
                                  value={registro.linea}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      linea: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='maquina'
                                  {...maquinaProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Máquina'
                                    />
                                  )}
                                  value={registro.maquina}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      maquina: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='causa'
                                  {...causaProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Causa'
                                    />
                                  )}
                                  value={registro.causa}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      causa: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  // inputProps={{ type: 'number', min: 0 }}
                                  id='notas'
                                  label='Notas'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      notas: e.target.value,
                                    })
                                  }
                                  name='notas'
                                  value={registro.notas || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horaini: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora Inicio'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      value={
                                        registro.horaini
                                          ? dayjs(registro.horaini)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horaini: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horafin: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora Fin'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      value={
                                        registro.horafin
                                          ? dayjs(registro.horafin)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horafin: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='observaciones'
                                  name='observaciones'
                                  label='Observaciones'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      observaciones: e.target.value,
                                    })
                                  }
                                  value={registro.observaciones || ''}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .tiempomuerid,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={() => {
                    setHoraParo(null);
                    dispatch({ type: 'OPEN_MODAL_END' });
                  }}
                >
                  Terminar
                </Button>
              </Box>
              <Dialog
                open={state.toggleModalEnd}
                onClose={() => {
                  setHoraParo(null);
                  dispatch({ type: 'CLOSE_MODAL_END' });
                }}
                aria-labelledby='form-dialog-title'
              >
                {loading ? (
                  <Box sx={{ m: 20 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <DialogTitle id='form-dialog-title'>Terminar</DialogTitle>
                    <form onSubmit={handleTerminar}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora actual'>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setHoraParo(dayjs(new Date()));
                                  }}
                                >
                                  <AccessTime />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  required
                                  id='horafin'
                                  name='horafin'
                                  label='Hora Fin'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={horaParo ? dayjs(horaParo) : null}
                                  onChange={(time) => {
                                    setHoraParo({ time });
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            setHoraParo(null);
                            dispatch({ type: 'CLOSE_MODAL_END' });
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          // onClick={handleTerminar}
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Dialog>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default TiemposMuertos;
