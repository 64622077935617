import { Delete, Edit, SkipNext } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Step,
  StepButton,
  Stepper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: true,
      };
    case 'CLOSE_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: false,
      };
    case 'OPEN_MODAL_LOTE':
      return {
        ...state,
        toggleModalLote: true,
      };
    case 'CLOSE_MODAL_LOTE':
      return {
        ...state,
        toggleModalLote: false,
      };
    default:
      return null;
  }
};

function EvaluacionCuarentena() {
  const { id } = useParams();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalEdit: false,
    toggleModalLote: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsL, setSelectedRowsL] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [etapaEv, setEtapaEv] = useState(false);
  const [header, setHeader] = useState({
    evaluacioncuarenpt: '',
    fecha: '',
    fechaetiq: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    estatusev: '',
    presentacionenv: '',
    fechainiinc: '',
    fechaevaest: '',
    horaini: '',
    horafin: '',
    // Datos para el update
    muestrasincdup: 0,
    almacenacontramues: 0,
    evaluacion: '',
  });
  const initialLote = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluacioncuarenptid: id,
    loteprod: null,
    marcaid: null,
    productoid: null,
  };
  const [lote, setLote] = useState({ ...initialLote });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluacioncuarenptid: id,
    procesotermid: null,
    // procesotermdetid: null,
    denominacionprodid: null,
  };
  const [registro, setRegistro] = useState({ ...initialRegistro });
  const initialEvaluacion = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluacioncuarenptid: id,
    procesotermid: null,
    procesotermdetid: null,
    denominacionprodid: null,
    phreg: 0,
    apariencia: 0,
    color: 0,
    olor: 0,
    gasesp: '1',
    gradohin: '1',
    evaluacion: '0',
    observaciones: '',
  };
  const [evaluacion, setEvaluacion] = useState({ ...initialEvaluacion });
  const [detalles, setDetalles] = useState([]);
  const [detallesLotes, setDetallesLotes] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [autoclaves, setAutoclaves] = useState([]);
  const [denominaciones, setDenominaciones] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [productos, setProductos] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Autoclave', name: 'procautoclave' },
    { label: 'Denominación Producto', name: 'denominacionprod' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsLotes = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Marca', name: 'marca' },
    { label: 'Producto', name: 'producto' },
  ];

  const columnsEvaluacion = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Autoclave', name: 'procautoclave' },
    { label: 'Denominación Producto', name: 'denominacionprod' },
    { label: 'PH', name: 'phreg' },
    { label: 'Apariencia', name: 'apariencia' },
    { label: 'Color', name: 'color' },
    { label: 'Olor', name: 'olor' },
    { label: 'Presencia de Gas o Espuma', name: 'gasesp' },
    { label: 'Grado de Hinchazón', name: 'gradohin' },
    { label: 'Evaluación', name: 'evaluacion' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditEvaluacion(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...registro,
      procesotermid: registro.procesotermid.id,
      procesotermdetid: registro.procesotermid.detid,
      denominacionprodid: registro.denominacionprodid.id,
    };

    axios
      .post('/laboratorio/evacuarentena/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: evaluacion.userid,
      detailid: evaluacion.detailid,
      phreg: evaluacion.phreg,
      apariencia: evaluacion.apariencia,
      color: evaluacion.color,
      olor: evaluacion.olor,
      gasesp: evaluacion.gasesp,
      gradohin: evaluacion.gradohin,
      evaluacion: evaluacion.evaluacion,
      observaciones: evaluacion.observaciones,
    };

    axios
      .post('/laboratorio/evacuarentena/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitLote = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...lote,
      registrodiaelaid: lote.loteprod.id,
      fechaprod: header.fechaprod,
      marcaid: lote.marcaid.id,
      productoid: lote.productoid.id,
      loteprod: lote.loteprod.name,
    };

    axios
      .post('/laboratorio/evacuarentena/lote/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL_LOTE' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_LOTE' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleUpdateHeader = () => {
    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      muestraincdup: header.muestrasincdup,
      almacenacontramues: header.almacenacontramues,
      evaluacion: header.evaluacion,
    };

    axios
      .post('/laboratorio/evacuarentena/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      procesotermid:
        {
          // id: detalle.procesotermid,
          detid: detalle.procesotermdetid,
          name: detalle.procautoclave,
        } || null,
      denominacionprodid:
        { id: detalle.denominacionprodid, name: detalle.denominacionprod } ||
        null,
    });
  };

  const handleEditEvaluacion = (id) => {
    dispatch({ type: 'OPEN_MODAL_EDIT' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: id,
      procesotermid:
        {
          id: detalle.procesotermid,
          detid: detalle.procesotermdetid,
          name: detalle.procautoclave,
        } || null,
      phreg: detalle.phreg || 0,
      apariencia: detalle.apariencia || 0,
      color: detalle.color || 0,
      olor: detalle.olor || 0,
      gasesp: detalle.gasespid > 0 ? detalle.gasespid : '1' || '1',
      gradohin: detalle.gradohinid > 0 ? detalle.gradohinid : '1' || '1',
      evaluacion: detalle.evaluacionid || '0',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/evacuarentena/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleDeleteLote = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/evacuarentena/lote/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEndRegistro = () => {
    Swal.fire({
      title: '¿Está seguro de concluir el registro?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/laboratorio/evacuarentena/document/guardar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡Ha concluido la etapa de Registro!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            setActiveStep(2);
            getDocumento();
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/laboratorio/evacuarentena/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/laboratorio');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/laboratorio/evacuarentena/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
          setEtapaEv(res.data.data.Header?.estatusevid === '27' ? true : false);
          setActiveStep(res.data.data.Header?.estatusevid === '27' ? 2 : 1);
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setDetallesLotes(res.data.data?.Lotes);
        getLoteProd(res.data.data?.Header?.fechaprod);
        getAutoclaves(res.data.data?.Header?.fechaprod);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getAutoclaves = (fechaprod) => {
    axios
      .get(`/laboratorio/evacuarentena/procesosautoclave/${fechaprod}`, {})
      .then((res) => {
        setAutoclaves(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const autoclavesProps = {
    options: autoclaves.map((option) => ({
      id: option.procesotermid,
      detid: option.procesotermdetid,
      name: option.procautoclave,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getDenominaciones = () => {
    axios
      .get('/catalogo/denominaprod', {})
      .then((res) => {
        setDenominaciones(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const denominacionesProps = {
    options: denominaciones.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getMarcas = () => {
    setLoading(true);
    axios
      .get('/catalogo/clientes', {})
      .then((res) => {
        setMarcas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const marcasProps = {
    options: marcas.map((option) => ({
      id: option.Id,
      name: option.Marca,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = () => {
    setLoading(true);
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  useEffect(() => {
    getDocumento();
    getDenominaciones();
    getMarcas();
    getProductos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 2 }}>
              <Step key='registro'>
                <StepButton onClick={() => setActiveStep(1)}>
                  Registro
                </StepButton>
              </Step>
              <Step key='cuarentena'>
                <StepButton onClick={() => setActiveStep(etapaEv ? 2 : 1)}>
                  Cuarentena
                </StepButton>
              </Step>
            </Stepper>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.evaluacioncuarenpt}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus Evaluación'
                    fullWidth
                    disabled
                    value={header.estatusev}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Presentación'
                    fullWidth
                    disabled
                    value={header.presentacionenv}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Inicio de Incubación'
                    fullWidth
                    disabled
                    value={header.fechainiinc}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Evaluación Estimada'
                    fullWidth
                    disabled
                    value={header.fechaevaest}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {activeStep === 1 ? (
            <>
              <Grid item xs={12}>
                <Widget>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      mb: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Lotes
                    </Typography>
                    <Box sx={{ float: 'right', mr: 1.5 }}>
                      {!disabledDoc ? (
                        <>
                          {/* {!etapaEv ? ( */}
                          <Grid item lg={6} xs={12}>
                            <Button
                              disabled={disabledDoc}
                              variant='contained'
                              onClick={() => {
                                setLote({ ...initialLote });
                                setEdit(false);
                                dispatch({ type: 'OPEN_MODAL_LOTE' });
                              }}
                            >
                              Nuevo
                            </Button>
                          </Grid>
                          {/* ) : (
                            ''
                          )} */}
                        </>
                      ) : (
                        ''
                      )}
                    </Box>
                    {/* MODAL ALTA DE LOTES */}
                    <Dialog
                      open={state.toggleModalLote}
                      onClose={() => {
                        dispatch({ type: 'CLOSE_MODAL_LOTE' });
                      }}
                      aria-labelledby='form-dialog-title'
                      fullWidth
                      maxWidth='md'
                    >
                      {loading ? (
                        <Box sx={{ m: 20 }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <DialogTitle id='form-dialog-title'>
                            {edit ? 'Editar Registro' : 'Nuevo Registro'}
                          </DialogTitle>
                          <form onSubmit={handleSubmitLote}>
                            <DialogContent>
                              <Grid container spacing={2}>
                                {error ? (
                                  <Grid item xs={12}>
                                    <AlertError message={error} />
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                <Grid item xs={12}>
                                  <Autocomplete
                                    disabled={disabledDoc}
                                    // disabled={disabledDoc || etapaEv}
                                    fullWidth
                                    id='loteprod'
                                    {...loteProdProps}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required
                                        label='Lote Producción'
                                      />
                                    )}
                                    value={lote.loteprod || null}
                                    onChange={(event, newValue) => {
                                      setLote({
                                        ...lote,
                                        loteprod: newValue,
                                      });
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    disabled={disabledDoc}
                                    // disabled={disabledDoc || etapaEv}
                                    fullWidth
                                    id='marcaid'
                                    {...marcasProps}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required
                                        label='Marca'
                                      />
                                    )}
                                    value={lote.marcaid || null}
                                    onChange={(event, newValue) => {
                                      setLote({
                                        ...lote,
                                        marcaid: newValue,
                                      });
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Autocomplete
                                    disabled={disabledDoc}
                                    // disabled={disabledDoc || etapaEv}
                                    fullWidth
                                    id='productoid'
                                    {...productosProps}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        required
                                        label='Producto'
                                      />
                                    )}
                                    value={lote.productoid || null}
                                    onChange={(event, newValue) => {
                                      setLote({
                                        ...lote,
                                        productoid: newValue,
                                      });
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() =>
                                  dispatch({ type: 'CLOSE_MODAL_LOTE' })
                                }
                              >
                                Cancelar
                              </Button>
                              <Button
                                disabled={disabledDoc}
                                // disabled={disabledDoc || etapaEv}
                                color='primary'
                                variant='contained'
                                type='submit'
                              >
                                Guardar
                              </Button>
                            </DialogActions>
                          </form>
                        </>
                      )}
                    </Dialog>
                  </Box>
                  {loading ? <LinearProgress /> : ''}
                  <MUIDataTable
                    data={detallesLotes}
                    columns={columnsLotes}
                    options={{
                      responsive: 'standard',
                      selectableRows: disabledDoc ? 'none' : 'multiple',
                      // selectableRows:
                      //   disabledDoc || etapaEv ? 'none' : 'multiple',
                      rowsSelected: selectedRowsL,
                      onRowSelectionChange: (
                        rowsSelectedData,
                        allRows,
                        rowsSelected,
                      ) => {
                        setSelectedRowsL(rowsSelected);
                      },
                      customToolbarSelect: (selectedRows) => (
                        <Tooltip title='Eliminar'>
                          <IconButton
                            onClick={async () => {
                              setLoading(true);
                              let response;
                              for (
                                let i = 0;
                                i < selectedRows.data.length;
                                i++
                              ) {
                                response = await handleDeleteLote(
                                  detallesLotes[selectedRows.data[i].dataIndex]
                                    .id,
                                );
                              }
                              getDocumento();
                              setSelectedRowsL([]);
                              if (!response.err) {
                                Swal.fire({
                                  title: 'Éxito',
                                  text: '¡Se han eliminado los registros exitosamente!',
                                  icon: 'success',
                                  confirmButtonColor: '#196b86',
                                });
                              } else {
                                Swal.fire({
                                  title: 'Advertencia',
                                  text: response.message,
                                  icon: 'warning',
                                  confirmButtonColor: '#196b86',
                                });
                              }
                            }}
                            style={{
                              marginRight: '24px',
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      mb: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Registro de Evaluación de Cuarentena de Producto Terminado
                    </Typography>
                    <Box sx={{ float: 'right', mr: 1.5 }}>
                      {!disabledDoc ? (
                        <>
                          {/* {!etapaEv ? ( */}
                          <Grid item lg={6} xs={12}>
                            <Button
                              disabled={disabledDoc}
                              variant='contained'
                              onClick={() => {
                                setRegistro({ ...initialRegistro });
                                setEdit(false);
                                dispatch({ type: 'OPEN_INPUT_MODAL' });
                              }}
                            >
                              Nuevo
                            </Button>
                          </Grid>
                          {/* ) : (
                            ''
                          )} */}
                        </>
                      ) : (
                        ''
                      )}
                    </Box>
                    {/* MODAL ALTA DE EVALUACIÓN */}
                    <Dialog
                      open={state.toggleInputModal}
                      onClose={() => {
                        dispatch({ type: 'CLOSE_INPUT_MODAL' });
                      }}
                      aria-labelledby='form-dialog-title'
                      fullWidth
                      maxWidth='md'
                    >
                      {loading ? (
                        <Box sx={{ m: 20 }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <DialogTitle id='form-dialog-title'>
                            {edit ? 'Editar Registro' : 'Nuevo Registro'}
                          </DialogTitle>
                          <form onSubmit={handleSubmit}>
                            <DialogContent>
                              <Grid container spacing={2}>
                                {error ? (
                                  <Grid item xs={12}>
                                    <AlertError message={error} />
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                <Grid item xs={6}>
                                  <Autocomplete
                                    disabled={disabledDoc}
                                    // disabled={disabledDoc || etapaEv}
                                    fullWidth
                                    id='procesotermid'
                                    {...autoclavesProps}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label='Autoclave'
                                      />
                                    )}
                                    value={registro.procesotermid || null}
                                    onChange={(event, newValue) => {
                                      setRegistro({
                                        ...registro,
                                        procesotermid: newValue,
                                      });
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <Autocomplete
                                    disabled={disabledDoc}
                                    // disabled={disabledDoc || etapaEv}
                                    fullWidth
                                    id='denominacionprodid'
                                    {...denominacionesProps}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label='Denominación del Producto'
                                      />
                                    )}
                                    value={registro.denominacionprodid || null}
                                    onChange={(event, newValue) => {
                                      setRegistro({
                                        ...registro,
                                        denominacionprodid: newValue,
                                      });
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.id === value.id
                                    }
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() =>
                                  dispatch({ type: 'CLOSE_INPUT_MODAL' })
                                }
                              >
                                Cancelar
                              </Button>
                              <Button
                                disabled={disabledDoc}
                                // disabled={disabledDoc || etapaEv}
                                color='primary'
                                variant='contained'
                                type='submit'
                              >
                                Guardar
                              </Button>
                            </DialogActions>
                          </form>
                        </>
                      )}
                    </Dialog>
                  </Box>
                  <Grid item xs={12}>
                    {loading ? <LinearProgress /> : ''}
                    <MUIDataTable
                      data={detalles}
                      columns={columns}
                      options={{
                        responsive: 'standard',
                        selectableRows: disabledDoc ? 'none' : 'multiple',
                        // selectableRows:
                        //   disabledDoc || etapaEv ? 'none' : 'multiple',
                        rowsSelected: selectedRows,
                        onRowSelectionChange: (
                          rowsSelectedData,
                          allRows,
                          rowsSelected,
                        ) => {
                          setSelectedRows(rowsSelected);
                        },
                        customToolbarSelect: (selectedRows) => (
                          <Tooltip title='Eliminar'>
                            <IconButton
                              onClick={async () => {
                                setLoading(true);
                                let response;
                                for (
                                  let i = 0;
                                  i < selectedRows.data.length;
                                  i++
                                ) {
                                  response = await handleDelete(
                                    detalles[selectedRows.data[i].dataIndex].id,
                                  );
                                }
                                getDocumento();
                                setSelectedRows([]);
                                if (!response.err) {
                                  Swal.fire({
                                    title: 'Éxito',
                                    text: '¡Se han eliminado los registros exitosamente!',
                                    icon: 'success',
                                    confirmButtonColor: '#196b86',
                                  });
                                } else {
                                  Swal.fire({
                                    title: 'Advertencia',
                                    text: response.message,
                                    icon: 'warning',
                                    confirmButtonColor: '#196b86',
                                  });
                                }
                              }}
                              style={{
                                marginRight: '24px',
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        ),
                      }}
                    />
                  </Grid>
                </Widget>
              </Grid>
              <Paper
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                elevation={4}
              >
                <Box sx={{ float: 'right' }}>
                  <Button
                    variant='contained'
                    endIcon={<SkipNext />}
                    // onClick={() =>
                    //   etapaEv ? setActiveStep(2) : handleEndRegistro
                    // }
                    onClick={handleEndRegistro}
                  >
                    {/* {etapaEv ? 'Ir a Cuarentena' : 'Concluir Registro'} */}
                    Concluir Registro
                  </Button>
                </Box>
              </Paper>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Widget>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      mb: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Lotes
                    </Typography>
                  </Box>
                  {loading ? <LinearProgress /> : ''}
                  <MUIDataTable
                    data={detallesLotes}
                    columns={columnsLotes}
                    options={{ responsive: 'standard', selectableRows: 'none' }}
                  />
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      mb: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Registro de Evaluación de Cuarentena de Producto Terminado
                    </Typography>
                  </Box>
                  {/* MODAL UPDATE DE EVALUACIÓN */}
                  <Dialog
                    open={state.toggleModalEdit}
                    onClose={() => {
                      dispatch({ type: 'CLOSE_MODAL_EDIT' });
                    }}
                    aria-labelledby='form-dialog-title'
                    fullWidth
                    maxWidth='md'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          Editar Registro
                        </DialogTitle>
                        <form onSubmit={handleSubmitEdit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled
                                  fullWidth
                                  id='procesotermid'
                                  {...autoclavesProps}
                                  renderInput={(params) => (
                                    <TextField {...params} label='Autoclave' />
                                  )}
                                  value={evaluacion.procesotermid || null}
                                  onChange={(event, newValue) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      procesotermid: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  required
                                  inputProps={{ type: 'number' }}
                                  id='phreg'
                                  name='phreg'
                                  label='PH'
                                  fullWidth
                                  value={evaluacion.phreg}
                                  onChange={(e) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      phreg: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  required
                                  inputProps={{ type: 'number' }}
                                  id='apariencia'
                                  name='apariencia'
                                  label='Apariencia'
                                  fullWidth
                                  value={evaluacion.apariencia}
                                  onChange={(e) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      apariencia: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  required
                                  inputProps={{ type: 'number' }}
                                  id='color'
                                  name='color'
                                  label='Color'
                                  fullWidth
                                  value={evaluacion.color}
                                  onChange={(e) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      color: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  required
                                  inputProps={{ type: 'number' }}
                                  id='olor'
                                  name='olor'
                                  label='Olor'
                                  fullWidth
                                  value={evaluacion.olor}
                                  onChange={(e) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      olor: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>
                                  Presencia de Gas o Espuma
                                </Typography>
                                <ToggleButtonGroup
                                  disabled={disabledDoc}
                                  color='primary'
                                  value={evaluacion.gasesp || '1'}
                                  exclusive
                                  onChange={(e, newValue) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      gasesp: newValue,
                                    });
                                  }}
                                  aria-label='Gas o Espuma'
                                >
                                  <ToggleButton value='1'>SI</ToggleButton>
                                  <ToggleButton value='2'>NO</ToggleButton>
                                  <ToggleButton value='3'>N/A</ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Grado de Hinchazón</Typography>
                                <ToggleButtonGroup
                                  disabled={disabledDoc}
                                  color='primary'
                                  value={evaluacion.gradohin || '1'}
                                  exclusive
                                  onChange={(e, newValue) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      gradohin: newValue,
                                    });
                                  }}
                                  aria-label='Grado Hinchazón'
                                >
                                  <ToggleButton value='1'>SI</ToggleButton>
                                  <ToggleButton value='2'>NO</ToggleButton>
                                  <ToggleButton value='3'>N/A</ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography>Resultado de Evaluación</Typography>
                                <ToggleButtonGroup
                                  disabled={disabledDoc}
                                  color='primary'
                                  value={evaluacion.evaluacion || '1'}
                                  exclusive
                                  onChange={(e, newValue) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      evaluacion: newValue,
                                    });
                                  }}
                                  aria-label='Evaluación'
                                >
                                  <ToggleButton value='0'>
                                    Negativa
                                  </ToggleButton>
                                  <ToggleButton value='1'>
                                    Positiva
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='observaciones'
                                  name='observaciones'
                                  label='Observaciones'
                                  fullWidth
                                  value={evaluacion.observaciones || ''}
                                  onChange={(e) => {
                                    setEvaluacion({
                                      ...evaluacion,
                                      observaciones: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({ type: 'CLOSE_MODAL_EDIT' })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              // disabled={disabledDoc || etapaEv}
                              color='primary'
                              variant='contained'
                              type='submit'
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                  <Grid item xs={12}>
                    {loading ? <LinearProgress /> : ''}
                    <MUIDataTable
                      data={detalles}
                      columns={columnsEvaluacion}
                      options={{
                        responsive: 'standard',
                        selectableRows: 'none',
                      }}
                    />
                  </Grid>
                </Widget>
              </Grid>
              <Grid item xs={12}>
                <Widget>
                  <Grid container spacing={2} sx={{ mt: 1.5 }}>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 0.5, textAlign: 'center' }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              fullWidth
                              disabled={disabledDoc}
                              checked={
                                header.muestrasincdup === '1'
                                  ? true
                                  : false || false
                              }
                              onChange={(e) => {
                                setHeader({
                                  ...header,
                                  muestrasincdup: e.target.checked ? '1' : '0',
                                });
                              }}
                            />
                          }
                          label='Las muestras se incuban por duplicado'
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              fullWidth
                              disabled={disabledDoc}
                              checked={
                                header.almacenacontramues === '1'
                                  ? true
                                  : false || false
                              }
                              onChange={(e) => {
                                setHeader({
                                  ...header,
                                  almacenacontramues: e.target.checked
                                    ? '1'
                                    : '0',
                                });
                              }}
                            />
                          }
                          label='Se almacena contra muestra'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        disabled={disabledDoc}
                        fullWidth
                        id='evaluacion'
                        name='evaluacion'
                        label='Evaluación'
                        value={header.evaluacion || ''}
                        onChange={(e) => {
                          setHeader({
                            ...header,
                            evaluacion: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  {!disabledDoc ? (
                    <Box sx={{ float: 'right', mt: 2 }}>
                      <Button variant='contained' onClick={handleUpdateHeader}>
                        Guardar
                      </Button>
                    </Box>
                  ) : (
                    ''
                  )}
                </Widget>
              </Grid>
              {!disabledDoc ? (
                <Paper
                  sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
                  elevation={4}
                >
                  <Box sx={{ float: 'right' }}>
                    <Button
                      variant='contained'
                      endIcon={<GridCheckCircleIcon />}
                      onClick={handleEnd}
                    >
                      Terminar
                    </Button>
                  </Box>
                </Paper>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      )}
    </>
  );
}

export default EvaluacionCuarentena;
