import React from "react"; 
import { Grid } from "@mui/material";
 
// components 

function Consultas() {
 

  return (
    <Grid container spacing={12}> 
      <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* <AdministracionMenu />  */}
            </Grid>
          </Grid>
      </Grid>
    </Grid>
  );
}
 

export default Consultas;
