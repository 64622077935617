import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import AlertError from 'components/AlertError/AlertError';
import dayjs from 'dayjs';
import { AccessTime, Delete, Edit } from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIDataTable from 'mui-datatables';
import { formatDatetime, isEditable } from 'functions';
import axios from 'axios';
import Swal from 'sweetalert2';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function PesoPastilla() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    pesopastilla: '',
    fecha: '',
    loteprod: '',
    ubicacion: '',
    estatus: '',
    descripcionprod: '',
    unidadmed: '',
    horaini: '',
    horafin: '',
  });
  const [tiposRev, setTiposRev] = useState([]);
  const [contMuestreo, setContMuestreo] = useState(0);
  const initialPesos = {
    pesopas1: 0,
    pesopas2: 0,
    pesopas3: 0,
    pesopas4: 0,
    pesopas5: 0,
    pesopas6: 0,
    pesopas7: 0,
    pesopas8: 0,
    pesopas9: 0,
    pesopas10: 0,
    pesopas11: 0,
    pesopas12: 0,
    pesopas13: 0,
    pesopas14: 0,
    pesopas15: 0,
    pesopas16: 0,
    pesopas17: 0,
    pesopas18: 0,
    pesopas19: 0,
    pesopas20: 0,
    pesopas21: 0,
    pesopas22: 0,
    pesopas23: 0,
    pesopas24: 0,
    pesopas25: 0,
    pesopas26: 0,
    pesopas27: 0,
    pesopas28: 0,
    pesopas29: 0,
    pesopas30: 0,
    pesopas31: 0,
  };
  const initialState = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    pesopasid: id,
    horaev: null,
    tiporevid: null,
    ...initialPesos,
    promediopp: 0,
  };
  const [muestreo, setMuestreo] = useState({ ...initialState });
  // const [pesos, setPesos] = useState({ ...initialPesos });
  const [detalles, setDetalles] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Hora Evaluación', name: 'horaev' },
    { label: 'Tipo Revisión', name: 'tiporev' },
    { label: 'Promedio', name: 'promediopp' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...muestreo,
      horaev: muestreo.horaev
        ? muestreo.horaev.$d
          ? formatDatetime(muestreo.horaev.$d)
          : formatDatetime(muestreo.horaev)
        : null,
      tiporevid: muestreo.tiporevid.id,
    };

    axios
      .post('/enlatado/pesopastilla/detail', body)
      .then(() => {
        setLoading(false);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setMuestreo({
      ...muestreo,
      detailid: detalle.id || '',
      horaev: new Date(detalle.horaev) || null,
      tiporevid: { id: detalle.tiporevid, name: detalle.tiporev } || null,
      pesopas1: detalle.pesopas1 || '',
      pesopas2: detalle.pesopas2 || '',
      pesopas3: detalle.pesopas3 || '',
      pesopas4: detalle.pesopas4 || '',
      pesopas5: detalle.pesopas5 || '',
      pesopas6: detalle.pesopas6 || '',
      pesopas7: detalle.pesopas7 || '',
      pesopas8: detalle.pesopas8 || '',
      pesopas9: detalle.pesopas9 || '',
      pesopas10: detalle.pesopas10 || '',
      pesopas11: detalle.pesopas11 || '',
      pesopas12: detalle.pesopas12 || '',
      pesopas13: detalle.pesopas13 || '',
      pesopas14: detalle.pesopas14 || '',
      pesopas15: detalle.pesopas15 || '',
      pesopas16: detalle.pesopas16 || '',
      pesopas17: detalle.pesopas17 || '',
      pesopas18: detalle.pesopas18 || '',
      pesopas19: detalle.pesopas19 || '',
      pesopas20: detalle.pesopas20 || '',
      pesopas21: detalle.pesopas21 || '',
      pesopas22: detalle.pesopas22 || '',
      pesopas23: detalle.pesopas23 || '',
      pesopas24: detalle.pesopas24 || '',
      pesopas25: detalle.pesopas25 || '',
      pesopas26: detalle.pesopas26 || '',
      pesopas27: detalle.pesopas27 || '',
      pesopas28: detalle.pesopas28 || '',
      pesopas29: detalle.pesopas29 || '',
      pesopas30: detalle.pesopas30 || '',
      pesopas31: detalle.pesopas31 || '',
      promediopp: detalle.promediopp || '',
    });
    setContMuestreo(detalle.tiporevid === '123' ? 31 : 10);
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/pesopastilla/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/pesopastilla/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);
    axios
      .get(`/enlatado/pesopastilla/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const tipoRevProps = {
    options: tiposRev.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getTiposRev = () => {
    axios
      .get('/catalogo/muestreo', {})
      .then((res) => {
        setTiposRev(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getPromedio = (e, index) => {
    let total = 0;
    for (let i = 0; i < 31; i++) {
      total += parseFloat(
        index === i ? e.target.value : muestreo[`pesopas${i + 1}`],
      );
    }
    return parseFloat(total / contMuestreo).toFixed(2);
  };

  useEffect(() => {
    getDocumento();
    getTiposRev();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.pesopastilla}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote de Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Descripción'
                    fullWidth
                    disabled
                    value={header.descripcionprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Unidad de Medida'
                    fullWidth
                    disabled
                    value={header.unidadmed}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Peso Pastilla
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setMuestreo({ ...initialState });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setMuestreo({
                                        ...muestreo,
                                        horaev: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora Evaluación'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    value={
                                      muestreo.horaev
                                        ? dayjs(muestreo.horaev)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setMuestreo({
                                        ...muestreo,
                                        horaev: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='tiporevid'
                                {...tipoRevProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Tipo de Revisión'
                                  />
                                )}
                                value={muestreo.tiporevid}
                                onChange={(event, newValue) => {
                                  setMuestreo({
                                    ...muestreo,
                                    tiporevid: newValue,
                                    ...initialPesos,
                                  });
                                  if (newValue !== null) {
                                    setContMuestreo(
                                      newValue?.id === '123' ? 31 : 10,
                                    );
                                  } else {
                                    setContMuestreo(0);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {[...Array(contMuestreo)].map((e, i) => (
                              <Grid item xs={6} key={i}>
                                <TextField
                                  inputProps={{ type: 'number' }}
                                  id={`pesopas${i + 1}`}
                                  name={`pesopas${i + 1}`}
                                  label={`Peso Pastilla ${i + 1}`}
                                  fullWidth
                                  required
                                  value={muestreo[`pesopas${i + 1}`] || ''}
                                  onChange={(e) =>
                                    setMuestreo({
                                      ...muestreo,
                                      [`pesopas${i + 1}`]: e.target.value,
                                      promediopp: getPromedio(e, i),
                                    })
                                  }
                                />
                              </Grid>
                            ))}
                            <Grid item xs={12}>
                              <TextField
                                inputProps={{ type: 'number' }}
                                id='promediopp'
                                name='promediopp'
                                label='Promedio Peso Pastilla'
                                fullWidth
                                disabled
                                value={muestreo.promediopp || 0}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default PesoPastilla;
