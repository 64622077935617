import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ModalEsterilizado from 'components/Modal/ModalEsterilizado';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const ViewInfoEsterilizado = ({
  title,
  columns,
  data,
  formato,
  handleSubmit,
  registro,
  setRegistro,
  loading,
  errorModal,
  setErrorModal,
  filtro = false,
  verTodos = false,
  setVerTodos = null,
  getDocumentos,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            '& a': {
              textDecoration: 'none',
              color: '#fff',
            },
          }}
        >
          <Typography variant='h3' color='text' noWrap>
            {title}
          </Typography>
          <Button
            variant='contained'
            sx={{ float: 'right' }}
            onClick={() => dispatch({ type: 'OPEN_INPUT_MODAL' })}
          >
            Nuevo
          </Button>
          <ModalEsterilizado
            open={state.toggleInputModal}
            dispatch={dispatch}
            formato={formato}
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            err={errorModal}
            setErr={setErrorModal}
            load={loading}
          />
        </Box>
      </Grid>
      {filtro ? (
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                fullWidth
                checked={verTodos || false}
                onChange={(e) => {
                  setVerTodos(e.target.checked);
                  getDocumentos(e.target.checked);
                }}
              />
            }
            label='Ver Todos los Folios'
          />
        </Grid>
      ) : (
        ''
      )}
      <Grid item xs={12}>
        {loading ? <LinearProgress /> : ''}
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ responsive: 'standard', selectableRows: 'none' }}
        />
      </Grid>
    </Grid>
  );
};

export default ViewInfoEsterilizado;
