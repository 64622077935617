import React from 'react';
import { withStyles } from '@mui/styles';

const styles = (theme) => ({
    folderMainColor: {
        fill: theme.palette.primary.light 
    },
    imgWrap: {
      marginRight: 10,
    },
})

const CloudIcon = ({ classes }) => {
    return (
        <div className={classes.imgWrap}>
            <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z" className={classes.folderMainColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.84945 13.6999C10.2856 10.9448 13.2718 9.36048 16.3583 9.71597C19.2265 10.0463 21.6295 11.9883 22.5748 14.6764H23.0069C25.188 14.6747 27.1186 16.087 27.7773 18.1663C28.4361 20.2455 27.6711 22.5118 25.8869 23.7664C25.5105 24.0312 24.9906 23.9406 24.7259 23.5641C24.4612 23.1876 24.5518 22.6678 24.9282 22.4031C26.1177 21.5667 26.6277 20.0558 26.1885 18.6696C25.7493 17.2835 24.4623 16.342 23.0076 16.3431H21.9576C21.5771 16.3431 21.245 16.0854 21.1504 15.7169C20.5498 13.3762 18.5682 11.6482 16.1676 11.3717C13.7669 11.0952 11.4444 12.3275 10.3274 14.4703C9.21037 16.6131 9.53022 19.2228 11.1316 21.0325C11.4366 21.3772 11.4045 21.9038 11.0598 22.2088C10.7152 22.5138 10.1885 22.4817 9.88351 22.137C7.82454 19.8103 7.4133 16.455 8.84945 13.6999ZM17.1743 18.0097V23.4979L15.2635 21.5872C14.9381 21.2617 14.4104 21.2617 14.085 21.5872C13.7596 21.9126 13.7596 22.4402 14.085 22.7657L17.4173 26.0979L17.4275 26.108C17.5775 26.2535 17.7821 26.3431 18.0076 26.3431C18.1215 26.3431 18.23 26.3202 18.3289 26.2789C18.4241 26.2392 18.5134 26.181 18.5914 26.1044L18.5976 26.0983L21.9302 22.7657C22.2556 22.4402 22.2556 21.9126 21.9302 21.5872C21.6047 21.2617 21.0771 21.2617 20.7517 21.5872L18.8409 23.4979V18.0097C18.8409 17.5495 18.4678 17.1764 18.0076 17.1764C17.5474 17.1764 17.1743 17.5495 17.1743 18.0097Z" fill="white"/>
            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="9" width="21" height="18">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.84945 13.6999C10.2856 10.9448 13.2718 9.36048 16.3583 9.71597C19.2265 10.0463 21.6295 11.9883 22.5748 14.6764H23.0069C25.188 14.6747 27.1186 16.087 27.7773 18.1663C28.4361 20.2455 27.6711 22.5118 25.8869 23.7664C25.5105 24.0312 24.9906 23.9406 24.7259 23.5641C24.4612 23.1876 24.5518 22.6678 24.9282 22.4031C26.1177 21.5667 26.6277 20.0558 26.1885 18.6696C25.7493 17.2835 24.4623 16.342 23.0076 16.3431H21.9576C21.5771 16.3431 21.245 16.0854 21.1504 15.7169C20.5498 13.3762 18.5682 11.6482 16.1676 11.3717C13.7669 11.0952 11.4444 12.3275 10.3274 14.4703C9.21037 16.6131 9.53022 19.2228 11.1316 21.0325C11.4366 21.3772 11.4045 21.9038 11.0598 22.2088C10.7152 22.5138 10.1885 22.4817 9.88351 22.137C7.82454 19.8103 7.4133 16.455 8.84945 13.6999ZM17.1743 18.0097V23.4979L15.2635 21.5872C14.9381 21.2617 14.4104 21.2617 14.085 21.5872C13.7596 21.9126 13.7596 22.4402 14.085 22.7657L17.4173 26.0979L17.4275 26.108C17.5775 26.2535 17.7821 26.3431 18.0076 26.3431C18.1215 26.3431 18.23 26.3202 18.3289 26.2789C18.4241 26.2392 18.5134 26.181 18.5914 26.1044L18.5976 26.0983L21.9302 22.7657C22.2556 22.4402 22.2556 21.9126 21.9302 21.5872C21.6047 21.2617 21.0771 21.2617 20.7517 21.5872L18.8409 23.4979V18.0097C18.8409 17.5495 18.4678 17.1764 18.0076 17.1764C17.5474 17.1764 17.1743 17.5495 17.1743 18.0097Z" fill="white"/>
            </mask>
            <g mask="url(#mask0)">
            </g>
            </svg>
        </div>
    )
}

export default withStyles(styles)(CloudIcon);