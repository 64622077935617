import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import React, { useEffect } from 'react';
import { useState } from 'react';

const ModalCatalogo = ({
  open,
  // title = 'Nuevo Registro',
  edit,
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [subcategorias, setSubcategorias] = useState([]);
  const [tipoProducto, setTipoProducto] = useState([]);
  const [presentacion, setPresentacion] = useState([]);

  const subcategoriasProps = {
    options: subcategorias.map((option) => ({
      id: option.clave,
    })),
    getOptionLabel: (option) => option.id,
  };

  const getSubcategorias = () => {
    setLoading(true);
    try {
      axios
        .get('administracion/catalogo/subcatalogos', {})
        .then((res) => {
          if (res !== null) {
            setSubcategorias(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  // COMBO TIPO DE PRODUCTO
  const tipoProductoProps = {
    options: tipoProducto.map((option) => ({
      id: option.clatipoprod,
      name: option.tipoprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getTipoProducto = () => {
    setLoading(true);
    try {
      axios
        .get('administracion/producto/tipoprod', {})
        .then((res) => {
          if (res !== null) {
            setTipoProducto(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  // COMBO PRESENTACIÓN
  const presentacionProps = {
    options: presentacion.map((option) => ({
      id: option.clapresentacion,
      name: option.presentacion,
    })),
    getOptionLabel: (option) => option.id + ' - ' + option.name,
  };

  const getPresentacion = () => {
    setLoading(true);
    try {
      axios
        .get('administracion/producto/presentacion', {})
        .then((res) => {
          if (res !== null) {
            setPresentacion(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getSubcategorias();
    getTipoProducto();
    getPresentacion();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setErr(null);
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>
              {edit ? 'Editar Registro' : 'Nuevo Registro'}
            </DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      catalogoMaestro: (
                        <>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={!edit}
                              sx={{ mt: 1 }}
                              fullWidth
                              id='clave'
                              {...subcategoriasProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Catálogo'
                                />
                              )}
                              value={registro.clave || null}
                              onChange={(event, newValue) => {
                                setRegistro({ ...registro, clave: newValue });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='nombrecor'
                              label='Nombre Corto'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombrecor: e.target.value,
                                })
                              }
                              name='nombrecor'
                              value={registro.nombrecor || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='nombreesp'
                              label='Nombre Español'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombreesp: e.target.value,
                                })
                              }
                              name='nombreesp'
                              value={registro.nombreesp || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='nombreing'
                              label='Nombre Inglés'
                              fullWidth
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombreing: e.target.value,
                                })
                              }
                              name='nombreing'
                              value={registro.nombreing || ''}
                            />
                          </Grid>
                        </>
                      ),
                      catalogoClientes: (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id='marca'
                              label='Marca'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  marca: e.target.value,
                                })
                              }
                              name='marca'
                              value={registro.marca || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='nombre'
                              label='Nombre'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombre: e.target.value,
                                })
                              }
                              name='nombre'
                              value={registro.nombre || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='razonsoc'
                              label='Razón Social'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  razonsoc: e.target.value,
                                })
                              }
                              name='razonsoc'
                              value={registro.razonsoc || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='rfc'
                              label='RFC'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  rfc: e.target.value,
                                })
                              }
                              name='rfc'
                              value={registro.rfc || ''}
                            />
                          </Grid>
                        </>
                      ),
                      catalogoProveedores: (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id='nombre'
                              label='Nombre'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombre: e.target.value,
                                })
                              }
                              name='nombre'
                              value={registro.nombre || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='razonsoc'
                              label='Razón Social'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  razonsoc: e.target.value,
                                })
                              }
                              name='razonsoc'
                              value={registro.razonsoc || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='rfc'
                              label='RFC'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  rfc: e.target.value,
                                })
                              }
                              name='rfc'
                              value={registro.rfc || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='email'
                              label='Correo Electrónico'
                              fullWidth
                              inputProps={{ type: 'email' }}
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  email: e.target.value,
                                })
                              }
                              name='email'
                              value={registro.email || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='contacto'
                              label='Contacto'
                              fullWidth
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  contacto: e.target.value,
                                })
                              }
                              name='contacto'
                              value={registro.contacto || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='telefonocontacto'
                              label='Teléfono Contacto'
                              fullWidth
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  telefonocontacto: e.target.value,
                                })
                              }
                              name='telefonocontacto'
                              value={registro.telefonocontacto || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='emailcontacto'
                              label='Correo Electrónico Contacto'
                              fullWidth
                              inputProps={{ type: 'email' }}
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  emailcontacto: e.target.value,
                                })
                              }
                              name='emailcontacto'
                              value={registro.emailcontacto || ''}
                            />
                          </Grid>
                        </>
                      ),
                      catalogoTransportistas: (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id='nombre'
                              label='Nombre'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  nombre: e.target.value,
                                })
                              }
                              name='nombre'
                              value={registro.nombre || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='razonsoc'
                              label='Razón Social'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  razonsoc: e.target.value,
                                })
                              }
                              name='razonsoc'
                              value={registro.razonsoc || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='rfc'
                              label='RFC'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  rfc: e.target.value,
                                })
                              }
                              name='rfc'
                              value={registro.rfc || ''}
                            />
                          </Grid>
                        </>
                      ),
                      catalogoProductos: (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id='claveprod'
                              label='Clave'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  claveprod: e.target.value,
                                })
                              }
                              name='claveprod'
                              value={registro.claveprod || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='descripcionprod'
                              label='Descripción'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  descripcionprod: e.target.value,
                                })
                              }
                              name='descripcionprod'
                              value={registro.descripcionprod || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  fullWidth
                                  checked={registro.tpN || false}
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      tpN: e.target.checked,
                                      nvotipoprod: e.target.checked ? 1 : 0,
                                    });
                                  }}
                                />
                              }
                              label='Nuevo Tipo Producto'
                            />
                          </Grid>
                          {registro.tpN ? (
                            <Grid item xs={12}>
                              <TextField
                                id='tipoprod'
                                label='Nuevo Tipo Producto'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    tipoprodN: e.target.value,
                                  })
                                }
                                name='tipoprod'
                                value={registro.tipoprodN || ''}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Autocomplete
                                sx={{ mt: 1 }}
                                fullWidth
                                id='tipoproductoE'
                                {...tipoProductoProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Tipo Producto'
                                  />
                                )}
                                value={registro.tipoproductoE || null}
                                onChange={(event, newValue) => {
                                  setRegistro({
                                    ...registro,
                                    tipoproductoE: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  fullWidth
                                  checked={registro.pN || false}
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      pN: e.target.checked,
                                      nvoclapresentacion: e.target.checked
                                        ? 1
                                        : 0,
                                    });
                                  }}
                                />
                              }
                              label='Nueva Presentación'
                            />
                          </Grid>
                          {registro.pN ? (
                            <>
                              <Grid item xs={12}>
                                <TextField
                                  id='clapresentacionN'
                                  label='Nueva Clave Presentación'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      clapresentacionN: e.target.value,
                                    })
                                  }
                                  name='clapresentacionN'
                                  value={registro.clapresentacionN || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  id='presentacionN'
                                  label='Nuevo Nombre Presentación'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      presentacionN: e.target.value,
                                    })
                                  }
                                  name='presentacionN'
                                  value={registro.presentacionN || ''}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Autocomplete
                                sx={{ mt: 1 }}
                                fullWidth
                                id='presentacionE'
                                {...presentacionProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Presentación'
                                  />
                                )}
                                value={registro.presentacionE || null}
                                onChange={(event, newValue) => {
                                  setRegistro({
                                    ...registro,
                                    presentacionE: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          )}
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setErr(null);
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalCatalogo;
