import React, { useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, formatDatetimeG, isEditable } from 'functions';
import { Delete, FileUpload, Visibility } from '@mui/icons-material';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function EtapaEmbarqueDetail() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [drag, setDrag] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [loteProd, setLoteProd] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [detalleTotales, setDetalleTotales] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const [header, setHeader] = useState({
    embarque: '',
    fecha: '',
    ubicacion: '',
    estatus: '',
    cliente: '', 
    transportista: '',
    horaini: '',
    horafin: '',
  });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    embarqueid: id,
    fechaprod: formatDatetime(new Date()),
    loteprod: null,
    totalcajastari: 0,
    identificada: '0',
    destino: '',
    replica: false,
    cantidadReg: 0,
  };
  const [registro, setRegistro] = useState({ ...initialRegistro });
  const initialTerminar = {
    userid: sessionStorage.getItem('user_id'),
    id: id,
    totalcajas: 0,
    calidad: null,
    logistica: null,
  };
  const [terminar, setTerminar] = useState({ ...initialTerminar });
  const inputRef = useRef();

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'No. Tarima', name: 'tarimanum' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Total Cajas Tarima', name: 'totalcajastari' },
    { label: 'Marca', name: 'marca' },
    { label: 'Identificada', name: 'identificada' },
    { label: 'Destino', name: 'destino' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsTotales = [
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Total Tarimas', name: 'totaltarimas' },
    { label: 'Total Cajas Lote', name: 'totalcajaslote' },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'nomarchivo' },
    {
      label: 'Ver',
      name: 'id',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target='_blank'>
              <Fab color='primary' size='small'>
                <Visibility />
              </Fab>
            </a>
          );
        },
      },
    },
  ];

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const clientesProps = {
    options: clientes.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const usuariosProps = {
    options: usuario.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getClientes = (clienteid) => {
 
    axios
     .get(`/catalogo/marcas/${clienteid}`, {}) 
      .then((res) => {
        setClientes(res.data.data);
   
      })
      .catch((error) => {
        setError(error);
 
      });
  };

  const getUsuarios = () => {
    setLoading(true);
    axios
      .get('/catalogo/usuario', {})
      .then((res) => {
        setUsuario(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/embarque/regembarque/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setArchivos(res.data.data?.Files);
        setLoading(false); 
        getClientes(res.data.data.Header.clienteid);
        // console.log(res);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getTotales = () => {
    setLoading(true);
    axios
      .get(`/embarque/regembarque/totaleslote/${id}`, {})
      .then((res) => {
        setDetalleTotales(res.data.data);
        setLoading(false);
        // console.log(res);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getTotalCajas = () => {
    let total = 0;
    for (let i = 0; i < detalleTotales.length; i++) {
      total += parseFloat(detalleTotales[i].totalcajaslote);
    }
    return total;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      ...registro,
      registrodiaelaid: registro.loteprod.id,
      loteprod: registro.loteprod.name,
      marcaid: registro.marca.id,
    };

    let err = false;
    let message = '';

    for (let i = 0; i <= registro.cantidadReg; i++) {
      await axios.post('/embarque/regembarque/detail', body).catch((error) => {
        // setError(error);
        err = true;
        message = error.response?.data?.message;
        setLoading(false);
      });
    }
    getRegistro();
    setSelectedRows([]);
    if (!err) {
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      Swal.fire({
        title: 'Éxito',
        text: '¡Se ha guardado la información exitosamente!',
        icon: 'success',
        confirmButtonColor: '#196b86',
      });
      setRegistro({ ...initialRegistro });
      getUsuarios();
      getTotales();
      getRegistro();  
      getLoteProd(formatDatetimeG(new Date()));
      setLoading(false);
    } else {
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      Swal.fire({
        title: 'Advertencia',
        text: message,
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      fechaprod: formatDatetime(new Date(detalle.fechaprod)),
      loteprod: { id: detalle.registrodiaelaid, name: detalle.loteprod },
      totalcajastari: detalle.totalcajastari,
      identificada: detalle.identificada === 'SI' ? '1' : '0',
      marca: { id: detalle.marcaid, name: detalle.marca },
      destino: detalle.destino,
      cantidadReg: 0,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/embarque/delete/regembarque/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = (e) => {
    // Swal.fire({
    //   title: '¿Está seguro de terminar el proceso?',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Sí',
    //   confirmButtonColor: '#196b86',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    e.preventDefault();
    const body = {
      ...terminar,
      calidadid: terminar.calidad.id,
      logisticaid: terminar.logistica.id,
    };
    axios
      .post('/embarque/regembarque/document/finalizar', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push('/app/etapas/embarque');
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
    //   }
    // });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files.length) {
      return;
    }
    const file = e.dataTransfer.files[0];
    const objectURL = URL.createObjectURL(file);
    document.getElementById('etiqueta').src = objectURL;
    setFileUpload(e.dataTransfer.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (!e.target.files.length) {
      return;
    }
    const file = e.target.files[0];
    const objectURL = URL.createObjectURL(file);
    document.getElementById('etiqueta').src = objectURL;
    setFileUpload(e.target.files[0]);
  };

  const handleRemoveImg = () => {
    document.getElementById('etiqueta').src = '';
    setFileUpload(null);
  };

  const handleSubmitArchivos = async () => {
    if (fileUpload) {
      setLoading(true);
      let err = false;
      let message = '';

      const formData = new FormData();
      formData.append('files', fileUpload);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      await axios
        .post(`/upload/embarque/${id}`, formData, config)
        .then((res) => {
          if (res.data.status === 'error') {
            err = true;
            message = res.data?.message;
          } else {
            err = false;
            setFileUpload(null);
          }
          getRegistro();
          setLoading(false);
        })
        .catch((error) => {
          err = true;
          message = error.response?.data?.message;
          setLoading(false);
        });

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se han guardado los archivos exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'No se han cargado archivos.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
  };

  useEffect(() => {
    // getLineaProd();
    // getMaquina();
    // getEstatus();
    getUsuarios();
    getTotales();
    getRegistro();
    getLoteProd(formatDatetimeG(new Date()));
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.embarque}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Cliente'
                    fullWidth
                    disabled
                    value={`${header.Marca} - ${header.cliente}`}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Transportista'
                    fullWidth
                    disabled
                    value={header.transportista}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Total Cajas'
                    fullWidth
                    disabled
                    value={header.totalcajas}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Firma Calidad'
                    fullWidth
                    disabled
                    value={header.calidad}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Firma Logística'
                    fullWidth
                    disabled
                    value={header.logistica}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Embarque
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        setRegistro({ ...initialRegistro });
                        setEdit(false);
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    required
                                    disableFuture
                                    id='fechaprod'
                                    name='fechaprod'
                                    label='Fecha de Producción'
                                    format='DD/MM/YYYY'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    value={dayjs(new Date(registro.fechaprod))}
                                    onChange={(date) => {
                                      setRegistro({
                                        ...registro,
                                        fechaprod: formatDatetime(
                                          new Date(date),
                                        ),
                                      });
                                      setError(null);
                                    }}
                                    onAccept={(date) =>
                                      getLoteProd(
                                        formatDatetimeG(new Date(date)),
                                      )
                                    }
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  fullWidth
                                  id='loteprod'
                                  {...loteProdProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Lote de Producción'
                                    />
                                  )}
                                  value={registro.loteprod || null}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      loteprod: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  InputProps={{
                                    type: 'number',
                                    min: 0,
                                  }}
                                  label='Total Cajas Tarima'
                                  id='totalcajastari'
                                  name='totalcajastari'
                                  fullWidth
                                  required
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      totalcajastari: e.target.value,
                                    });
                                  }}
                                  value={registro.totalcajastari || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  fullWidth
                                  id='marca'
                                  {...clientesProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Marca'
                                    />
                                  )}
                                  value={registro.marca || null}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      marca: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                {/* <Typography variant='h6'>Acción</Typography> */}
                                <ToggleButtonGroup
                                  disabled={disabledDoc}
                                  color='primary'
                                  value={registro.identificada || '0'}
                                  exclusive
                                  onChange={(e, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      identificada: newValue,
                                    });
                                  }}
                                  aria-label='Indentificación'
                                >
                                  <ToggleButton value='0'>
                                    No Identificada
                                  </ToggleButton>
                                  <ToggleButton value='1'>
                                    Identificada
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  required
                                  disabled={disabledDoc}
                                  id='destino'
                                  name='destino'
                                  label='Destino'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      destino: e.target.value,
                                    })
                                  }
                                  value={registro.destino || ''}
                                />
                              </Grid>
                              {!edit ? (
                                <>
                                  <Grid item xs={12}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          fullWidth
                                          checked={registro.replica || false}
                                          onChange={(e) => {
                                            setRegistro({
                                              ...registro,
                                              replica: e.target.checked,
                                            });
                                          }}
                                        />
                                      }
                                      label='Existen varias tarimas con la misma información'
                                    />
                                  </Grid>
                                  {registro.replica ? (
                                    <Grid item xs={12}>
                                      <TextField
                                        InputProps={{
                                          type: 'number',
                                          min: 0,
                                        }}
                                        id='cantidadReg'
                                        label='Cantidad de Tarimas'
                                        fullWidth
                                        required={
                                          registro.replica ? true : false
                                        }
                                        onChange={(e) =>
                                          setRegistro({
                                            ...registro,
                                            cantidadReg: e.target.value,
                                          })
                                        }
                                        name='cantidadReg'
                                        value={registro.cantidadReg || ''}
                                      />
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : (
                                ''
                              )}

                              {/* <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  InputProps={{
                                    type: 'number',
                                    min: 0,
                                  }}
                                  label='Cantidad de tarimas con la misma información'
                                  id='cantidadReg'
                                  name='cantidadReg'
                                  fullWidth
                                  required
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      cantidadReg: e.target.value,
                                    });
                                  }}
                                  value={registro.cantidadReg || ''}
                                />
                              </Grid> */}
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .id,
                            );
                          }
                          getUsuarios();
                          getTotales();
                          getClientes();
                          getRegistro();
                          getLoteProd(formatDatetimeG(new Date()));
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Typography
                variant='h3'
                color='text'
                noWrap
                sx={{ mt: 2, mb: 2 }}
              >
                Total de Cajas por Lote
              </Typography>
              <MUIDataTable
                data={detalleTotales}
                columns={columnsTotales}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Etiqueta Utilizada
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    onClick={() => {
                      setEdit(false);
                      handleSubmitArchivos();
                    }}
                  >
                    Guardar Archivos
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                  <Box sx={{ margin: '0 auto' }}>
                    <div
                      className={`dropzone ${drag ? 'drag' : ''}`}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragEnd}
                      onDrop={handleDrop}
                    >
                      <input
                        type='file'
                        onChange={(e) => handleUpload(e)}
                        hidden
                        ref={inputRef}
                      />
                      <Fab
                        color='primary'
                        variant='extended'
                        aria-label='upload'
                        size='small'
                        onClick={() => inputRef.current.click()}
                      >
                        <FileUpload /> Cargar Archivo
                      </Fab>
                      <Typography
                        sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                      >
                        o arrastra un archivo
                      </Typography>
                    </div>
                  </Box>
                </Grid>
                <Grid item lg={8} xs={12}>
                  {fileUpload ? (
                    <Fab
                      color='primary'
                      variant='extended'
                      sx={{ mb: 1 }}
                      onClick={handleRemoveImg}
                    >
                      Quitar imagen
                    </Fab>
                  ) : (
                    ''
                  )}
                  <img fullWidth id='etiqueta' width='100%' />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Typography
                variant='h3'
                color='text'
                noWrap
                sx={{ mt: 2, mb: 2 }}
              >
                Archivos
              </Typography>
              <MUIDataTable
                title='Archivos'
                data={archivos}
                columns={columnsFiles}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={() => {
                    setTerminar({ ...initialTerminar });
                    setTerminar({
                      ...terminar,
                      totalcajas: getTotalCajas(),
                    });
                    dispatch({ type: 'OPEN_MODAL_END' });
                  }}
                >
                  Terminar
                </Button>
              </Box>
              <Dialog
                open={state.toggleModalEnd}
                onClose={() => {
                  setTerminar({ ...initialTerminar });
                  dispatch({ type: 'CLOSE_MODAL_END' });
                }}
                aria-labelledby='form-dialog-title'
              >
                {loading ? (
                  <Box sx={{ m: 20 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <DialogTitle id='form-dialog-title'>Terminar</DialogTitle>
                    <form onSubmit={handleTerminar}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <TextField
                              disabled
                              inputProps={{ type: 'number', min: 0 }}
                              id='totalcajas'
                              label='Total de Cajas'
                              fullWidth
                              name='totalcajas'
                              value={terminar.totalcajas || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={disabledDoc}
                              fullWidth
                              id='calidad'
                              {...usuariosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Firma Calidad'
                                />
                              )}
                              value={terminar.calidad}
                              onChange={(event, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  calidad: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={disabledDoc}
                              fullWidth
                              id='logistica'
                              {...usuariosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Firma Logística'
                                />
                              )}
                              value={terminar.logistica}
                              onChange={(event, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  logistica: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            setTerminar({ ...initialTerminar });
                            dispatch({ type: 'CLOSE_MODAL_END' });
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          // onClick={handleTerminar}
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Dialog>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EtapaEmbarqueDetail;
