import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from 'components/Widget/Widget';
import {
  FactCheck,
  ProductionQuantityLimits,
  Storage,
  ThumbDown,
  Troubleshoot,
} from '@mui/icons-material';
import AnalisisFisicoquimicoTab from './AnalisisFisicoquimico/AnalisisFisicoquimicoTab';
import EvaluacionCuarentenaTab from './EvaluacionCuarentena/EvaluacionCuarentenaTab';
import RechazoClienteTab from './RechazoCliente/RechazoClienteTab';
import AccionesCorrectivasTab from './AccionesCorrectivas/AccionesCorrectivasTab';
import ReclamacionProveedorTab from './ReclamacionProveedor/ReclamacionProveedorTab';
import AlmacenamientoCuarentenaTab from './AlmacenamientoCuarentena/AlmacenamientoCuarentenaTab';

function EtapaLaboratorio() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('laboratorio')
      ? parseInt(localStorage.getItem('laboratorio'))
      : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('laboratorio', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab label='Análisis Fisicoquímico' icon={<Troubleshoot />} />
              <Tab
                label='Evaluación de Cuarentena de Producto Terminado'
                icon={<FactCheck />}
              />
              <Tab label='Rechazo de Cliente' icon={<ThumbDown />} />
              {/* <Tab
                label='Reporte para Acciones Correctivas'
                icon={<FactCheck />}
              /> */}
              <Tab
                label='Reclamación a Proveedor'
                icon={<ProductionQuantityLimits />}
              />
              <Tab label='Almacenamiento Cuarentena' icon={<Storage />} />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <AnalisisFisicoquimicoTab />,
              1: <EvaluacionCuarentenaTab />,
              2: <RechazoClienteTab />,
              // 3: <AccionesCorrectivasTab />,
              3: <ReclamacionProveedorTab />,
              4: <AlmacenamientoCuarentenaTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaLaboratorio;
