import React from 'react';
import {
  Home as HomeIcon,
  FilterNone as UIElementsIcon,  
  Apps as CoreIcon,
  Description as DescriptionIcon, 
  Assignment as AssignmentIcon,
  DoneAll as DoneAllIcon,
  WbSunny as WbSunnyIcon,
  Waves as WavesIcon,
  LabelImportant as LabelImportantIcon,
  LineWeight as LineWeightIcon,
  Settings,
  Storage as StorageIcon,
  RotateLeft as RotateLeftIcon,
  DeleteOutline as DeleteOutlineIcon, 
  Colorize as ColorizeIcon, 
  DirectionsBoat as DirectionsBoatIcon, 
  Search
} from '@mui/icons-material';
import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';

// components

const structure = [
  { id: 0, label: 'Dashboard', link: '/app/dashboard', icon: <HomeIcon /> },
  {
    id: 6,
    label: 'Etapas',
    link: '/app/etapas',
    icon: <CoreIcon />, 
  },
  {
    id: 11,
    label: 'Administración',
    link: '/app/administracion',
    icon: <Settings />,
  },
  {
    id: 12,
    label: 'Consultas',
    link: '/app/consultas',
    icon: <Search />,
    children: [
      {
        label: 'Histórico Folios',
        link: '/app/consultas/historico-folios',
      },
    ],
  }, 
 
  { id: 4, type: 'divider' },
  { id: 5, type: 'title', label: 'ETAPAS' },

  {
    id: 101,
    label: 'Registro MP',
    link: '/app/etapas/registro-materia-prima',
    icon: <DescriptionIcon/>,
  },
  {
    id: 102,
    label: 'Planificación',
    link: '/app/etapas/planeacion-materiales',
    icon: <AssignmentIcon/>,
  },
  {
    id: 103,
    label: 'Descongelado',
    link: '/app/etapas/descogelado',
    icon: <WbSunnyIcon/>,
  },
  {
    id: 104,
    label: 'Eviscerado',
    link: '/app/etapas/eviscerado',
    icon: <DeleteOutlineIcon />,
  },
  {
    id: 105,
    label: 'Cocimiento',
    link: '/app/etapas/cocimiento',
    icon: <WavesIcon />,
  },
  {
    id: 106,
    label: 'OCB',
    link: '/app/etapas/ocb',
    icon: <UIElementsIcon/>,
  },
  {
    id: 107,
    label: 'Mezcla',
    link: '/app/etapas/mezcla',
    icon: <RotateLeftIcon />,
  },
  {
    id: 108,
    label: 'Enlatado',
    link: '/app/etapas/enlatado',
    icon: <StorageIcon />,
  },
  {
    id: 109,
    label: 'Codificado',
    link: '/app/etapas/codificado',
    icon: <LineWeightIcon />,
  },
  
  {
    id: 110,
    label: 'Esterilizado',
    link: '/app/etapas/esterilizado',
    icon: <DoneAllIcon />,
  }, 
  {
    id: 111,
    label: 'Etiquetado',
    link: '/app/etapas/etiquetado',
    icon: <LabelImportantIcon/>,
  },
  {
    id: 112,
    label: 'Laboratorio',
    link: '/app/etapas/laboratorio',
    icon: <ColorizeIcon />,
  },
  {
    id: 113,
    label: 'Embarque',
    link: '/app/etapas/embarque',
    icon: <DirectionsBoatIcon />,
  },

  
   
  
];

export default structure;
