import React from "react";
 
import { Grid } from "@mui/material";
   
// components 

function EtapaEviscerado() {
  return (
    <Grid container spacing={12}> 
      <Grid item xs={12} sm={12} md={12} lg={12}>
  
          <Grid container spacing={1}>
            <Grid item xs={12}>
              
            </Grid>
          </Grid>
       
      </Grid>
  
    </Grid>
  );
}
 

export default EtapaEviscerado;
