import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import AlertError from 'components/AlertError/AlertError';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Delete, Edit } from '@mui/icons-material';
import { formatDatetime, isEditable } from 'functions';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const CocimientoDetalle = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [cocedores, setCocedores] = useState([]);
  const [tallaEspecie, setTallaEspecie] = useState([]);
  const [tallasCocimiento, setTallasCocimiento] = useState([]);

  
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [header, setHeader] = useState({
    cocimiento: '',
    fechareg: '',
    fechaprod: '',
    tipocombustible: '',
    observaciones: '',
  });
  const [detalles, setDetalles] = useState([]);
  const [cocimiento, setCocimiento] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    cocimientoid: id,
    tallaesp: null,
    tallacoc: null,
    estatus: 21,
    cocedornum: null,
    horaentcoc: null,
    temperaturaentcoc: '',
    horalevtemp: null,
    horasalcoc: null,
    temperaturasalcoc: '',
    totalcarrcoc: '',
    totalparrcoc: '',
    observaciones: '',
  });

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Talla y Especie', name: 'tallayesp' },
    { label: 'Talla Cocimiento', name: 'tallacoc' },
    { label: 'Estatus', name: 'estatus', options: { display: false,  viewColumns: false } },
    { label: 'Cocedor Num.', name: 'cocedor' },
    { label: 'Hora Entrada', name: 'horaentcoc' },
    { label: 'Temperatura Entrada', name: 'temperaturaentcoc' },
    { label: 'Hora Levantamiento Temperatura', name: 'horalevtemp' },
    { label: 'Hora Salida', name: 'horasalcoc' },
    { label: 'Temperatura Salida', name: 'temperaturasalcoc' },
    { label: 'Total Carros', name: 'totalcarrcoc' },
    { label: 'Total Parrillas', name: 'totalparrcoc' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => handleEdit(tableMeta.rowData[0])}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const limpiarCocimiento = () => {
    setCocimiento({
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      cocimientoid: id,
      tallaesp: null,
      tallacoc: null,
      estatus: 21,
      cocedornum: null,
      horaentcoc: null,
      temperaturaentcoc: '',
      horalevtemp: null,
      horasalcoc: null,
      temperaturasalcoc: '',
      totalcarrcoc: '',
      totalparrcoc: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (cocimiento.temperaturasalcoc !== '' && !cocimiento.horasalcoc) {
      alert(
        'Es necesaria la temperatura de salida si la hora de salida está indicada.',
      );
      setLoading(false);
      return;
    }

    const body = {
      ...cocimiento,
      cocedornum: cocimiento.cocedornum.id,
      tallaesp: cocimiento.tallaesp.id,
      tallacoc: cocimiento.tallacoc.id,
      horaentcoc: cocimiento.horaentcoc
        ? cocimiento.horaentcoc.$d
          ? formatDatetime(cocimiento.horaentcoc.$d)
          : formatDatetime(cocimiento.horaentcoc)
        : null,
      fechaprod: header.fechaprod,
      temperaturasalcoc: !cocimiento.horasalcoc
        ? null
        : cocimiento.temperaturasalcoc,
      horalevtemp: cocimiento.horalevtemp
        ? cocimiento.horalevtemp.$d
          ? formatDatetime(cocimiento.horalevtemp.$d)
          : formatDatetime(cocimiento.horalevtemp)
        : null,
      horasalcoc: cocimiento.horasalcoc
        ? cocimiento.horasalcoc.$d
          ? formatDatetime(cocimiento.horasalcoc.$d)
          : formatDatetime(cocimiento.horasalcoc)
        : null,
      estatus: !cocimiento.horasalcoc ? 22 : 21,
    };

    axios
      .post('/cocimiento/rpc/detail', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleObservaciones = () => {
    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      observaciones: header.observaciones,
    };
    axios
      .post('/cocimiento/rpc/header', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => setError(error));
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setCocimiento({
      ...cocimiento,
      detailid: detalle.id || '',
      tallaesp: { id: detalle.tallaespid,   name: detalle.tallayesp,  } || '',
      tallacoc: { id: detalle.tallacocid,   name: detalle.tallacoc } || '',
      estatus: detalle.estatusid || '',
      cocedornum: { id: detalle.cocedornum, name: detalle.cocedor } || '',
      horaentcoc: detalle.horaentcoc
        ? new Date(detalle.horaentcoc)
        : null || null,
      temperaturaentcoc: detalle.temperaturaentcoc || '',
      horalevtemp: detalle.horalevtemp
        ? new Date(detalle.horalevtemp)
        : null || null,
      horasalcoc: detalle.horasalcoc
        ? new Date(detalle.horasalcoc)
        : null || null,
      temperaturasalcoc: detalle.temperaturasalcoc || '',
      totalcarrcoc: detalle.totalcarrcoc || '',
      totalparrcoc: detalle.totalparrcoc || '',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/cocimiento/delete/rpc/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/cocimiento/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/cocimiento');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const cocedoresProps = {
    options: cocedores.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const tallaEspecieProps = {
    options: tallaEspecie.map((option) => ({
      id: option.id,
      name: option.description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const tallasCocimientoProps = {
    options: tallasCocimiento.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getCocedores = () => {
    axios
      .get('/catalogo/cocedor', {})
      .then((res) => {
        setCocedores(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getTallaEspecie = (fechaprod) => {
    axios
      .get(`/cocimiento/tallayespecie/${fechaprod}`, {})
      .then((res) => {
        setTallaEspecie(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getTallasCocimiento = () => {
    axios
      .get(`/catalogo/tallacoc`, {})
      .then((res) => {
        setTallasCocimiento(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getDocumento = () => {
    setLoading(true);
    axios
      .get(`/cocimiento/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(
          res.data.data.Header !== null
            ? { ...res.data.data.Header }
            : { ...header },
        );
        getTallaEspecie(res.data.data.Header.fechaprod);
        setDetalles(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTallasCocimiento();
    getCocedores();
    getDocumento();

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.cocimiento}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Fecha de registro'
                    fullWidth
                    disabled
                    value={header.fechareg}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Fecha de producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Combustible'
                    fullWidth
                    disabled
                    value={header.tipocombustible}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Detalles Cocimiento
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      limpiarCocimiento();
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='lg'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        Nuevo Registro
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='noCocedor'
                                {...cocedoresProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='No. Cocedor'
                                  />
                                )}
                                value={cocimiento.cocedornum}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setCocimiento({
                                      ...cocimiento,
                                      cocedornum: {
                                        id: newValue.id,
                                        name: newValue.name,
                                      },
                                    });
                                  } else {
                                    setCocimiento({
                                      ...cocimiento,
                                      cocedornum: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='tallaEspecie'
                                {...tallaEspecieProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Talla y Especie'
                                  />
                                )}
                                value={cocimiento.tallaesp}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setCocimiento({
                                      ...cocimiento,
                                      tallaesp: {
                                        id: newValue.id,
                                        name: newValue.name,
                                      },
                                    });
                                  } else {
                                    setCocimiento({
                                      ...cocimiento,
                                      tallaesp: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='TallaCocimiento'
                                {...tallasCocimientoProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Talla Cocimiento'
                                  />
                                )}
                                value={cocimiento.tallacoc}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setCocimiento({
                                      ...cocimiento,
                                      tallacoc: {
                                        id: newValue.id,
                                        name: newValue.name,
                                      },
                                    });
                                  } else {
                                    setCocimiento({
                                      ...cocimiento,
                                      tallacoc: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={8}></Grid>

                            <Grid item xs={6}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horaentcoc: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horaEntrada'
                                    name='horaEntrada'
                                    label='Hora de entrada a cocedor'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    value={
                                      cocimiento.horaentcoc
                                        ? dayjs(cocimiento.horaentcoc)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horaentcoc: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='temperatura'
                                name='temperatura'
                                label='Temperatura Entrada a Cocedor'
                                fullWidth
                                value={cocimiento.temperaturaentcoc || ''}
                                onChange={(e) =>
                                  setCocimiento({
                                    ...cocimiento,
                                    temperaturaentcoc: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horalevtemp: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horaLevantamiento'
                                    name='horaLevantamiento'
                                    label='Hora de levantamiento de temperatura'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    value={
                                      cocimiento.horalevtemp
                                        ? dayjs(cocimiento.horalevtemp)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horalevtemp: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horasalcoc: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horaSalida'
                                    name='horaSalida'
                                    label='Hora de salida del cocedor'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    value={
                                      cocimiento.horasalcoc
                                        ? dayjs(cocimiento.horasalcoc)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horasalcoc: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='temperaturaSalida'
                                name='temperaturaSalida'
                                label='Temperatura de salida del cocedor'
                                fullWidth
                                value={cocimiento.temperaturasalcoc || ''}
                                onChange={(e) =>
                                  setCocimiento({
                                    ...cocimiento,
                                    temperaturasalcoc: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='noCarros'
                                name='noCarros'
                                label='No. Carros'
                                fullWidth
                                required
                                value={cocimiento.totalcarrcoc || ''}
                                onChange={(e) =>
                                  setCocimiento({
                                    ...cocimiento,
                                    totalcarrcoc: e.target.value,
                                    totalparrcoc: e.target.value * 14,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='noParrillas'
                                name='noParrillas'
                                label='No. Parrillas'
                                fullWidth
                                required
                                value={cocimiento.totalparrcoc || ''}
                                onChange={(e) =>
                                  setCocimiento({
                                    ...cocimiento,
                                    totalparrcoc: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={cocimiento.observaciones || ''}
                                onChange={(e) =>
                                  setCocimiento({
                                    ...cocimiento,
                                    observaciones: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                            getDocumento();
                            setSelectedRows([]);
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <TextField
                disabled={disabledDoc}
                sx={{ mt: 2 }}
                fullWidth
                id='observaciones'
                name='observaciones'
                label='Observaciones'
                multiline
                rows={4}
                value={header.observaciones || ''}
                onChange={(e) => {
                  setHeader({ ...header, observaciones: e.target.value });
                }}
              />
              <Box sx={{ float: 'right', mt: 1 }}>
                <Button
                  disabled={disabledDoc}
                  variant='contained'
                  onClick={handleObservaciones}
                >
                  Guardar
                </Button>
              </Box>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
};

export default CocimientoDetalle;
