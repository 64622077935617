import { AccessTime, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import AlertError from 'components/AlertError/AlertError';
import Widget from 'components/Widget/Widget';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import dayjs from 'dayjs';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import { formatDatetime, isEditable } from 'functions';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL_CARROS':
      return {
        ...state,
        toggleModalCarros: true,
      };
    case 'CLOSE_MODAL_CARROS':
      return {
        ...state,
        toggleModalCarros: false,
      };
    case 'OPEN_MODAL_CODIF':
      return {
        ...state,
        toggleModalCodif: true,
      };
    case 'CLOSE_MODAL_CODIF':
      return {
        ...state,
        toggleModalCodif: false,
      };
    default:
      return null;
  }
};

function CarrosProduccion() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModalCarros: false,
    toggleModalCodif: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsCod, setSelectedRowsCod] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    llenadocarrprod: '',
    fecha: '',
    fechaprod: '',
    loteprod: '',
    ubicacion: '',
    estatus: '',
    totallatbrut: '',
    totallatrep: '',
    totallatvercierr: '',
    totallatmueslab: '',
    produccionnetalat: '',
    produccionnetacaj: '',
    horaini: '',
    horafin: '',
    horafinprod: null,
  });
  const initialCarros = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    llenadocarrprod: id,
    autoclaveid: null,
    hora: null,
    productoid: null,
    observaciones: '',
  };
  const initialCodificadores = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    llenadocarrprod: id,
    codificadorid: null,
    fecha: formatDatetime(new Date()),
    totallatas: 0,
  };
  const [llenado, setLlenado] = useState({ ...initialCarros });
  const [totales, setTotales] = useState({ ...initialCodificadores });
  const [detallesCarros, setDetallesCarros] = useState([]);
  const [detallesCodif, setDetallesCodif] = useState([]);
  const [autoclaves, setAutoclaves] = useState([]);
  const [productos, setProductos] = useState([]);
  const [codificadores, setCodificadores] = useState([]);
  const columnsCarros = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Carro Num.', name: 'carronum' },
    { label: 'Autoclave', name: 'nombreautoclave' },
    { label: 'Producto', name: 'descripcionprod' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditCarros(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsCodificadores = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Codificador', name: 'codificador' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Total Latas', name: 'totallatas' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditCodif(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleUpdateHeader = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      totallatbrut: header.totallatbrut,
      totallatrep: header.totallatrep,
      totallatvercierr: header.totallatvercierr,
      totallatmueslab: header.totallatmueslab,
      produccionnetalat: header.produccionnetalat,
      produccionnetacaj: header.produccionnetacaj,
      horafinprod: header.horafinprod
        ? header.horafinprod.$d
          ? formatDatetime(new Date(header.horafinprod.$d))
          : formatDatetime(new Date(header.horafinprod))
        : null,
    };

    axios
      .post('/codificado/carrosprod/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitCarros = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...llenado,
      autoclaveid: llenado.autoclaveid.id,
      hora: llenado.hora
        ? llenado.hora.$d
          ? formatDatetime(llenado.hora.$d)
          : formatDatetime(llenado.hora)
        : null,
      productoid: llenado.productoid.id,
    };

    axios
      .post('/codificado/carrosprod/detail', body)
      .then(() => {
        setLoading(false);
        dispatch({ type: 'CLOSE_MODAL_CARROS' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_CARROS' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEditCarros = (id) => {
    dispatch({ type: 'OPEN_MODAL_CARROS' });
    let detalle = detallesCarros.find((x) => x.id === id);
    setLlenado({
      ...llenado,
      detailid: detalle.id || '',
      autoclaveid:
        { id: detalle.autoclaveid, name: detalle.nombreautoclave } || null,
      hora: new Date(detalle.hora) || null,
      productoid:
        { id: detalle.productoid, name: detalle.descripcionprod } || null,
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDeleteCarros = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/codificado/delete/carrosprod/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleSubmitCodif = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...totales,
      codificadorid: totales.codificadorid.id,
      fecha: formatDatetime(new Date(totales.fecha)),
    };

    axios
      .post('/codificado/totalescod/detail', body)
      .then(() => {
        setLoading(false);
        dispatch({ type: 'CLOSE_MODAL_CODIF' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_CODIF' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEditCodif = (id) => {
    dispatch({ type: 'OPEN_MODAL_CODIF' });
    let detalle = detallesCodif.find((x) => x.id === id);
    setTotales({
      ...totales,
      detailid: detalle.id || '',
      codificadorid:
        { id: detalle.codificadorid, name: detalle.codificador } || null,
      fecha: detalle.fecha
        ? formatDatetime(new Date(detalle.fecha))
        : null || null,
      totallatas: detalle.totallatas || '',
    });
  };

  const handleDeleteCodif = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/codificado/delete/totalcod/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/codificado/carrosprod/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/codificado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/codificado/carrosprod/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetallesCarros(res.data.data?.Detail);
        setDetallesCodif(res.data.data?.Codificadores);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const autoclavesProps = {
    options: autoclaves.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const codificadoresProps = {
    options: codificadores.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getAutoclaves = () => {
    axios
      .get('/catalogo/autoclave', {})
      .then((res) => {
        setAutoclaves(res.data?.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getProductos = () => {
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data?.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getCodificadores = () => {
    axios
      .get('/catalogo/codificador', {})
      .then((res) => {
        setCodificadores(res.data?.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getAutoclaves();
    getProductos();
    getCodificadores();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.llenadocarrprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote de Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producción Bruta'
                    fullWidth
                    disabled
                    value={header.totallatbrut}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Latas de Reproceso'
                    fullWidth
                    disabled
                    value={header.totallatrep}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Latas Verificación Cierre'
                    fullWidth
                    disabled
                    value={header.totallatvercierr}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Latas Muestra Laboratorio'
                    fullWidth
                    disabled
                    value={header.totallatmueslab}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producción Neta Latas'
                    fullWidth
                    disabled
                    value={header.produccionnetalat}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producción Neta Cajas'
                    fullWidth
                    disabled
                    value={header.produccionnetacaj}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reporte de Llenado de Carros de Producción
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setLlenado({ ...initialCarros });
                      setEdit(false);
                      dispatch({ type: 'OPEN_MODAL_CARROS' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleModalCarros}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_CARROS' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitCarros}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='autoclaveid'
                                {...autoclavesProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Autoclave'
                                  />
                                )}
                                value={llenado.autoclaveid}
                                onChange={(event, newValue) => {
                                  setLlenado({
                                    ...llenado,
                                    autoclaveid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setLlenado({
                                        ...llenado,
                                        hora: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    value={
                                      llenado.hora ? dayjs(llenado.hora) : null
                                    }
                                    onChange={(time) => {
                                      setLlenado({
                                        ...llenado,
                                        hora: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Producto'
                                  />
                                )}
                                value={llenado.productoid}
                                onChange={(event, newValue) => {
                                  setLlenado({
                                    ...llenado,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={llenado.observaciones || ''}
                                onChange={(e) => {
                                  setLlenado({
                                    ...llenado,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_MODAL_CARROS' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detallesCarros}
                  columns={columnsCarros}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteCarros(
                                detallesCarros[selectedRows.data[i].dataIndex]
                                  .id,
                              );
                            }
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                            getDocumento();
                            setSelectedRows([]);
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Totales por Codificador
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setTotales({ ...initialCodificadores });
                      setEdit(false);
                      dispatch({ type: 'OPEN_MODAL_CODIF' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleModalCodif}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_CODIF' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitCodif}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='codificadorid'
                                {...codificadoresProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Codificador'
                                  />
                                )}
                                value={totales.codificadorid}
                                onChange={(event, newValue) => {
                                  setTotales({
                                    ...totales,
                                    codificadorid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  required
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha de Registro'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={dayjs(new Date(totales.fecha))}
                                  onChange={(date) => {
                                    setTotales({
                                      ...totales,
                                      fecha: formatDatetime(new Date(date)),
                                    });
                                    setError(null);
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number', min: 0 }}
                                id='totallatas'
                                name='totallatas'
                                label='Total Latas'
                                fullWidth
                                required
                                value={totales.totallatas || ''}
                                onChange={(e) => {
                                  setTotales({
                                    ...totales,
                                    totallatas: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_MODAL_CODIF' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detallesCodif}
                  columns={columnsCodificadores}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRowsCod,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRowsCod(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteCodif(
                                detallesCodif[selectedRows.data[i].dataIndex]
                                  .id,
                              );
                            }
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                            getDocumento();
                            setSelectedRowsCod([]);
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <form onSubmit={handleUpdateHeader}>
                <Grid container spacing={2} sx={{ mt: 1.5 }}>
                  <Grid item xs={3}>
                    <TextField
                      disabled={disabledDoc}
                      inputProps={{ type: 'number', min: 0 }}
                      id='totallatbrut'
                      name='totallatbrut'
                      label='Producción Bruta'
                      fullWidth
                      required
                      value={header.totallatbrut || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          totallatbrut: e.target.value,
                          produccionnetalat:
                            parseFloat(e.target.value) -
                            // parseFloat(header.totallatvercierr) -
                            parseFloat(header.totallatrep) -
                            parseFloat(header.totallatmueslab),
                          produccionnetacaj: Math.round(
                            (parseFloat(e.target.value) -
                              // parseFloat(header.totallatvercierr) -
                              parseFloat(header.totallatrep) -
                              parseFloat(header.totallatmueslab)) /
                              48,
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={disabledDoc}
                      inputProps={{ type: 'number', min: 0 }}
                      id='totallatrep'
                      name='totallatrep'
                      label='Latas de Reproceso'
                      fullWidth
                      required
                      value={header.totallatrep || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          totallatrep: e.target.value,
                          produccionnetalat:
                            parseFloat(header.totallatbrut) -
                            // parseFloat(header.totallatvercierr) -
                            parseFloat(e.target.value) -
                            parseFloat(header.totallatmueslab),
                          produccionnetacaj: Math.round(
                            (parseFloat(header.totallatbrut) -
                              // parseFloat(header.totallatvercierr) -
                              parseFloat(e.target.value) -
                              parseFloat(header.totallatmueslab)) /
                              48,
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={disabledDoc}
                      inputProps={{ type: 'number', min: 0 }}
                      id='totallatvercierr'
                      name='totallatvercierr'
                      label='Latas Verificación Cierre'
                      fullWidth
                      required
                      value={header.totallatvercierr || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          totallatvercierr: e.target.value,
                          produccionnetalat:
                            parseFloat(header.totallatbrut) -
                            // parseFloat(e.target.value) -
                            parseFloat(header.totallatrep) -
                            parseFloat(header.totallatmueslab),
                          produccionnetacaj: Math.round(
                            (parseFloat(header.totallatbrut) -
                              // parseFloat(e.target.value) -
                              parseFloat(header.totallatrep) -
                              parseFloat(header.totallatmueslab)) /
                              48,
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={disabledDoc}
                      inputProps={{ type: 'number', min: 0 }}
                      id='totallatmueslab'
                      name='totallatmueslab'
                      label='Latas Muestra Laboratorio'
                      fullWidth
                      required
                      value={header.totallatmueslab || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          totallatmueslab: e.target.value,
                          produccionnetalat:
                            parseFloat(header.totallatbrut) -
                            // parseFloat(header.totallatvercierr) -
                            parseFloat(header.totallatrep) -
                            parseFloat(e.target.value),
                          produccionnetacaj: Math.round(
                            (parseFloat(header.totallatbrut) -
                              // parseFloat(header.totallatvercierr) -
                              parseFloat(header.totallatrep) -
                              parseFloat(e.target.value)) /
                              48,
                          ),
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      inputProps={{ type: 'number', min: 0 }}
                      id='produccionnetalat'
                      name='produccionnetalat'
                      label='Producción Neta Latas'
                      fullWidth
                      disabled
                      value={header.produccionnetalat || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      inputProps={{ type: 'number', min: 0 }}
                      id='produccionnetacaj'
                      name='produccionnetacaj'
                      label='Producción Neta Cajas'
                      fullWidth
                      disabled
                      value={header.produccionnetacaj || ''}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Box sx={{ display: 'flex' }}>
                      <Tooltip title='Hora actual'>
                        <Button
                          disabled={disabledDoc}
                          variant='contained'
                          onClick={() => {
                            setHeader({
                              ...header,
                              horafinprod: dayjs(new Date()),
                            });
                          }}
                        >
                          <AccessTime />
                        </Button>
                      </Tooltip>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          disabled={disabledDoc}
                          required
                          id='hora'
                          name='hora'
                          label='Hora Fin Producción'
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              required: true,
                            },
                          }}
                          value={
                            header.horafinprod
                              ? dayjs(new Date(header.horafinprod))
                              : null
                          }
                          onChange={(time) => {
                            setHeader({
                              ...header,
                              horafinprod: time,
                            });
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                </Grid>
                {!disabledDoc ? (
                  <Box sx={{ float: 'right', mt: 2 }}>
                    <Button
                      type='submit'
                      variant='contained'
                      // onClick={handleUpdateHeader}
                    >
                      Guardar
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default CarrosProduccion;
