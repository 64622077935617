import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, isEditable } from 'functions';
import { Delete } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function MaterialEnvase() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const [header, setHeader] = useState({
    ingresomatenv: '',
    fecha: '',
    ubicacionid: '',
    ubicacion: '',
    estatusid: '',
    estatus: '',
    registrodiaela: '',
    loteprod: '',
    horaini: '',
    horafin: '',
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    ingresomatenvid: id,
    tarimanum: 0,
    loteenv: 0,
    horaentprodenv: '',
    lotetap: 0,
    totaltaplot: 0,
    horaentprodtap: '',
    observaciones: '',
  });

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'No. Tarima', name: 'tarimanum' },
    { label: 'No. Lote de Envase', name: 'loteenv' },
    { label: 'Hora de Entrada a Producción (Envase)', name: 'horaentprodenv' },
    { label: 'No. Lote Tapa', name: 'lotetap' },
    { label: 'Núm. Tapas Lote', name: 'totaltaplot' },
    { label: 'Hora de Entrada a Producción (Tapa)', name: 'horaentprodtap' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/enlatado/materialenv/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...registro,
      horaentprodenv: registro.horaentprodenv
        ? registro.horaentprodenv.$d
          ? formatDatetime(registro.horaentprodenv.$d)
          : formatDatetime(registro.horaentprodenv)
        : null,
      horaentprodtap: registro.horaentprodtap
        ? registro.horaentprodtap.$d
          ? formatDatetime(registro.horaentprodtap.$d)
          : formatDatetime(registro.horaentprodtap)
        : null,
    };

    axios
      .post('/enlatado/materialenv/detail', body)
      .then((res) => {
        console.log(res);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      tarimanum: detalle.tarimanum,
      loteenv: detalle.loteenv,
      horaentprodenv: new Date(detalle.horaentprodenv),
      lotetap: detalle.lotetap,
      totaltaplot: detalle.totaltaplot,
      horaentprodtap: new Date(detalle.horaentprodtap),
      observaciones: detalle.observaciones,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/materialenv/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/materialenv/document/finalizar', body)
          .then((res) => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      ingresomatenvid: id,
      tarimanum: 0,
      loteenv: 0,
      horaentprodenv: '',
      lotetap: 0,
      totaltaplot: 0,
      horaentprodtap: '',
      observaciones: '',
    });
  };

  useEffect(() => {
    getRegistro();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.ingresomatenv}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Lote de Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Material de Envase
                </Typography>
                <Box sx={{ float: 'right' }}>
                  <Button
                    variant='contained'
                    onClick={() => {
                      limpiarRegistro();
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number', min: 0 }}
                                  id='tarimanum'
                                  label='No. Tarima'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      tarimanum: e.target.value,
                                    })
                                  }
                                  name='tarimanum'
                                  value={registro.tarimanum || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  // inputProps={{ type: 'number', min: 0 }}
                                  id='loteenv'
                                  label='No. Lote de Envase'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      loteenv: e.target.value,
                                    })
                                  }
                                  name='loteenv'
                                  value={registro.loteenv || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horaentprodenv: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora de Entrada a Producción (Envase)'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      value={
                                        registro.horaentprodenv
                                          ? dayjs(registro.horaentprodenv)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horaentprodenv: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  // inputProps={{ type: 'number', min: 0 }}
                                  id='lotetap'
                                  label='No. Lote de Tapa'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      lotetap: e.target.value,
                                    })
                                  }
                                  name='lotetap'
                                  value={registro.lotetap || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number', min: 0 }}
                                  id='totaltaplot'
                                  label='Núm. Tapas Lote'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      totaltaplot: e.target.value,
                                    })
                                  }
                                  name='totaltaplot'
                                  value={registro.totaltaplot || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horaentprodtap: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora de Entrada a Producción (Tapa)'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      value={
                                        registro.horaentprodtap
                                          ? dayjs(registro.horaentprodtap)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horaentprodtap: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='observaciones'
                                  name='observaciones'
                                  label='Observaciones'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      observaciones: e.target.value,
                                    })
                                  }
                                  value={registro.observaciones || ''}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .id,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default MaterialEnvase;
