import { Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: true,
      };
    case 'CLOSE_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: false,
      };
    default:
      return null;
  }
};

function AnalisisFisicoquimico() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalEdit: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsPr, setSelectedRowsPr] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    analisisfisicoquim: '',
    fecha: '',
    fechaetiq: '',
    fechaprod: '',
    loteprod: '',
    ubicacion: '',
    estatus: '',
    presentacionenv: '',
    producto: '',
    marca: '',
    horaini: '',
    horafin: '',
  });
  // const initialEvaluacion = {
  //   userid: sessionStorage.getItem('user_id'),
  //   detailid: 0,
  //   analisisfisicoquimid: id,
  //   pesoneto: 0,
  //   vacio: 0,
  //   pesodrenado: 0,
  //   aceite: 0,
  //   caldoveg: 0,
  //   ph: 0,
  //   indiceacid: 0,
  //   cloruros: 0,
  //   color: 0,
  //   colorobsid: null,
  //   olor: 0,
  //   olorobsid: null,
  //   apariencia: 0,
  //   aparienciaobsid: null,
  //   textura: 0,
  //   texturaobsid: null,
  //   observaciones: '',
  // };
  const initialEvaluacion = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    analisisfisicoquimid: id,
    muestranum: 0,
    evaluacionfqid: null,
    resultado: 0,
    observacionid: null,
    observaciones: '',
  };
  const [evaluacion, setEvaluacion] = useState([]);
  const initialEvaluacionEdit = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    analisisfisicoquimid: id,
    muestranum: 0,
    evaluacionfqid: null,
    resultado: 0,
    observacionid: null,
    observaciones: '',
  };
  const [evaluacionEdit, setEvaluacionEdit] = useState({
    ...initialEvaluacionEdit,
  });
  const [detalles, setDetalles] = useState([]);
  const [promedios, setPromedios] = useState([]);
  const [observaciones, setObservaciones] = useState('');
  const [hallazgos, setHallazgos] = useState([]);
  const [numMuestras, setNumMuestras] = useState(0);
  const [elementos, setElementos] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Muestra Num.', name: 'muestranum' },
    { label: 'Evaluación', name: 'evaluacionfq' },
    { label: 'Valor', name: 'resultado' },
    { label: 'Observación', name: 'observacion' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setInfo(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsPromedios = [
    {
      label: 'ID',
      name: 'analisisfisicoquimid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Evaluación', name: 'evaluacionf' },
    { label: 'Total Muestras', name: 'totalmues' },
    { label: 'Promedio', name: 'promedio' },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    if (numMuestras > 0) {
      let err = false;
      let message = '';

      for (let i = 0; i < evaluacion.length; i++) {
        const body = {
          ...evaluacion[i],
          evaluacionfqid: evaluacion[i].evaluacionfqid.id,
          observacionid: evaluacion[i].observacionid
            ? evaluacion[i].observacionid.id
            : null,
        };
        await axios
          .post('/laboratorio/analisisfq/detail', body)
          .catch((error) => {
            console.log(error);
            err = true;
            message = error.response?.data?.message;
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
      setLoading(false);
      setEvaluacion([]);
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
    } else {
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      Swal.fire({
        title: 'Advertencia',
        text: 'Indique el número de muestras a evaluar.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
      setLoading(false);
    }

    // const body = {
    //   ...evaluacion,
    //   colorobsid: evaluacion.colorobsid ? evaluacion.colorobsid.id : null,
    //   olorobsid: evaluacion.olorobsid ? evaluacion.olorobsid.id : null,
    //   aparienciaobsid: evaluacion.aparienciaobsid
    //     ? evaluacion.aparienciaobsid.id
    //     : null,
    //   texturaobsid: evaluacion.texturaobsid ? evaluacion.texturaobsid.id : null,
    // };

    // axios
    //   .post('/laboratorio/analisisfq/detail', body)
    //   .then(() => {
    //     dispatch({ type: 'CLOSE_INPUT_MODAL' });
    //     Swal.fire({
    //       title: 'Éxito',
    //       text: '¡Se ha guardado la información exitosamente!',
    //       icon: 'success',
    //       confirmButtonColor: '#196b86',
    //     });
    //     getDocumento();
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //     setLoading(false);
    //   });
  };

  const handleNumMuestras = () => {
    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      totalmues: numMuestras,
    };

    axios
      .post('/laboratorio/analisisfq/header', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const setInfo = (id) => {
    dispatch({ type: 'OPEN_MODAL_EDIT' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacionEdit({
      ...evaluacionEdit,
      detailid: id,
      muestranum: detalle.muestranum || 0,
      evaluacionfqid:
        { id: detalle.evaluacionfq_id, name: detalle.evaluacionfq } || null,
      resultado: detalle.resultado || 0,
      observacionid: detalle.observacionid
        ? { id: detalle.observacionid, name: detalle.observacion }
        : null || null,
      observaciones: detalle.observaciones || '',
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...evaluacionEdit,
      evaluacionfqid: evaluacionEdit.evaluacionfqid.id,
      observacionid: evaluacionEdit.observacionid
        ? evaluacionEdit.observacionid.id
        : null,
    };
    axios
      .post('/laboratorio/analisisfq/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/analisisfq/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/laboratorio/analisisfq/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/laboratorio');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const handleNew = () => {
    let muestras = [];
    for (let i = 0; i < numMuestras; i++) {
      const muestra = {
        userid: sessionStorage.getItem('user_id'),
        detailid: 0,
        analisisfisicoquimid: id,
        muestranum: i + 1,
        evaluacionfqid: null,
        resultado: 0,
        observacionid: null,
        observaciones: '',
      };
      muestras.push({ ...muestra });
    }
    setEvaluacion([...evaluacion, ...muestras]);
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/laboratorio/analisisfq/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setPromedios(res.data.data?.Promedios);
        setObservaciones(res.data.data?.Observaciones[0]?.observacion);
        setNumMuestras(
          res.data.data?.Header?.totalmues
            ? parseInt(res.data.data?.Header?.totalmues)
            : 0,
        );
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const hallazgosProps = {
    options: hallazgos.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getHallazgos = () => {
    axios
      .get('/catalogo/hallazgoafq', {})
      .then((res) => {
        setHallazgos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const elementosProps = {
    options: elementos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getElementos = () => {
    axios
      .get('/catalogo/elementoevalua/12/30', {})
      .then((res) => {
        setElementos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getHallazgos();
    getElementos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.analisisfisicoquim}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Presentación'
                    fullWidth
                    disabled
                    value={header.presentacionenv}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producto'
                    fullWidth
                    disabled
                    value={header.producto}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Marca'
                    fullWidth
                    disabled
                    value={header.marca}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Número de Muestras
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {detalles.length === 0 ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={detalles.length > 0}
                        variant='contained'
                        onClick={() => {
                          setEvaluacion([]);
                          handleNumMuestras();
                        }}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <TextField
                disabled={detalles.length > 0}
                required
                inputProps={{ type: 'number' }}
                id='nummuestras'
                name='nummuestras'
                label='Muestras a Evaluar'
                fullWidth
                value={numMuestras}
                onChange={(e) => {
                  setNumMuestras(e.target.value);
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Análisis Fisicoquímicos
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {!disabledDoc ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={disabledDoc}
                        variant='contained'
                        onClick={() => {
                          setEvaluacion({ ...initialEvaluacion });
                          setEdit(false);
                          dispatch({ type: 'OPEN_INPUT_MODAL' });
                          handleNew();
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
                {/* MODAL NUEVO */}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                    setEvaluacion([]);
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='lg'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='elementoevalua'
                                {...elementosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Elemento a Evaluar'
                                  />
                                )}
                                value={evaluacion[0]?.evaluacionfqid || null}
                                onChange={(event, newValue) => {
                                  const idMuestra = newValue
                                    ? newValue.id
                                    : null;
                                  const lastId = detalles.filter(
                                    (x) => x.evaluacionfq_id === idMuestra,
                                  ).length;
                                  for (let i = 0; i < evaluacion.length; i++) {
                                    const newArray = [...evaluacion];
                                    newArray[i].evaluacionfqid = newValue;
                                    if (newValue) {
                                      newArray[i].muestranum =
                                        parseInt(i + 1) + parseInt(lastId);
                                    }
                                    setEvaluacion([...newArray]);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {[...new Array(numMuestras)].map(
                              (muestra, index) => (
                                <>
                                  <Grid item xs={3}>
                                    <TextField
                                      disabled={disabledDoc}
                                      required
                                      inputProps={{ type: 'number' }}
                                      id={`muestra${index + 1}`}
                                      name={`muestra${index + 1}`}
                                      label={`Valor Muestra ${index + 1}`}
                                      fullWidth
                                      value={evaluacion[index]?.resultado}
                                      onChange={(e) => {
                                        const newArray = [...evaluacion];
                                        newArray[index].resultado =
                                          e.target.value;
                                        setEvaluacion([...newArray]);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Autocomplete
                                      disabled={disabledDoc}
                                      fullWidth
                                      id='observacionid'
                                      {...hallazgosProps}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label='Observación'
                                        />
                                      )}
                                      value={
                                        evaluacion[index]?.observacionid || null
                                      }
                                      onChange={(event, newValue) => {
                                        const newArray = [...evaluacion];
                                        newArray[index].observacionid =
                                          newValue;
                                        setEvaluacion([...newArray]);
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      disabled={disabledDoc}
                                      id={`observacion${index + 1}`}
                                      name={`observacion${index + 1}`}
                                      label='Observaciones'
                                      fullWidth
                                      value={evaluacion[index]?.observaciones}
                                      onChange={(e) => {
                                        const newArray = [...evaluacion];
                                        newArray[index].observaciones =
                                          e.target.value;
                                        setEvaluacion([...newArray]);
                                      }}
                                    />
                                  </Grid>
                                </>
                              ),
                            )}
                            {/* <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='pesoneto'
                                name='pesoneto'
                                label='Peso Neto'
                                fullWidth
                                value={evaluacion.pesoneto}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    pesoneto: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='vacio'
                                name='vacio'
                                label='Vacío'
                                fullWidth
                                value={evaluacion.vacio}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    vacio: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='pesodrenado'
                                name='pesodrenado'
                                label='Peso Drenado'
                                fullWidth
                                value={evaluacion.pesodrenado}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    pesodrenado: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='aceite'
                                name='aceite'
                                label='Aceite'
                                fullWidth
                                value={evaluacion.aceite}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    aceite: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='caldoveg'
                                name='caldoveg'
                                label='Caldo Vegetal'
                                fullWidth
                                value={evaluacion.caldoveg}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    caldoveg: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='ph'
                                name='ph'
                                label='PH'
                                fullWidth
                                value={evaluacion.ph}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    ph: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='indiceacid'
                                name='indiceacid'
                                label='Índice de Acidez'
                                fullWidth
                                value={evaluacion.indiceacid}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    indiceacid: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='cloruros'
                                name='cloruros'
                                label='Cloruros'
                                fullWidth
                                value={evaluacion.cloruros}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    cloruros: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='color'
                                name='color'
                                label='Color'
                                fullWidth
                                value={evaluacion.color}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    color: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='colorobsid'
                                {...hallazgosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Observación Color'
                                  />
                                )}
                                value={evaluacion.colorobsid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    colorobsid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='olor'
                                name='olor'
                                label='Olor'
                                fullWidth
                                value={evaluacion.olor}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    olor: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='olorobsid'
                                {...hallazgosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Observación Olor'
                                  />
                                )}
                                value={evaluacion.olorobsid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    olorobsid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='apariencia'
                                name='apariencia'
                                label='Apariencia'
                                fullWidth
                                value={evaluacion.apariencia}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    apariencia: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='aparienciaobsid'
                                {...hallazgosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Observación Apariencia'
                                  />
                                )}
                                value={evaluacion.aparienciaobsid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    aparienciaobsid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='textura'
                                name='textura'
                                label='Textura'
                                fullWidth
                                value={evaluacion.textura}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    textura: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='texturaobsid'
                                {...hallazgosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Observación Textura'
                                  />
                                )}
                                value={evaluacion.texturaobsid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    texturaobsid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={evaluacion.observaciones || ''}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid> */}
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              dispatch({ type: 'CLOSE_INPUT_MODAL' });
                              setEvaluacion([]);
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                {/* MODAL EDIT */}
                <Dialog
                  open={state.toggleModalEdit}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_EDIT' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        Editar Registro
                      </DialogTitle>
                      <form onSubmit={handleEdit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled
                                fullWidth
                                id='elementoevalua'
                                {...elementosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label='Elemento a Evaluar'
                                  />
                                )}
                                value={evaluacionEdit?.evaluacionfqid || null}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='resultado'
                                name='resultado'
                                label='Valor'
                                fullWidth
                                value={evaluacionEdit?.resultado}
                                onChange={(e) => {
                                  setEvaluacionEdit({
                                    ...evaluacionEdit,
                                    resultado: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='observacionid'
                                {...hallazgosProps}
                                renderInput={(params) => (
                                  <TextField {...params} label='Observación' />
                                )}
                                value={evaluacionEdit?.observacionid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacionEdit({
                                    ...evaluacionEdit,
                                    observacionid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={evaluacionEdit?.observaciones}
                                onChange={(e) => {
                                  setEvaluacionEdit({
                                    ...evaluacionEdit,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_MODAL_EDIT' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Promedios
                </Typography>
              </Box>
              {loading ? <LinearProgress /> : ''}
              <MUIDataTable
                data={promedios}
                columns={columnsPromedios}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                  rowsSelected: selectedRowsPr,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRowsPr(rowsSelected);
                  },
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    id='observaciones'
                    name='observaciones'
                    label='Observaciones'
                    multiline
                    rows={2}
                    value={observaciones || ''}
                    // onChange={(e) => {
                    //   setObservaciones(e.target.value);
                    // }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default AnalisisFisicoquimico;
