import { Delete, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { formatDatetime, isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function ProductoNoConforme() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    dañosprocetiqid: 0,
    dañosprocetiq: '',
    fecha: '',
    fechaprod: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    horaini: '',
    horafin: '',
  });
  const [evaluacion, setEvaluacion] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    dañosprocetiqid: id,
    fechahorainc: formatDatetime(new Date()),
    totalcaj: 0,
    totalpiez: 0,
    lugarinc: '',
    porcentajedañ: 0,
    causa: '',
    accionescorr: '',
    comentarios: '',
  });
  const [detalles, setDetalles] = useState([]);

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha Hora', name: 'fechahorainc' },
    { label: 'Cajas Dañadas', name: 'totalcaj' },
    { label: 'Piezas Dañadas', name: 'totalpiez' },
    { label: 'Lugar Incidente', name: 'lugarinc' },
    { label: '% Dañado', name: 'porcentajedañ' },
    { label: 'Causa', name: 'causa' },
    { label: 'Acción Correctiva', name: 'accionescorr' },
    { label: 'Comentarios', name: 'comentarios' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/etiquetado/danosetiq/detail', evaluacion)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: id,
      fechahorainc: detalle.fechahorainc
        ? formatDatetime(new Date(detalle.fechahorainc))
        : null || null,
      totalcaj: detalle.totalcaj || '0',
      totalpiez: detalle.totalpiez || '0',
      lugarinc: detalle.lugarinc || '',
      porcentajedañ: detalle.porcentajedañ || '0',
      causa: detalle.causa || '',
      accionescorr: detalle.accionescorr || '',
      comentarios: detalle.comentarios || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/etiquetado/delete/danosetiq/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/etiquetado/danosetiq/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/etiquetado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/etiquetado/danosetiq/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const limpiarEvaluacion = () => {
    setEvaluacion({
      ...evaluacion,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      dañosprocetiqid: id,
      fechahorainc: formatDatetime(new Date()),
      totalcaj: 0,
      totalpiez: 0,
      lugarinc: '',
      porcentajedañ: 0,
      causa: '',
      accionescorr: '',
      comentarios: '',
    });
  };

  useEffect(() => {
    getDocumento();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.dañosprocetiq || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus || ''}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    label='Fecha'
                    fullWidth
                    disabled
                    value={header.fecha || ''}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    label='Fecha Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod || ''}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini || ''}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin || ''}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Producto No Confrome en Proceso de Etiquetado
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      // setProceso({ ...initialProceso });
                      limpiarEvaluacion();
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  required
                                  disableFuture
                                  id='fechahorainc'
                                  name='fechahorainc'
                                  label='Fecha y Hora Incidente'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={dayjs(
                                    new Date(evaluacion.fechahorainc),
                                  )}
                                  onChange={(date) =>
                                    setEvaluacion({
                                      ...evaluacion,
                                      fechahorainc: formatDatetime(
                                        new Date(date),
                                      ),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                }}
                                id='totalcaj'
                                name='totalcaj'
                                label='Total Cajas Dañadas'
                                fullWidth
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    totalcaj: e.target.value,
                                    porcentajedañ:
                                      (parseFloat(evaluacion.totalpiez) /
                                        (parseFloat(e.target.value) * 48)) *
                                      100,
                                  });
                                }}
                                value={evaluacion.totalcaj || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                }}
                                id='totalpiez'
                                name='totalpiez'
                                label='Total Piezas Dañadas'
                                fullWidth
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    totalpiez: e.target.value,
                                    porcentajedañ:
                                      (parseFloat(e.target.value) /
                                        (parseFloat(evaluacion.totalcaj) *
                                          48)) *
                                      100,
                                  })
                                }
                                value={evaluacion.totalpiez || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='lugarinc'
                                name='lugarinc'
                                label='Lugar Incidente'
                                fullWidth
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    lugarinc: e.target.value,
                                  })
                                }
                                value={evaluacion.lugarinc || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled
                                id='porcentajedañ'
                                name='porcentajedañ'
                                label='% Dañado'
                                fullWidth
                                value={evaluacion.porcentajedañ || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='causa'
                                name='causa'
                                label='Causa'
                                fullWidth
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    causa: e.target.value,
                                  })
                                }
                                value={evaluacion.causa || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='accionescorr'
                                name='accionescorr'
                                label='Acción Correctiva'
                                fullWidth
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    accionescorr: e.target.value,
                                  })
                                }
                                value={evaluacion.accionescorr || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='comentarios'
                                name='comentarios'
                                label='Comentarios'
                                fullWidth
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    comentarios: e.target.value,
                                  })
                                }
                                value={evaluacion.comentarios || ''}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default ProductoNoConforme;
