import React from 'react';
import { Box, Button, Grid, LinearProgress, Typography } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ModalOCB from 'components/ModalOCB/ModalOCB';
import { Link } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    default:
      return null;
  }
};

const ViewInfoOCB = ({
  title,
  columns,
  data,
  formato,
  handleSubmit,
  registro,
  setRegistro,
  loading,
  loadingSave = false,
  error,
  setError,
}) => {
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            '& a': {
              textDecoration: 'none',
              color: '#fff',
            },
          }}
        >
          <Typography variant='h3' color='text' noWrap>
            {title}
          </Typography>
          <Button
            variant='contained'
            sx={{ float: 'right' }}
            onClick={() => dispatch({ type: 'OPEN_MODAL' })}
          >
            Nuevo
          </Button>
          <ModalOCB
            open={state.toggleModal}
            dispatch={dispatch}
            formato={formato}
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            err={error}
            setErr={setError}
            load={loadingSave}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading ? <LinearProgress /> : ''}
        <MUIDataTable
          data={data}
          columns={columns}
          options={{ responsive: 'standard', selectableRows: 'none' }}
        />
      </Grid>
    </Grid>
  );
};

export default ViewInfoOCB;
