import { Delete, Edit, FileUpload, ShowChart } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  ToggleButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useRef, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_GRAFICA':
      return {
        ...state,
        toggleModalGrafica: true,
      };
    case 'CLOSE_MODAL_GRAFICA':
      return {
        ...state,
        toggleModalGrafica: false,
      };
    default:
      return null;
  }
};

export const Evaluacion = () => {
  return (
    <>
      <ToggleButton value='1'>1</ToggleButton>
      <ToggleButton value='2'>2</ToggleButton>
      <ToggleButton value='3'>3</ToggleButton>
      <ToggleButton value='4'>4</ToggleButton>
      <ToggleButton value='5'>5</ToggleButton>
      <ToggleButton value='6'>6</ToggleButton>
      <ToggleButton value='7'>7</ToggleButton>
      <ToggleButton value='8'>8</ToggleButton>
      <ToggleButton value='9'>9</ToggleButton>
      <ToggleButton value='10'>10</ToggleButton>
    </>
  );
};

function EvaluacionExternaPT() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalGrafica: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    evaluacionextpt: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    producto: '',
    loteprod: '',
    marca: '',
    verifiquemar: '',
    horaini: '',
    horafin: '',
    latascaja:48
  });
  const initialEvaluacion = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluaextptid: id,
    etiqueta: '0',
    suciedad: '0',
    oxido: '0',
    raspaduras: '0',
    abolladuras: '0',
    anillo: '0',
    cierreherm: '0',
    totaldef: 0,
    observaciones: '',
  };
  const [evaluacion, setEvaluacion] = useState({ ...initialEvaluacion });
  const [detalles, setDetalles] = useState([]);
  const [totales, setTotales] = useState([]);
  const [files, setFiles] = useState([]);
  const [drag, setDrag] = useState(false);
  const [etiquetaFile, setEtiquetaFile] = useState(null);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 500,
      type: 'line',
      // dropShadow: {
      //   enabled: true,
      //   color: '#000',
      //   top: 18,
      //   left: 7,
      //   blur: 10,
      //   opacity: 0.2,
      // },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: true,
      },
    },
    // colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Gráfica de Defectos',
      align: 'left',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      title: {
        text: 'Hora',
      },
    },
    yaxis: {
      title: {
        text: 'No. Defectos',
      },
      min: 0,
      max: 25,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  });
  const inputRef = useRef();

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Tarima Num.', name: 'tarimanum' },
    { label: 'Hora Registro', name: 'horareg' },
    { label: 'Etiqueta Mal Colocada', name: 'etiqueta' },
    { label: 'Suciedad', name: 'suciedad' },
    { label: 'Óxido', name: 'oxido' },
    { label: 'Raspaduras', name: 'raspaduras' },
    { label: 'Abolladuras', name: 'abolladura' },
    { label: 'Defecto en el Anillo', name: 'anillo' },
    { label: 'Defecto en Cierre Hermético', name: 'cierreherm' },
    { label: 'Total de defectos', name: 'totaldef' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsTotales = [
    { label: 'Totales', name: 'totales' },
    { label: 'Suciedad', name: 'suciedad' },
    { label: 'Óxido', name: 'oxido' },
    { label: 'Raspaduras', name: 'raspaduras' },
    { label: 'Abolladuras', name: 'abolladura' },
    { label: 'Defecto en el anillo', name: 'anillo' },
    { label: 'Defecto en Cierre Hermético', name: 'cierreherm' },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'file' },
    {
      label: 'Eliminar',
      name: 'eliminar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Eliminar Archivo'>
              <Fab
                color='primary'
                size='small'
                // onClick={() => {
                //   setEdit(true);
                //   handleEdit(tableMeta.rowData[0]);
                // }}
              >
                <Delete />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/etiquetado/evaluaext/detail', evaluacion)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: id,
      etiqueta: detalle.etiqueta || '0',
      suciedad: detalle.suciedad || '0',
      oxido: detalle.oxido || '0',
      raspaduras: detalle.raspaduras || '0',
      abolladura: detalle.abolladura || '0',
      anillo: detalle.anillo || '0',
      cierreherm: detalle.cierreherm || '0',
      totaldef: detalle.totaldef || '0',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/etiquetado/delete/evaluaext/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/etiquetado/evaluaext/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/etiquetado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/etiquetado/evaluaext/document/${id}`)
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setTotales(res.data.data?.Totales);
        setFiles(res.data.data?.Files);
        if (res.data.data?.Detail.length > 0) {
          getDataGrafica();
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getTotalDefectos = (elemento, value) => {
    let total = 0;
    total =
      (elemento === 'etiqueta'
        ? parseInt(value)
        : parseInt(evaluacion.etiqueta)) +
      (elemento === 'suciedad'
        ? parseInt(value)
        : parseInt(evaluacion.suciedad)) +
      (elemento === 'oxido' ? parseInt(value) : parseInt(evaluacion.oxido)) +
      (elemento === 'raspaduras'
        ? parseInt(value)
        : parseInt(evaluacion.raspaduras)) +
      (elemento === 'abolladuras'
        ? parseInt(value)
        : parseInt(evaluacion.abolladuras)) +
      (elemento === 'anillo' ? parseInt(value) : parseInt(evaluacion.anillo)) +
      (elemento === 'cierreherm'
        ? parseInt(value)
        : parseInt(evaluacion.cierreherm));
    return total;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files.length) {
      return;
    }
    const file = e.dataTransfer.files[0];
    const objectURL = URL.createObjectURL(file);
    document.getElementById('etiqueta').src = objectURL;
    setEtiquetaFile(e.dataTransfer.files[0]);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    if (!e.target.files.length) {
      return;
    }
    const file = e.target.files[0];
    const objectURL = URL.createObjectURL(file);
    document.getElementById('etiqueta').src = objectURL;
    setEtiquetaFile(e.target.files[0]);
  };

  const handleRemoveImg = () => {
    document.getElementById('etiqueta').src = '';
    setEtiquetaFile(null);
  };

  const getDataGrafica = () => {
    axios
      .get(`/etiquetado/evaluaext/grafica/${id}`, {})
      .then((res) => {
        let arrayOptions = [];
        let arrayData = [];
        const data = res.data.data.data;
        for (let i = 0; i < data.length; i++) {
          let detailData = data[i].data
            .replace("'", '')
            .replace('[', '')
            .replace(']', '')
            .split(',');
          arrayData.push({ name: data[i].name, data: detailData });
        }
        const opt = res.data.data.categories;
        let detailOptions = opt[0].categories.split(',');
        for (let i = 0; i < detailOptions.length; i++) {
          arrayOptions.push(
            detailOptions[i]
              .replace('[', '')
              .replace(']', '')
              .replace("'", '')
              .replace("'", ''),
          );
        }
        setSeries(arrayData);
        setOptions({
          ...options,
          xaxis: {
            ...options.xaxis,
            categories: [...arrayOptions],
          },
        });
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.evaluacionextpt}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producto'
                    fullWidth
                    disabled
                    value={header.producto}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Marca'
                    fullWidth
                    disabled
                    value={header.marca}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Etiqueta correspondiente a la marca'
                    fullWidth
                    disabled
                    value={header.verifiquemar}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Latas Caja'
                    fullWidth
                    disabled
                    value={header.latascaja}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>             
                
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Evaluación Externa de Producto Terminado
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  <Grid container spacing={1}>
                    {detalles.length > 0 ? (
                      <Grid item lg={6} xs={12}>
                        <Button
                          variant='contained'
                          onClick={() => {
                            dispatch({ type: 'OPEN_MODAL_GRAFICA' });
                          }}
                        >
                          <ShowChart />
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                    {!disabledDoc ? (
                      <Grid item lg={6} xs={12}>
                        <Button
                          disabled={disabledDoc}
                          variant='contained'
                          onClick={() => {
                            setEvaluacion({ ...initialEvaluacion });
                            setEdit(false);
                            dispatch({ type: 'OPEN_INPUT_MODAL' });
                          }}
                        >
                          Nuevo
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Box>
                <Dialog
                  open={state.toggleModalGrafica}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_GRAFICA' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='xl'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>Gráfica</DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <ReactApexChart
                              fullWidth
                              options={options}
                              series={series}
                              type='line'
                              height='350'
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() =>
                            dispatch({ type: 'CLOSE_MODAL_GRAFICA' })
                          }
                        >
                          Cerrar
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
                {/* {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setEvaluacion({ ...initialEvaluacion });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )} */}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='etiqueta'
                                name='etiqueta'
                                label='Etiqueta mal colocada'
                                fullWidth
                                value={evaluacion.etiqueta}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    etiqueta: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'etiqueta',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='suciedad'
                                name='suciedad'
                                label='Suciedad'
                                fullWidth
                                value={evaluacion.suciedad}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    suciedad: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'suciedad',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='oxido'
                                name='oxido'
                                label='Óxido'
                                fullWidth
                                value={evaluacion.oxido}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    oxido: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'oxido',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='raspaduras'
                                name='raspaduras'
                                label='Raspaduras'
                                fullWidth
                                value={evaluacion.raspaduras}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    raspaduras: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'raspaduras',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='abolladuras'
                                name='abolladuras'
                                label='Abolladuras'
                                fullWidth
                                value={evaluacion.abolladuras}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    abolladuras: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'abolladuras',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='anillo'
                                name='anillo'
                                label='Defectos en el anillo'
                                fullWidth
                                value={evaluacion.anillo}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    anillo: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'anillo',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='cierreherm'
                                name='cierreherm'
                                label='Defecto en cierre hermético'
                                fullWidth
                                value={evaluacion.cierreherm}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    cierreherm: e.target.value,
                                    totaldef: getTotalDefectos(
                                      'cierreherm',
                                      e.target.value,
                                    ),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled
                                id='totaldef'
                                name='totaldef'
                                label='Total de defectos'
                                fullWidth
                                value={evaluacion.totaldef || 0}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={evaluacion.observaciones || ''}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Totales
                </Typography>
              </Box>
              {/* <Grid container spacing={2}> */}
              <MUIDataTable
                data={totales}
                columns={columnsTotales}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
              {/* </Grid> */}
            </Widget>
          </Grid>
          {/* <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Etiqueta Utilizada
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    // onClick={() => {
                    //   setEdit(false);
                    //   handleSubmitArchivos();
                    // }}
                  >
                    Guardar Archivos
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                  <Box sx={{ margin: '0 auto' }}>
                    <div
                      className={`dropzone ${drag ? 'drag' : ''}`}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragEnd}
                      onDrop={handleDrop}
                    >
                      <input
                        type='file'
                        onChange={(e) => handleUpload(e)}
                        hidden
                        ref={inputRef}
                      />
                      <Fab
                        color='primary'
                        variant='extended'
                        aria-label='upload'
                        size='small'
                        onClick={() => inputRef.current.click()}
                      >
                        <FileUpload /> Cargar Archivo
                      </Fab>
                      <Typography
                        sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                      >
                        o arrastra un archivo
                      </Typography>
                    </div>
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <MUIDataTable
                      title='Archivos'
                      data={files}
                      columns={columnsFiles}
                      options={{
                        responsive: 'standard',
                        selectableRows: 'none',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={8} xs={12}>
                  {etiquetaFile ? (
                    <Fab
                      color='primary'
                      variant='extended'
                      sx={{ mb: 1 }}
                      onClick={handleRemoveImg}
                    >
                      Quitar imagen
                    </Fab>
                  ) : (
                    ''
                  )}
                  <img fullWidth id='etiqueta' width='100%' />
                </Grid>
              </Grid>
            </Widget>
          </Grid> */}
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EvaluacionExternaPT;
