import React, { useEffect, useState } from 'react';
import { CircularProgress, Fab } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, generaPDF, isDateValid } from 'functions';
import AlertError from 'components/AlertError/AlertError';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import axios from 'axios';
import ViewInfoEnlatado from 'components/ViewInfoEnlatado/ViewInfoEnlatado';
import ModalPDF from 'components/Modal/ModalPDF';
import TrazabilidadPDF from './TrazabilidadPDF';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function TrazabilidadProcTab() {
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'trazabilidadmatpriid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'trazabilidadmatpri' },
    { label: 'Fecha de Registro', name: 'fecha' },
    { label: 'Fecha de Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/enlatado/trazabilidad-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <BlobProvider
              document={<TrazabilidadPDF id={tableMeta.rowData[0]} />}
            >
              {({ load, url }) => {
                setLoading(load);
                return load ? (
                  <CircularProgress />
                ) : (
                  <a href={url} target='_blank' disabled={load || !url}>
                    <Fab color='primary' size='small'>
                      <PictureAsPdf style={{ color: 'white' }} />
                    </Fab>
                  </a>
                );
              }}
            </BlobProvider>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isDateValid(registro.fecha) || !isDateValid(registro.fechaprod)) {
      setErrorModal('Fechas incorrectas');
      return;
    }

    setLoading(true);

    axios
      .post('/enlatado/trazabilidadmp', registro)
      .then((res) => {
        history.push(
          `/app/etapas/enlatado/trazabilidad-no-${res.data.data.Id}`,
        );
        setLoading(false);
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/enlatado/trazabilidadmp', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {error ? <AlertError message={error} /> : ''}
      <ViewInfoEnlatado
        title='Trazabilidad en el Proceso'
        columns={columns}
        data={documentos}
        formato='trazabilidad'
        handleSubmit={handleSubmit}
        registro={registro}
        setRegistro={setRegistro}
        loading={loading}
        errorModal={errorModal}
        setErrorModal={setErrorModal}
      />
      <ModalPDF
        state={state}
        dispatch={dispatch}
        loading={loading}
        pdf={<TrazabilidadPDF id={idSel} />}
      />
    </>
  );
}

export default TrazabilidadProcTab;
