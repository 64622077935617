import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { Box, CircularProgress, Fab, Grid, Typography } from '@mui/material';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ModalPDF from 'components/Modal/ModalPDF';
import { generaPDF } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LotesLiberadosPDF from './LotesLiberadosPDF';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function LotesLiberadosTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'verificacioncodid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'verificacioncod' },
    { label: 'Fecha Registro', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/codificado/lotes-liberados-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <BlobProvider
              document={<LotesLiberadosPDF id={tableMeta.rowData[0]} />}
            >
              {({ load, url }) => {
                setLoading(load);
                return load ? (
                  <CircularProgress />
                ) : (
                  <a href={url} target='_blank' disabled={load || !url}>
                    <Fab color='primary' size='small'>
                      <PictureAsPdf style={{ color: 'white' }} />
                    </Fab>
                  </a>
                );
              }}
            </BlobProvider>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/codificado/verificacod', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Consulta de Lotes Liberados
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {error ? <AlertError message={error} /> : ''}
              <MUIDataTable
                data={documentos}
                columns={columns}
                options={{ responsive: 'standard', selectableRows: 'none' }}
              />
            </Grid>
            <ModalPDF
              state={state}
              dispatch={dispatch}
              loading={loading}
              pdf={<LotesLiberadosPDF id={idSel} />}
            />
          </Grid>
        </>
      )}
    </>
  );
}

export default LotesLiberadosTab;
