import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MUIDataTable from 'mui-datatables';
import Widget from 'components/Widget/Widget';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatDatetime, generaPDF } from 'functions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import ModalPDF from 'components/Modal/ModalPDF';
import PRMPDF from './PRMPDF';
import { PictureAsPdf } from '@mui/icons-material';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EtapaPRM() {
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [header, setHeader] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    proveedorid: 0,
    productoid: 0,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // const [proveedores, setProveedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [rde, setRde] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'registrodiaelaid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Registro', name: 'registrodiaela' },
    { label: 'Fecha de Registro', name: 'fechareg' },
    { label: 'Fecha de Producción', name: 'fechaprod' },
    { label: 'Lote', name: 'loteprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/planeacion-materiales/${tableMeta.rowData[0]}`,
                )
              }
            >
              <VisibilityIcon />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <BlobProvider document={<PRMPDF id={tableMeta.rowData[0]} />}>
              {({ load, url }) => {
                setLoading(load);
                return load ? (
                  <CircularProgress />
                ) : (
                  <a href={url} target='_blank' disabled={load || !url}>
                    <Fab color='primary' size='small'>
                      <PictureAsPdf style={{ color: 'white' }} />
                    </Fab>
                  </a>
                );
              }}
            </BlobProvider>
          ) : (
            ''
          );
        },
      },
    },
  ];

  // const proveedoresProps = {
  //   options: proveedores.map((option) => ({
  //     id: option.Id,
  //     name: option.Name,
  //   })),
  //   getOptionLabel: (option) => option.name,
  // };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  // const getProveedores = () => {
  //   setLoading(true);
  //   try {
  //     axios
  //       .get('/catalogo/proveedores', {})
  //       .then((res) => {
  //         if (res !== null) {
  //           setProveedores(res.data.data);
  //           setError(null);
  //           setLoading(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setError(error);
  //         setLoading(false);
  //       });
  //   } catch (error) {
  //     setError(error);
  //   }
  // };

  const getProductos = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/productos', {})
        .then((res) => {
          if (res !== null) {
            setProductos(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getRde = () => {
    setLoading(true);
    axios
      .get('/prm/rde', {})
      .then((res) => {
        setRde(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/prm/rde', header)
      .then((res) => {
        console.log(res);
        const id = res.data.data.Id;
        history.push(`/app/etapas/planeacion-materiales/${id}`);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRde();
    // getProveedores();
    getProductos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap>
                    Registro de Pescado Programado para Producción
                  </Typography>
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setError(null);
                      dispatch({ type: 'OPEN_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                  <Dialog
                    open={state.toggleModal}
                    onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          Nuevo Requerimiento
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    required
                                    id='fecha'
                                    name='fecha'
                                    label='Fecha de Registro'
                                    format='DD/MM/YYYY HH:mm:ss'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    defaultValue={new Date(header.fecha)}
                                    value={dayjs(new Date(header.fecha))}
                                    onChange={(date) =>
                                      setHeader({
                                        ...header,
                                        fecha: formatDatetime(new Date(date)),
                                      })
                                    }
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  fullWidth
                                  id='producto'
                                  {...productosProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Producto'
                                    />
                                  )}
                                  onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                      setHeader({
                                        ...header,
                                        productoid: newValue.id,
                                      });
                                    } else {
                                      setHeader({
                                        ...header,
                                        productoid: null,
                                      });
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    required
                                    id='fechaprod'
                                    name='fechaprod'
                                    label='Fecha de Producción'
                                    format='DD/MM/YYYY HH:mm:ss'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    defaultValue={new Date(header.fechaprod)}
                                    value={dayjs(new Date(header.fechaprod))}
                                    onChange={(date) =>
                                      setHeader({
                                        ...header,
                                        fechaprod: formatDatetime(
                                          new Date(date),
                                        ),
                                      })
                                    }
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => dispatch({ type: 'CLOSE_MODAL' })}
                            >
                              Cancelar
                            </Button>
                            <Button
                              color='primary'
                              variant='contained'
                              type='submit'
                              onClick={handleSubmit}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <MUIDataTable
                  data={rde}
                  columns={columns}
                  options={{ responsive: 'standard', selectableRows: 'none' }}
                />
              </Grid>
            </Grid>
          </Widget>
          <ModalPDF
            state={state}
            dispatch={dispatch}
            loading={loading}
            pdf={<PRMPDF id={idSel} />}
          />
        </>
      )}
    </>
  );
}

export default EtapaPRM;
