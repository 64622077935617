import React from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from 'components/Widget/Widget';
import SetMealIcon from '@mui/icons-material/SetMeal';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import PescadoTab from './Pescado/PescadoTab';
import CartonTab from './Carton/CartonTab';
import EnvaseTapaTab from './EnvaseTapa/EnvaseTapaTab';

function EtapaRMP() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('rmp') ? parseInt(localStorage.getItem('rmp')) : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('rmp', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              aria-label='full width tabs example'
            >
              <Tab label='Pescado' icon={<SetMealIcon />} />
              <Tab label='Cartón' icon={<TakeoutDiningIcon />} />
              <Tab label='Tapa' icon={<BreakfastDiningIcon />} />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <PescadoTab />,
              1: <CartonTab />,
              2: <EnvaseTapaTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaRMP;
