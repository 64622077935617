import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { CircularProgress, Fab } from '@mui/material';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ModalPDF from 'components/Modal/ModalPDF';
import ViewInfoLab from 'components/ViewInfo/ViewInfoLab';
import { formatDatetime, generaPDF } from 'functions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import EvaluacionCuarentenaPDF from './EvaluacionCuarentenaPDF';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EvaluacionCuarentenaTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    presentacionenvid: null,
    fechainiinc: formatDatetime(new Date()),
  });
  const [documentos, setDocumentos] = useState([]);
  const [evaluaciones, setEvaluaciones] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'evaluacioncuarenptid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, viewColumns: false, filter: false },
    },
    { label: 'Folio', name: 'evaluacioncuarenpt' },
    { label: 'Fecha Registro', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/laboratorio/evaluacion-cuarentena-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        viewColumns: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <BlobProvider
              document={<EvaluacionCuarentenaPDF id={tableMeta.rowData[0]} />}
            >
              {({ load, url }) => {
                setLoading(load);
                return load ? (
                  <CircularProgress />
                ) : (
                  <a href={url} target='_blank' disabled={load || !url}>
                    <Fab color='primary' size='small'>
                      <PictureAsPdf style={{ color: 'white' }} />
                    </Fab>
                  </a>
                );
              }}
            </BlobProvider>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // setLoading(true);

    const body = {
      ...registro,
      presentacionenvid: registro.presentacionenvid.id,
    };

    axios
      .post('/laboratorio/evacuarentena', body)
      .then((res) => {
        history.push(
          `/app/etapas/laboratorio/evaluacion-cuarentena-no-${res.data.data.Id}`,
        );
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/laboratorio/evacuarentena', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getEvaluacionesPendientes = () => {
    axios
      .get('/laboratorio/evacuarentena/evaluaciondia', {})
      .then((res) => {
        setEvaluaciones(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumentos();
    getEvaluacionesPendientes();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {error ? <AlertError message={error} /> : ''}
          <ViewInfoLab
            title='Evaluación de Cuarentena de Producto Terminado'
            columns={columns}
            data={documentos}
            formato='evaluacionCuarentena'
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            loading={loading}
            errorModal={errorModal}
            setErrorModal={setErrorModal}
            error={error}
            cuarentena={true}
            evaluacionesPendientes={evaluaciones}
          />
          <ModalPDF
            state={state}
            dispatch={dispatch}
            loading={loading}
            pdf={<EvaluacionCuarentenaPDF id={idSel} />}
          />
        </>
      )}
    </>
  );
}

export default EvaluacionCuarentenaTab;
