import { Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { formatDatetime, formatDatetimeG, isEditable } from 'functions';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import axios from 'axios';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function AlmacenamientoCuarentena() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    almacenamientocua: '',
    fecha: '',
    ubicacion: '',
    estatus: '',
    tarimaid: '',
    tarima: '',
    horaini: '',
    horafin: '',
  });
  const initialAlmacenamiento = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    almacenamientocuaid: id,
    tarimacuaid: { id: header.tarimaid, name: header.tarima },
    cajanum: '',
    fechaprod: formatDatetime(new Date()),
    fechaing: formatDatetime(new Date()),
    loteprod: null,
    presentacionid: null,
    productoid: null,
    estatusid: 170,
    fechadep: null,
  };
  const [almacenamiento, setAlmacenamiento] = useState({
    ...initialAlmacenamiento,
  });
  const [detalles, setDetalles] = useState([]);
  const [tarimas, setTarimas] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [productos, setProductos] = useState([]);
  const [estatus, setEstatus] = useState([]);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Tarima', name: 'tarima' },
    { label: 'Caja Num.', name: 'cajanum' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Fecha Ingreso', name: 'fechaing' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Presentación', name: 'presentacion' },
    { label: 'Producto', name: 'producto' },
    // { label: 'Estatus Caja', name: 'estatuscaja' },
    // { label: 'Fecha Depurada', name: 'fechadep' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...almacenamiento,
      tarimacuaid: header.tarimaid,
      registrodiaela: almacenamiento.loteprod.id,
      loteprod: almacenamiento.loteprod.name,
      presentacionid: almacenamiento.presentacionid.id,
      productoid: almacenamiento.productoid.id,
      // estatusid: almacenamiento.estatusid.id,
    };

    axios
      .post('/laboratorio/almacenacua/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message || error.response?.data?.status,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setAlmacenamiento({
      ...almacenamiento,
      detailid: id,
      // tarimacuaid: { id: detalle.tarimaid, name: detalle.tarima } || null,
      fechaprod: detalle.fechaprod
        ? formatDatetime(new Date(detalle.fechaprod))
        : null || null,
      fechaing: detalle.fechaing
        ? formatDatetime(new Date(detalle.fechaing))
        : null || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      presentacionid:
        { id: detalle.presentacionid, name: detalle.presentacion } || null,
      productoid: { id: detalle.productoid, name: detalle.producto } || null,
      // estatusid: { id: detalle.estatusid, name: detalle.estatuscaja } || null,
      // fechadep: detalle.fechadep
      //   ? formatDatetime(new Date(detalle.fechadep))
      //   : null || null,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/almacenacua/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/laboratorio/almacenacua/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/laboratorio');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/laboratorio/almacenacua/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const tarimasProps = {
    options: tarimas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getTarimas = () => {
    setLoading(true);
    axios
      .get('/catalogo/tarimacua', {})
      .then((res) => {
        setTarimas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const presentacionesProps = {
    options: presentaciones.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getPresentaciones = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/denominaprod', {})
        .then((res) => {
          if (res !== null) {
            setPresentaciones(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = () => {
    setLoading(true);
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const estatusProps = {
    options: estatus.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getEstatus = () => {
    axios
      .get('/catalogo/estatus', {})
      .then((res) => {
        if (res !== null) {
          setEstatus(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getTarimas();
    getLoteProd(formatDatetime(new Date()));
    getPresentaciones();
    getProductos();
    getEstatus();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.almacenamientocua}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Tarima'
                    fullWidth
                    disabled
                    value={header.tarima}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Almacenamiento Cuarentena
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {!disabledDoc ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={disabledDoc}
                        variant='contained'
                        onClick={() => {
                          setAlmacenamiento({ ...initialAlmacenamiento });
                          setEdit(false);
                          dispatch({ type: 'OPEN_INPUT_MODAL' });
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled
                                fullWidth
                                id='tarimacuaid'
                                {...tarimasProps}
                                renderInput={(params) => (
                                  <TextField {...params} label='Tarima' />
                                )}
                                value={
                                  {
                                    id: header.tarimaid,
                                    name: header.tarima,
                                  } || null
                                }
                                // onChange={(event, newValue) => {
                                //   setAlmacenamiento({
                                //     ...almacenamiento,
                                //     tarimacuaid: newValue,
                                //   });
                                // }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                fullWidth
                                id='cajanum'
                                name='cajanum'
                                label='Número Caja'
                                value={almacenamiento.cajanum || ''}
                                onChange={(e) => {
                                  setAlmacenamiento({
                                    ...almacenamiento,
                                    cajanum: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fechaing'
                                  name='fechaing'
                                  label='Fecha de Ingreso'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: { fullWidth: true },
                                    required: true,
                                  }}
                                  value={
                                    almacenamiento.fechaing
                                      ? dayjs(new Date(almacenamiento.fechaing))
                                      : null
                                  }
                                  onChange={(date) =>
                                    setAlmacenamiento({
                                      ...almacenamiento,
                                      fechaing: formatDatetime(new Date(date)),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fechaprod'
                                  name='fechaprod'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: { fullWidth: true },
                                    required: true,
                                  }}
                                  value={
                                    almacenamiento.fechaprod
                                      ? dayjs(
                                          new Date(almacenamiento.fechaprod),
                                        )
                                      : null
                                  }
                                  onChange={(date) =>
                                    setAlmacenamiento({
                                      ...almacenamiento,
                                      fechaprod: formatDatetime(new Date(date)),
                                    })
                                  }
                                  onAccept={(date) => {
                                    getLoteProd(
                                      formatDatetimeG(new Date(date)),
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Lote Producción'
                                  />
                                )}
                                value={almacenamiento.loteprod || null}
                                onChange={(event, newValue) => {
                                  setAlmacenamiento({
                                    ...almacenamiento,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='presentacionid'
                                {...presentacionesProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Presentación'
                                  />
                                )}
                                value={almacenamiento.presentacionid || null}
                                onChange={(event, newValue) => {
                                  setAlmacenamiento({
                                    ...almacenamiento,
                                    presentacionid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Producto'
                                  />
                                )}
                                value={almacenamiento.productoid || null}
                                onChange={(event, newValue) => {
                                  setAlmacenamiento({
                                    ...almacenamiento,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {/* <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='estatusid'
                                {...estatusProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Estatus'
                                  />
                                )}
                                value={almacenamiento.estatusid || null}
                                onChange={(event, newValue) => {
                                  setAlmacenamiento({
                                    ...almacenamiento,
                                    estatusid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fechadep'
                                  name='fechadep'
                                  label='Fecha de Depurada'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: { fullWidth: true },
                                    required: true,
                                  }}
                                  value={
                                    almacenamiento.fechadep
                                      ? dayjs(new Date(almacenamiento.fechadep))
                                      : null
                                  }
                                  onChange={(date) =>
                                    setAlmacenamiento({
                                      ...almacenamiento,
                                      fechadep: formatDatetime(new Date(date)),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid> */}
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default AlmacenamientoCuarentena;
