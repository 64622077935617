import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  card: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },
  progressSectionTitle: {
    marginBottom: theme.spacing(1),
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  pieChartLegendWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: theme.spacing(1),
  },
  legendItemContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  fullHeightBody: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  alignStandaloneElement: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  tableWidget: {
    overflowX: 'auto',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main,
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  performanceLegendWrapper: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  legendElement: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  legendElementText: {
    marginLeft: theme.spacing(1),
  },
  serverOverviewElement: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '100%',
  },
  serverOverviewElementText: {
    minWidth: 149,
    paddingRight: theme.spacing(2),
  },
  serverOverviewElementChartWrapper: {
    width: '100%',
  },
  mainChartBody: {
    overflowX: 'auto',
  },
  mainChartHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.only('xs')]: {
      flexWrap: 'wrap',
    },
  },
  mainChartHeaderLabels: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      order: 3,
      width: '100%',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  mainChartHeaderLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
  },
  mainChartSelectRoot: {
    borderColor: theme.palette.text.hint + '80 !important',
  },
  mainChartSelect: {
    padding: 10,
    paddingRight: 25,
  },
  mainChartLegendElement: {
    fontSize: '14px !important',
    marginLeft: theme.spacing(1),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  fixIconRight: {
    '& .MuiSelect-icon': {
      right: 4,
    },
  },
  actionsIcon: {
    color: '#76767B',
  },
  logotypeContainer: {
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '10rem',
    paddingBottom: '10rem',
  },
  logotypeImage: {
    width: 320,
    marginBottom: theme.spacing(4),
  },
  logotypeText: {
    color: 'white',
    fontWeight: 500,
    fontSize: 84,
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: 48,
    },
  },
}));

// import { makeStyles } from '@mui/styles';

// export default makeStyles((theme) => ({
//   container: {
//     height: '100vh',
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     position: 'absolute',
//     top: 0,
//     left: 0,
//   },
//   logotypeContainer: {
//     backgroundColor: theme.palette.primary.main,
//     // width: '60%',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     padding: '1rem',
//     // [theme.breakpoints.down('md')]: {
//     //   width: '50%',
//     // },
//     // [theme.breakpoints.down('md')]: {
//     //   display: 'none',
//     // },
//   },
//   logotypeImage: {
//     width: 320,
//     marginBottom: theme.spacing(4),
//   },
//   logotypeText: {
//     color: 'white',
//     fontWeight: 500,
//     fontSize: 84,
//     textAlign: 'center',
//     [theme.breakpoints.down('md')]: {
//       fontSize: 48,
//     },
//   },
//   customFormContainer: {
//     width: '40%',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     overflow: 'auto',
//     alignItems: 'center',
//     [theme.breakpoints.down('md')]: {
//       width: '50%',
//       overflow: 'visible',
//     },
//   },
//   formContainer: {
//     width: '40%',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     overflow: 'auto',
//     alignItems: 'center',
//     [theme.breakpoints.down('md')]: {
//       width: '50%',
//       overflow: 'visible',
//     },
//   },
//   form: {
//     width: 320,
//   },
//   tab: {
//     fontWeight: 400,
//     fontSize: 18,
//   },
//   greeting: {
//     fontWeight: 500,
//     textAlign: 'center',
//     marginTop: theme.spacing(4),
//   },
//   subGreeting: {
//     fontWeight: 500,
//     textAlign: 'center',
//     marginTop: theme.spacing(2),
//   },
//   googleButton: {
//     marginTop: theme.spacing(6),
//     boxShadow: theme.customShadows.widget,
//     backgroundColor: 'white',
//     width: '100%',
//     textTransform: 'none',
//   },
//   googleButtonCreating: {
//     marginTop: 0,
//   },
//   googleIcon: {
//     width: 30,
//     marginRight: theme.spacing(2),
//   },
//   creatingButtonContainer: {
//     marginTop: theme.spacing(2.5),
//     height: 46,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   createAccountButton: {
//     height: 46,
//     textTransform: 'none',
//   },
//   formDividerContainer: {
//     marginTop: theme.spacing(4),
//     marginBottom: theme.spacing(4),
//     display: 'flex',
//     alignItems: 'center',
//   },
//   formDividerWord: {
//     paddingLeft: theme.spacing(2),
//     paddingRight: theme.spacing(2),
//   },
//   formDivider: {
//     flexGrow: 1,
//     height: 1,
//     backgroundColor: theme.palette.text.hint + '40',
//   },
//   errorMessage: {
//     textAlign: 'center',
//     color: '#ff0000ba',
//   },
//   textFieldUnderline: {
//     '&:before': {
//       borderBottomColor: theme.palette.primary.light,
//     },
//     '&:after': {
//       borderBottomColor: theme.palette.primary.main,
//     },
//     '&:hover:before': {
//       borderBottomColor: `${theme.palette.primary.light} !important`,
//     },
//   },
//   textField: {
//     borderBottomColor: theme.palette.background.light,
//   },
//   formButtons: {
//     width: '100%',
//     marginTop: theme.spacing(4),
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   forgetButton: {
//     textTransform: 'none',
//     fontWeight: 400,
//   },
//   loginLoader: {
//     marginLeft: theme.spacing(4),
//   },
//   copyright: {
//     marginTop: theme.spacing(4),
//     whiteSpace: 'nowrap',
//     [theme.breakpoints.up('md')]: {
//       bottom: theme.spacing(2),
//     },
//   },
// }));
