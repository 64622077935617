import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import TMComponent from 'components/TiempoMuerto/TMComponent';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AccessTime, CheckCircle, Edit } from '@mui/icons-material';
import axios from 'axios';
import { formatDatetime, formatTime, isEditable } from 'functions';
import AlertError from 'components/AlertError/AlertError';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function TiempoMuerto() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [lineas, setLineas] = useState([]);
  const [maquinas, setMaquinas] = useState([]);
  const [causas, setCausas] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalPreop: false,
    toggleModalEdit: false,
  });
  const [header, setHeader] = useState({
    tiempomuer: '',
    ubicacion: '',
    estatus: '',
    fecha: '',
    fechaprod: '',
    horaarract: '',
  });

  const initialTM = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    tiempomuerid: id,
    lineaid: null,
    maquina: null,
    causaid: null,
    notas: '',
    horaini: null,
    horafin: null,
    observaciones: '',
  };

  const [tiempoMuerto, setTiempoMuerto] = useState({ ...initialTM });
  const [detalles, setDetalles] = useState([]);
  const [horaParo, setHoraParo] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'tiempomuerid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Línea', name: 'linea' },
    { label: 'Máquina', name: 'maquina' },
    { label: 'Causa', name: 'causa' },
    { label: 'Notas', name: 'notas' },
    {
      label: 'Hora Inicio',
      name: 'horaini',
      options: {
        customBodyRender: (value) => {
          return value ? formatTime(value) : null;
        },
      },
    },
    {
      label: 'Hora Fin',
      name: 'horafin',
      options: {
        customBodyRender: (value) => {
          return value ? formatTime(value) : null;
        },
      },
    },
    { label: 'Tiempo Total (minutos)', name: 'tiempotot' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...tiempoMuerto,
      maquina: tiempoMuerto.maquina.id,
      causaid: tiempoMuerto.causaid.id,
      horaini: tiempoMuerto.horaini
        ? tiempoMuerto.horaini.$d
          ? formatDatetime(new Date(tiempoMuerto.horaini.$d))
          : formatDatetime(new Date(tiempoMuerto.horaini))
        : null,
      horafin: tiempoMuerto.horafin
        ? tiempoMuerto.horafin.$d
          ? formatDatetime(new Date(tiempoMuerto.horafin.$d))
          : formatDatetime(new Date(tiempoMuerto.horafin))
        : null,
      lineaid: tiempoMuerto.lineaid.id,
    };

    axios
      .post('/ocb/tiempom/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.tiempomuerid === id);
    setTiempoMuerto({
      ...tiempoMuerto,
      detailid: detalle.tiempomuerid,
      lineaid: { id: detalle.lineaid, name: detalle.linea },
      maquina: { id: detalle.maquinaid, name: detalle.maquina },
      causaid: { id: detalle.causaid, name: detalle.causa },
      notas: detalle.notas,
      horaini: detalle.horaini ? new Date(detalle.horaini) : null,
      horafin: detalle.horafin ? new Date(detalle.horafin) : null,
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (selectedRows) => {
    setLoading(true);
    let err = false;
    let message = '';
    for (let i = 0; i < selectedRows.data.length; i++) {
      const tiempomuerid =
        detalles[selectedRows.data[i].dataIndex].tiempomuerid;
      await axios
        .post(`/ocb/delete/tiempom/detail/${tiempomuerid}`)
        .catch((error) => {
          err = true;
          message = error.response?.data?.message;
        });
    }
    if (!err) {
      Swal.fire({
        title: 'Éxito',
        text: '¡Se han eliminado los registros exitosamente!',
        icon: 'success',
        confirmButtonColor: '#196b86',
      });
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: message,
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
    getDocumento();
    setSelectedRows([]);
  };

  const handleEnd = (e) => {
    e.preventDefault();

    // Swal.fire({
    //   title: '¿Está seguro de terminar el proceso?',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Sí',
    //   confirmButtonColor: '#196b86',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      horaparo:  horaParo.$d
      ? formatDatetime(new Date(horaParo.$d))
      : formatDatetime(new Date(horaParo))
       
    };
    axios
      .post('/ocb/tiempom/document/finalizar', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push('/app/etapas/ocb');
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      });
    //   }
    // });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/ocb/tiempom/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalles(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const lineaProps = {
    options: lineas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineas = () => {
    axios
      .get('/catalogo/lineaprod', {})
      .then((res) => {
        setLineas(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const maquinasProps = {
    options: maquinas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const causaProps = {
    options: causas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getMaquinas = () => {
    axios
      .get('/catalogo/maquina', {})
      .then((res) => {
        setMaquinas(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getCausas = () => {
    axios
      .get('/catalogo/causa', {})
      .then((res) => setCausas(res.data.data))
      .catch((error) => setError(error));
  };

  useEffect(() => {
    getLineas();
    getMaquinas();
    getCausas();
    getDocumento();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.tiempomuer || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Fecha'
                    fullWidth
                    disabled
                    value={header.fecha || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Fecha Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={12}>
                  <TextField
                    label='Hora Arranque'
                    fullWidth
                    disabled
                    value={
                      header.horaarract
                        ? formatTime(header.horaarract)
                        : null || ''
                    }
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <TMComponent
                disabledDoc={disabledDoc}
                edit={edit}
                title='Tiempo Muerto'
                formato='ocb'
                initialState={initialTM}
                tiempoMuerto={tiempoMuerto}
                setTiempoMuerto={setTiempoMuerto}
                data={detalles}
                columns={columns}
                handleDelete={handleDelete}
                state={state}
                dispatch={dispatch}
                loading={loading}
                error={error}
                handleSubmit={handleSubmit}
                lineaProps={lineaProps}
                maquinasProps={maquinasProps}
                causaProps={causaProps}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircle />}
                  onClick={() => dispatch({ type: 'OPEN_MODAL_END' })}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
          <Dialog
            open={state.toggleModalEnd}
            onClose={() => {
              setHoraParo(null);
              dispatch({ type: 'CLOSE_MODAL_END' });
            }}
            aria-labelledby='form-dialog-title'
          >
            {loading ? (
              <Box sx={{ m: 20 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DialogTitle id='form-dialog-title'>Terminar</DialogTitle>
                <form onSubmit={handleEnd}>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {error ? (
                        <Grid item xs={12}>
                          <AlertError message={error} />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title='Hora actual'>
                            <Button
                              variant='contained'
                              onClick={() => {
                                setHoraParo(new Date());
                              }}
                            >
                              <AccessTime />
                            </Button>
                          </Tooltip>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              required
                              id='horafin'
                              name='horafin'
                              label='Hora Fin'
                              slotProps={{
                                textField: { fullWidth: true, required: true },
                              }}
                              value={horaParo ? dayjs(horaParo) : null}
                              onChange={(time) => {
                                setHoraParo(time);
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        setHoraParo(null);
                        dispatch({ type: 'CLOSE_MODAL_END' });
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button color='primary' variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Dialog>
        </Grid>
      )}
    </>
  );
}

export default TiempoMuerto;
