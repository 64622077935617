import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { CircularProgress, Fab } from '@mui/material';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import ModalPDF from 'components/Modal/ModalPDF';
import ViewInfoCodificado from 'components/ViewInfo/ViewInfoCodificado';
import { formatDatetime, generaPDF } from 'functions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import CarrosEtiquetadoPDF from './CarrosEtiquetadoPDF';
import { BlobProvider } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function CarrosEtiquetadoTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    lotesprod: [],
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'llenadocarretiqid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'llenadocarretiq' },
    { label: 'Fecha Registro', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/codificado/carros-etiquetado-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <BlobProvider
              document={<CarrosEtiquetadoPDF id={tableMeta.rowData[0]} />}
            >
              {({ load, url }) => {
                setLoading(load);
                return load ? (
                  <CircularProgress />
                ) : (
                  <a href={url} target='_blank' disabled={load || !url}>
                    <Fab color='primary' size='small'>
                      <PictureAsPdf style={{ color: 'white' }} />
                    </Fab>
                  </a>
                );
              }}
            </BlobProvider>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const getLotesProd = (array) => {
    let lotes = '';
    for (let i = 0; i < array.length; i++) {
      lotes += array[i].loteprod + ',';
    }
    return lotes;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...registro,
      lotesprod: getLotesProd(registro.lotesprod).substring(
        0,
        getLotesProd(registro.lotesprod).length - 1,
      ),
    };

    axios
      .post('/codificado/carrosetiq', body)
      .then((res) => {
        if (res.data.data.Id) {
          history.push(
            `/app/etapas/codificado/carros-etiquetado-no-${res.data.data.Id}`,
          );
        } else {
          setErrorModal(res.data.data.resultado);
        }
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
    setLoading(false);
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/codificado/carrosetiq', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {error ? <AlertError message={error} /> : ''}
          <ViewInfoCodificado
            title='Llenado de Carros de Etiquetado'
            columns={columns}
            data={documentos}
            formato='carrosEtiquetado'
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            loading={loading}
            errorModal={errorModal}
            setErrorModal={setErrorModal}
          />
          <ModalPDF
            state={state}
            dispatch={dispatch}
            loading={loading}
            pdf={<CarrosEtiquetadoPDF id={idSel} />}
          />
        </>
      )}
    </>
  );
}

export default CarrosEtiquetadoTab;
