import React from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from 'components/Widget/Widget';
import SetMealIcon from '@mui/icons-material/SetMeal';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import LimpiezaOAETab from './LimpiezaOAE/LimpiezaOAETab';
import EvaluacionETTab from './EvaluacionET/EvaluacionETTab';
import MaterialEnvaseTab from './MaterialEnvase/MaterialEnvaseTab';
import TrazabilidadProcTab from './TrazabilidadProc/TrazabilidadProcTab';
import EvaluacionDobleCierreTab from './EvaluacionDobleCierre/EvaluacionDobleCierreTab';
import PesoPastillaTab from './PesoPastilla/PesoPastillaTab';
import LiquidosCoberturaTab from './LiquidosCobertura/LiquidosCoberturaTab';
import PesoNetoTab from './PesoNeto/PesoNetoTab';
import VerifCodigosTab from './VerifCodigos/VerifCodigosTab';
import TiemposMuertosTab from './TiemposMuertos/TiemposMuertosTab';
import AjusteEngargoladorasTab from './AjusteEngargoladoras/AjusteEngargoladorasTab';
import EntradaProduccionTab from './EntradaProduccion/EntradaProduccionTab';
import { AccessTime, Build, Checklist, CleaningServices, ContentPasteGo, MonitorWeight, PrecisionManufacturing, Scale, Timeline, Verified, WaterDrop } from '@mui/icons-material';

function EtapaEnlatado() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('enlatado')
      ? parseInt(localStorage.getItem('enlatado'))
      : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('enlatado', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='full width tabs example'
            >
              <Tab
                label='Entrada MP a Producción'
                icon={<ContentPasteGo />}
              />
              <Tab
                label='Limpieza Diaria y Operaciones Enlatado'
                icon={<CleaningServices />}
              />
              <Tab
                label='Evaluación Envase y Tapa'
                icon={<Checklist />}
              />
              <Tab label='Material de Envase' icon={<BreakfastDiningIcon />} />
              <Tab
                label='Trazabilidad en el Proceso'
                icon={<Timeline />}
              />
              <Tab
                label='Evaluación del Doble Cierre'
                icon={<PrecisionManufacturing />}
              />
              <Tab label='Peso Pastilla' icon={<Scale />} />
              <Tab
                label='Líquidos de Cobertura'
                icon={<WaterDrop />}
              />
              <Tab label='Peso Neto' icon={<MonitorWeight />} />
              <Tab
                label='Verificación de Códigos'
                icon={<Verified />}
              />
              <Tab label='Tiempos Muertos' icon={<AccessTime />} />
              <Tab
                label='Ajuste a Engargoladoras'
                icon={<Build />}
              />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <EntradaProduccionTab />,
              1: <LimpiezaOAETab />,
              2: <EvaluacionETTab />,
              3: <MaterialEnvaseTab />,
              4: <TrazabilidadProcTab />,
              5: <EvaluacionDobleCierreTab />,
              6: <PesoPastillaTab />,
              7: <LiquidosCoberturaTab />,
              8: <PesoNetoTab />,
              9: <VerifCodigosTab />,
              10: <TiemposMuertosTab />,
              11: <AjusteEngargoladorasTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaEnlatado;
