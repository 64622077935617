import React from 'react';
import useStyles from 'pages/notifications/styles';
import Notification from 'components/Notification/Notification';

const AlertError = ({ message }) => {
  let classes = useStyles();

  return (
    <>
      <Notification
        className={classes.notificationItem}
        type='report'
        message={message}
        variant='rounded'
        color='warning'
      />
    </>
  );
};

export default AlertError;
