import React from 'react';

const FollowIcon = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.317243" fillRule="evenodd" clipRule="evenodd" d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#FFC35F"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.551 13.8573C10.7476 13.8573 9.28571 12.3953 9.28571 10.592C9.28571 8.78859 10.7476 7.32666 12.551 7.32666C14.3544 7.32666 15.8163 8.78859 15.8163 10.592C15.8163 12.3953 14.3544 13.8573 12.551 13.8573ZM18.102 19.7348V18.4287C18.102 16.6253 16.6401 15.1634 14.8367 15.1634H10.2653C8.46193 15.1634 7 16.6253 7 18.4287V19.7348C7 20.0955 7.29239 20.3879 7.65306 20.3879C8.01374 20.3879 8.30612 20.0955 8.30612 19.7348V18.4287C8.30612 17.3467 9.18328 16.4695 10.2653 16.4695H14.8367C15.9188 16.4695 16.7959 17.3467 16.7959 18.4287V19.7348C16.7959 20.0955 17.0883 20.3879 17.449 20.3879C17.8097 20.3879 18.102 20.0955 18.102 19.7348ZM19.4082 15.1634V13.8573H18.102C17.7414 13.8573 17.449 13.5649 17.449 13.2042C17.449 12.8435 17.7414 12.5511 18.102 12.5511H19.4082V11.245C19.4082 10.8844 19.7005 10.592 20.0612 10.592C20.4219 10.592 20.7143 10.8844 20.7143 11.245V12.5511H22.0204C22.3811 12.5511 22.6735 12.8435 22.6735 13.2042C22.6735 13.5649 22.3811 13.8573 22.0204 13.8573H20.7143V15.1634C20.7143 15.5241 20.4219 15.8165 20.0612 15.8165C19.7005 15.8165 19.4082 15.5241 19.4082 15.1634ZM14.5102 10.592C14.5102 11.674 13.633 12.5511 12.551 12.5511C11.469 12.5511 10.5918 11.674 10.5918 10.592C10.5918 9.50994 11.469 8.63278 12.551 8.63278C13.633 8.63278 14.5102 9.50994 14.5102 10.592Z" fill="#FF9D13"/>
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="7" width="16" height="14">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.551 13.8573C10.7476 13.8573 9.28571 12.3953 9.28571 10.592C9.28571 8.78859 10.7476 7.32666 12.551 7.32666C14.3544 7.32666 15.8163 8.78859 15.8163 10.592C15.8163 12.3953 14.3544 13.8573 12.551 13.8573ZM18.102 19.7348V18.4287C18.102 16.6253 16.6401 15.1634 14.8367 15.1634H10.2653C8.46193 15.1634 7 16.6253 7 18.4287V19.7348C7 20.0955 7.29239 20.3879 7.65306 20.3879C8.01374 20.3879 8.30612 20.0955 8.30612 19.7348V18.4287C8.30612 17.3467 9.18328 16.4695 10.2653 16.4695H14.8367C15.9188 16.4695 16.7959 17.3467 16.7959 18.4287V19.7348C16.7959 20.0955 17.0883 20.3879 17.449 20.3879C17.8097 20.3879 18.102 20.0955 18.102 19.7348ZM19.4082 15.1634V13.8573H18.102C17.7414 13.8573 17.449 13.5649 17.449 13.2042C17.449 12.8435 17.7414 12.5511 18.102 12.5511H19.4082V11.245C19.4082 10.8844 19.7005 10.592 20.0612 10.592C20.4219 10.592 20.7143 10.8844 20.7143 11.245V12.5511H22.0204C22.3811 12.5511 22.6735 12.8435 22.6735 13.2042C22.6735 13.5649 22.3811 13.8573 22.0204 13.8573H20.7143V15.1634C20.7143 15.5241 20.4219 15.8165 20.0612 15.8165C19.7005 15.8165 19.4082 15.5241 19.4082 15.1634ZM14.5102 10.592C14.5102 11.674 13.633 12.5511 12.551 12.5511C11.469 12.5511 10.5918 11.674 10.5918 10.592C10.5918 9.50994 11.469 8.63278 12.551 8.63278C13.633 8.63278 14.5102 9.50994 14.5102 10.592Z" fill="white"/>
        </mask>
        <g mask="url(#mask0)">
        </g>
        </svg>
    )
}

export default FollowIcon;