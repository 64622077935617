import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import { formatDatetime, formatDatetimeG } from 'functions';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const ModalEmbarque = ({
  open,
  title = 'Nuevo Registro',
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transportistas, setTransportistas] = useState([]);
  const [clientes, setClientes] = useState([]);

  const transportistasProps = {
    options: transportistas.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const clientesProps = {
    options: clientes.map((option) => ({
      id: option.Id,
      name: option.Marca,
      cliente: option.Name,
    })),
    getOptionLabel: (option) => option.name ,
  };

  const getTransportistas = () => {
    setLoading(true);
    axios
      .get('/catalogo/transportistas', {})
      .then((res) => {
        setTransportistas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getClientes = () => {
    setLoading(true);
    axios
      .get('/catalogo/clientes', {})
      .then((res) => {
        setClientes(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTransportistas();
    getClientes();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      embarque: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='cliente'
                              {...clientesProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Cliente'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  cliente: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='transportista'
                              {...transportistasProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Transportista'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  transportista: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid> */}
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalEmbarque;
