import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';

const Modal = ({
  title = 'Nuevo Registro',
  open,
  dispatch,
  form,
  handleSubmit,
  disabledDoc = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
      aria-labelledby='form-dialog-title'
    >
      <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {form}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant='contained'
            onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
          >
            Cancelar
          </Button>
          <Button
            disabled={disabledDoc}
            color='primary'
            variant='contained'
            type='submit'
            // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
          >
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Modal;
