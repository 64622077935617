import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Modal from 'components/Modal/Modal';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime } from 'functions';
import { Cancel, Delete, Edit, Label } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import AlertError from 'components/AlertError/AlertError';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EntradaProduccionTab() {
  const { id } = useParams();
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [evaluacion, setEvaluacion] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const [detalleCarton, setDetalleCarton] = useState([]);
  const [detalleEnvase, setDetalleEnvase] = useState([]);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    recepcionid: 0,
    evaluaciontar: null,
    estatustar: null,
    fechaentpr: null,
  });

  const columnsCarton = [
    { label: 'Id', name: 'ID', options: { display: false } },
    { label: 'Recepción', name: 'recepcionid', options: { display: false } },
    { label: 'Fecha', name: 'Fecha' },
    { label: 'Folio', name: 'Folio' },
    { label: 'Proveedor', name: 'Proveedor' },
    { label: 'No. Tarima', name: 'TarimaNum' },
    { label: 'No. Lote', name: 'LoteNum' },
    { label: 'Cantidad', name: 'cantidad' },
    { label: 'Unidad de Medida', name: 'UnidadMed' },
    { label: 'Porcentaje Evaluación', name: 'percenteval' },
    { label: 'Evaluación Tarima', name: 'EvaluacionTar' },
    { label: 'Observaciones', name: 'Observaciones' },
    { label: 'Causa Aceptación', name: 'CausaAcep' },
    // { label: 'Fecha de Entrada a Producción', name: 'FechaEntAPr' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => handleEditCarton(tableMeta.rowData[0])}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsEnvase = [
    {
      label: 'Id',
      name: 'ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Recepción',
      name: 'recepcionid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha', name: 'Fecha' },
    { label: 'Folio', name: 'Folio' },
    { label: 'Proveedor', name: 'Proveedor' },
    { label: 'No. Tarima', name: 'TarimaNum' },
    { label: 'No. Lote', name: 'LoteNum' },
    { label: 'Cantidad', name: 'cantidad' },
    { label: 'Unidad de Medida', name: 'UnidadMed' },
    { label: 'Color', name: 'color' },
    { label: 'Porcentaje Evaluación', name: 'percenteval' },
    { label: 'Evaluación Tarima', name: 'EvaluacionTar' },
    { label: 'Observaciones', name: 'Observaciones' },
    { label: 'Causa Aceptación', name: 'CausaAcep' },
    // { label: 'Fecha de Entrada a Producción', name: 'FechaEntAPr' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => handleEditEnvase(tableMeta.rowData[0])}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const evaluacionProps = {
    options: evaluacion.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const estatusProps = {
    options: estatus.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getEvaluacion = () => {
    axios
      .get('/catalogo/evaluacion', {})
      .then((res) => {
        if (res !== null) {
          setEvaluacion(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getEstatus = () => {
    axios
      .get('/catalogo/estatus', {})
      .then((res) => {
        if (res !== null) {
          setEstatus(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getRegistrosCarton = () => {
    axios
      .get('enlatado/entradapr/2', {})
      .then((res) => {
        setDetalleCarton(res.data.data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getRegistrosEnvase = () => {
    axios
      .get('enlatado/entradapr/3', {})
      .then((res) => {
        setDetalleEnvase(res.data.data);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...registro,
      evaluaciontarid: registro.evaluaciontar.id,
      estatustarid: registro.estatustar.id,
      fechaentpr: registro.fechaentpr
        ? formatDatetime(new Date(registro.fechaentpr))
        : null || null,
    };
    console.log(body);
    axios
      .post('/enlatado/entradapr/detail', body)
      .then((res) => {
        console.log(res);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistrosCarton();
        getRegistrosEnvase();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleEditEnvase = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleEnvase.find((x) => x.ID === id);
    setRegistro({
      ...registro,
      detailid: id,
      recepcionid: detalle.recepcionid,
      evaluaciontar:
        {
          id: detalle.EvaluacionTarID,
          name: detalle.EvaluacionTar,
        } || null,
      estatustar:
        { id: detalle.estatustarid, name: detalle.estatustar } || null,
      fechaentpr: detalle.fechaentpr
        ? formatDatetime(new Date(detalle.fechaentpr))
        : formatDatetime(new Date()) || formatDatetime(new Date()),
    });
  };

  const handleEditCarton = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleCarton.find((x) => x.ID === id);
    setRegistro({
      ...registro,
      detailid: id,
      recepcionid: detalle.recepcionid,
      evaluaciontar:
        {
          id: detalle.EvaluacionTarID,
          name: detalle.EvaluacionTar,
        } || null,
      estatustar:
        { id: detalle.estatustarid, name: detalle.estatustar } || null,
        fechaentpr: detalle.fechaentpr
        ? formatDatetime(new Date(detalle.fechaentpr))
        : formatDatetime(new Date()) || formatDatetime(new Date()),
    });
  };

  const limpiarRegistro = () => {
    setRegistro({
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      recepcionid: 0,
      evaluaciontar: null,
      estatustar: null,
      fechaentpr: null,
    });
  };

  useEffect(() => {
    getRegistrosCarton();
    getRegistrosEnvase();
    getEstatus();
    getEvaluacion();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                '& a': {
                  textDecoration: 'none',
                  color: '#fff',
                },
              }}
            >
              <Typography variant='h3' color='text' noWrap>
                Entrada MP a Producción
              </Typography>
              <Dialog
                open={state.toggleInputModal}
                onClose={() => {
                  dispatch({ type: 'CLOSE_INPUT_MODAL' });
                }}
                aria-labelledby='form-dialog-title'
              >
                {loading ? (
                  <Box sx={{ m: 20 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <DialogTitle id='form-dialog-title'>
                      Editar Registro
                    </DialogTitle>
                    <form onSubmit={handleSubmit}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='evaluaciontar'
                              {...evaluacionProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Evaluación Tarima'
                                />
                              )}
                              value={registro.evaluaciontar}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  evaluaciontar: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='estatustar'
                              {...estatusProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Estatus Tarima'
                                />
                              )}
                              value={registro.estatustar}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  estatustar: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Entrada a Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                value={dayjs(new Date(registro.fechaentpr))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaentpr: formatDatetime(new Date(date)),
                                  });
                                  setError(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() =>
                            dispatch({ type: 'CLOSE_INPUT_MODAL' })
                          }
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Dialog>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {error ? <AlertError message={error} /> : ''}
            <MUIDataTable
              title='Cartón pendiente de entrar a producción'
              data={detalleCarton}
              columns={columnsCarton}
              options={{ responsive: 'standard', selectableRows: 'none' }}
            />
          </Grid>
          <Grid item xs={12}>
            {error ? <AlertError message={error} /> : ''}
            <MUIDataTable
              title='Tapa pendiente de entrar a producción'
              data={detalleEnvase}
              columns={columnsEnvase}
              options={{ responsive: 'standard', selectableRows: 'none' }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default EntradaProduccionTab;
