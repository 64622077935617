import React, { useEffect, useState } from 'react';
import ViewInfoUser from 'components/ViewInfo/ViewInfoUser';
import { Edit,   Security , Lock ,BorderColor} from '@mui/icons-material';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { Fab ,Grid } from '@mui/material';
import axios from 'axios';
import Widget from 'components/Widget/Widget';
import { useHistory } from 'react-router-dom';
import { et } from 'date-fns/locale';
import useStyles from 'pages/user/styles';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};


function Usuarios() {
  
  const classes = useStyles();
  const [formato, setFormato] = useState('CatalogoUsuarios');

  const history = useHistory();
  const [loading, setLoading] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(null);
  const [edit, setEdit] = useState(0);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    id: 0,
    cuenta: '',
    nombre: '',
    rolid: null ,
    rol:'',
    password:'' ,
    permisos:[],
    avatar: 'M',

  };

  

  const [registro, setRegistro] = useState({ ...initialRegistro });
  const [documentos, setDocumentos] = useState([]);
  const [signFile, setSignFile] = useState(null);


  const columns = [
    { label: 'ID', name: 'id', options: { display: false } },
    { label: 'Password', name: 'password', options: { display: false } },
 
    { label: 'Cuenta', name: 'cuenta' },
    { label: 'Nombre', name: 'nombre' ,

      options: {
        setCellProps: () => ({ style: { flex: 1 } })
      }
    },     
    // { label: 'Rol', name: 'rol' }, 
    { label: 'RolId', name: 'rolId' , options: { display: false } },
    {
      label: ' ',
      name: 'editar', 
      options: {
 
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container spacing={2}>
              <Fab
                color='primary'
                size='small' 
                onClick={() => {
                  setEdit(1);
                  setFormato('CatalogoUsuarios');
                  handleEdit(tableMeta.rowData[0]);
                }}>
                <Edit />
              </Fab>

              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(2);
                  setFormato('ChangePassword');
                  handleEdit(tableMeta.rowData[0]);
                }}>
                <Lock />
              </Fab>

              <Link
                to={`/app/administracion/usuarios/permisos-${tableMeta.rowData[3]}-${tableMeta.rowData[0]}`} >
              <Fab
                color='primary'
                size='small' 
              > 
                <Security />
              </Fab>
            </Link>

            <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(3);
                  setFormato('Firma');
                  handleEdit(tableMeta.rowData[0]);
                }}>
                <BorderColor />
              </Fab>
 
          
            </Grid>
          );
        },
      },
    },
     
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if(formato === 'NuevoUsuario' || formato === 'CatalogoUsuarios'){

        const body  = {
          id: registro.id, 
          userid: registro.userid, 
          cuenta: registro.cuenta,
          nombre: registro.nombre,
          rolid: registro.rolid.id, 
          password:registro.password ,
          avatar:registro.avatar 
        };
        
        // setLoading(true);
        axios
          .post('/administracion/usuarios/save', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡Se ha guardado la información exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            getDocumentos();
            dispatch({ type: 'CLOSE_INPUT_MODAL' });
            // limpiarRegistro();
            setLoading(false);
          })
          .catch((error) => {
            dispatch({ type: 'CLOSE_INPUT_MODAL' });
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      } 
      else if(formato === 'ChangePassword') { 

          const bodyPassword  = {
            id: registro.id, 
            userid: registro.userid, 
            password:registro.password ,
          };

            // setLoading(true);
        axios
        .put('/administracion/usuarios/changepassword', bodyPassword)
        .then(() => {
          Swal.fire({
            title: 'Éxito',
            text: '¡Se ha guardado la información exitosamente!',
            icon: 'success',
            confirmButtonColor: '#196b86',
          }); 
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
          // limpiarRegistro();
          setLoading(false);
        })
        .catch((error) => {
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
          Swal.fire({
            title: 'Advertencia',
            text: error.response?.data?.message,
            icon: 'warning',
            confirmButtonColor: '#196b86',
          });
          setLoading(false);
        });

      } else if(formato === 'Firma') {

         handelUploadSingFile();

        
      };
  }

  const handelUploadSingFile = () => {
    const formData = new FormData();
    formData.append('file', signFile);  

    
    axios.post(`/administracion/usuarios/upload/${registro.id}`, formData).then(() => {
      Swal.fire({
        title: 'Éxito',
        text: '¡Se ha guardado la información exitosamente!',
        icon: 'success',
        confirmButtonColor: '#196b86',
      });
      getDocumentos();
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      // limpiarRegistro();
      setLoading(false);
    }).catch((error) => {
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      Swal.fire({
        title: 'Advertencia',
        text: error.response?.data?.message,
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
      setLoading(false);
    }
    );  
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = documentos.find((x) => x.id === id);
    setRegistro({
      ...registro,
      id: id,
      cuenta: detalle.cuenta || '',
      nombre: detalle.nombre || '', 
      password: detalle.password || '',
      rolid: { id: (detalle.rolId.id || 5), name: (detalle.rol || '') } ,
      avatar: detalle.avatar || '',
    });
  };

  const handleDelete = async (idDelete) => {
    let err = false;
    let message = '';
    await axios
      .post(`/administracion/delete/usuarios/${idDelete}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/administracion/usuarios/list', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        // setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            {error ? <AlertError message={error} /> : ''}
            <ViewInfoUser
              title='usuarios'
              columns={columns}
              data={documentos}
              formato={formato} 
              edit={edit}
              setEdit={setEdit}
              handleSubmit={handleSubmit}
              initialRegistro={initialRegistro}
              setSignFile={setSignFile}
              registro={registro}
              setRegistro={setRegistro}
              setFormato={setFormato}
              handleDelete={handleDelete}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              error={error}
              state={state}
              dispatch={dispatch}
              getDocumentos={getDocumentos}
              id={'userid'} 
            />
          </Widget>
        </>
      )}
    </>
  );
}

export default Usuarios;
