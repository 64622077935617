import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import LimpiezaDiariaTab from './LimpiezaDiaria/LimpiezaDiariaTab';
import EmpaqueTerciarioTab from './EmpaqueTerciario/EmpaqueTerciarioTab';
import EvaluacionExternaPTTab from './EvaluacionExternaPT/EvaluacionExternaPTTab';
import ProductoNoConformeTab from './ProductoNoConforme/ProductoNoConformeTab';
import TiemposMuertosTab from './TiemposMuertos/TiemposMuertosTab';
import EmpaqueProductoTab from './EmpaqueProducto/EmpaqueProductoTab';
import Widget from 'components/Widget/Widget';
import {
  AccessTime,
  Checklist,
  CleaningServices,
  FactCheck,
  GppBad,
  Inventory,
} from '@mui/icons-material';

function EtapaEtiquetado() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('etiquetado')
      ? parseInt(localStorage.getItem('etiquetado'))
      : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('etiquetado', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab
                label='Registro Diario de Limpieza'
                icon={<CleaningServices />}
              />
              <Tab
                label='Evaluación de Empaque Terciario'
                icon={<FactCheck />}
              />
              <Tab
                label='Evaluación Externa de Producto Terminado'
                icon={<Checklist />}
              />
              <Tab label='Producto No Conforme en Proceso' icon={<GppBad />} />
              <Tab label='Tiempos Muertos' icon={<AccessTime />} />
              <Tab label='Empaque de Producto' icon={<Inventory />} />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <LimpiezaDiariaTab />,
              1: <EmpaqueTerciarioTab />,
              2: <EvaluacionExternaPTTab />,
              3: <ProductoNoConformeTab />,
              4: <TiemposMuertosTab />,
              5: <EmpaqueProductoTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaEtiquetado;
