import React, { useState, useRef, useEffect } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AlertError from 'components/AlertError/AlertError';
import EditIcon from '@mui/icons-material/Edit';
import Modal from 'components/Modal/Modal';
import { FileUpload, Visibility } from '@mui/icons-material';
import './Carton.css';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, isEditable } from 'functions';
import { Delete } from '@mui/icons-material';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function Carton() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [header, setHeader] = useState({
    ID: 0,
    Fecha: '',
    Folio: '',
    ProveedorID: 0,
    Proveedor: '',
    RazonSocial: '',
    Tipo: 0,
  });
  const [detalleRecepcion, setDetalleRecepcion] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [unidadMedida, setUnidadMedida] = useState([]);
  // const [evaluacionTarima, setEvaluacionTarima] = useState([]);
  const [recepcion, setRecepcion] = useState({
    userID: sessionStorage.getItem('user_id'),
    RecepcionID: id,
    detailID: 0,
    FechaRecTarima: formatDatetime(new Date()),
    tipomatprim: 2,
    LoteNum: 0,
    UnidadMedID: 0,
    UnidadMed: '',
    Cantidad: 0,
    Evaluacion: 0,
    EvaluacionTarID: 1,
    EvaluacionTar: '',
    Observaciones: '',
    CausaAcep: '',
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    // toggleInputModalPhoto: false,
  });
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const inputRef = useRef();

  const columns = [
    {
      label: 'Id',
      name: 'ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'No. De Tarima', name: 'TarimaNum' },
    { label: 'Lote', name: 'LoteNum' },
    { label: 'Cantidad', name: 'cantidad' },
    { label: 'Unidad de Medida', name: 'UnidadMed' },
    // { label: '% Evaluación', name: 'percenteval' },
    // { label: 'Evaluación de Tarima', name: 'EvaluacionTar' },
    { label: 'Observaciones', name: 'Observaciones' },
    // { label: 'Fecha de Entrada al Proceso', name: 'fecha' },
    { label: 'Causa de Aceptación', name: 'CausaAcep' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'nomarchivo' },
    {
      label: 'Ver',
      name: 'id',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target='_blank'>
              <Fab color='primary' size='small'>
                <Visibility />
              </Fab>
            </a>
          );
        },
      },
    },
  ];

  const getUnidadMedida = () => {
    axios
      .get('/catalogo/unidadesmedida', {})
      .then((res) => {
        if (res !== null) {
          setUnidadMedida(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  // const getEvaluacionTarima = () => {
  //   axios
  //     .get('/catalogo/evaluacion', {})
  //     .then((res) => {
  //       if (res !== null) {
  //         setEvaluacionTarima(res.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       setError(error);
  //     });
  // };

  const getRecepcion = () => {
    setLoading(true);
    axios
      .get(`/rmp/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalleRecepcion(res.data.data?.Detail);
        setArchivos(res.data?.data?.Files);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const unidadMedidaProps = {
    options: unidadMedida.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  // const evaluacionTarimaProps = {
  //   options: evaluacionTarima.map((option) => ({
  //     id: option.Id,
  //     name: option.Description,
  //   })),
  //   getOptionLabel: (option) => option.name,
  // };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.dataTransfer.files[i].name,
        type: e.dataTransfer.files[i].type,
        file: e.dataTransfer.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        file: e.target.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleDeleteFile = (id) => {
    const aux = files;
    for (let i = 0; i < aux.length; i++) {
      if (aux[i].id == id) {
        aux.splice(i, 1);
      }
    }
    setFiles([...aux]);
  };

  const handleSubmitArchivos = async () => {
    if (files.length > 0) {
      setLoading(true);
      let err = false;
      let message = '';

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('files', files[i].file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        await axios
          .post(`/upload/car/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 'error') {
              err = true;
              message = res.data?.message;
            } else {
              err = false;
              setFiles(null);
            }
            getRecepcion();
            setLoading(false);
          })
          .catch((error) => {
            err = true;
            message = error.response?.data?.message;
            setLoading(false);
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se han guardado los archivos exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'No se han cargado archivos.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/rmp/car/detail', recepcion)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRecepcion();
        limpiarRecepcion();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRecepcion.find((x) => x.ID === id);
    setRecepcion({
      ...recepcion,
      detailID: detalle.ID,
      // TarimaNum: detalle.TarimaNum || '',
      LoteNum: detalle.LoteNum || '',
      UnidadMedID: detalle.UnidadMedID || '',
      UnidadMed: detalle.UnidadMed || '',
      Cantidad: detalle.cantidad || '',
      Observaciones: detalle.Observaciones || '',
      CausaAcep: detalle.CausaAcep || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/rmp/delete/car/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };
        axios
          .post(`/rmp/document/finalizar`, body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push(`/app/etapas/registro-materia-prima`);
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const limpiarRecepcion = () => {
    setRecepcion({
      ...recepcion,
      userID: sessionStorage.getItem('user_id'),
      RecepcionID: id,
      detailID: 0,
      FechaRecTarima: formatDatetime(new Date()),
      tipomatprim: 2,
      LoteNum: 0,
      UnidadMedID: 0,
      UnidadMed: '',
      Cantidad: 0,
      Evaluacion: 0,
      EvaluacionTarID: 1,
      EvaluacionTar: '',
      Observaciones: '',
      CausaAcep: '',
    });
  };

  useEffect(() => {
    getRecepcion();
    getUnidadMedida();
    // getEvaluacionTarima();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.Folio}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.Fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Proveedor'
                    fullWidth
                    disabled
                    value={header.Proveedor}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Razón Social'
                    fullWidth
                    disabled
                    value={header.RazonSocial}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Cartón
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    onClick={() => {
                      setEdit(false);
                      limpiarRecepcion();
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='lote'
                                label='Lote'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    LoteNum: e.target.value,
                                  })
                                }
                                name='lote'
                                value={recepcion.LoteNum || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                }}
                                id='cantidad'
                                label='Cantidad'
                                fullWidth
                                required
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    Cantidad: e.target.value,
                                  })
                                }
                                name='cantidad'
                                value={recepcion.Cantidad || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='unidadMedida'
                                {...unidadMedidaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Unidad de Medida'
                                  />
                                )}
                                defaultValue={
                                  recepcion.UnidadMedID
                                    ? {
                                        id: recepcion.UnidadMedID,
                                        name: recepcion.UnidadMed,
                                      }
                                    : null
                                }
                                // defaultValue={{ id: 56, name: 'Piezas' }}
                                // value={{id: recepcion.UnidadMedID, name: recepcion.UnidadMed}}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setRecepcion({
                                      ...recepcion,
                                      UnidadMedID: newValue.id,
                                    });
                                  } else {
                                    setRecepcion({
                                      ...recepcion,
                                      UnidadMedID: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {/* <Grid item xs={12}>
                      <TextField
                        inputProps={{ type: 'number', min: 0, max: 100 }}
                        id='percenteval'
                        label='% Evaluación'
                        fullWidth
                        required
                        onChange={(e) => {
                          setRecepcion({
                            ...recepcion,
                            Evaluacion: e.target.value,
                          });
                          if (e.target.value > 100) {
                            setRecepcion({
                              ...recepcion,
                              Evaluacion: 100,
                            });
                          }
                          if (e.target.value < 0) {
                            setRecepcion({
                              ...recepcion,
                              Evaluacion: 0,
                            });
                          }
                        }}
                        name='percenteval'
                        value={recepcion.Evaluacion || ''}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        fullWidth
                        id='EvaluacionTarID'
                        {...evaluacionTarimaProps}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            label='Evaluación de Tarima'
                          />
                        )}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            setRecepcion({
                              ...recepcion,
                              EvaluacionTarID: newValue.id,
                            });
                          } else {
                            setRecepcion({
                              ...recepcion,
                              EvaluacionTarID: null,
                            });
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                      />
                    </Grid> */}
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                label='Observaciones'
                                fullWidth
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    Observaciones: e.target.value,
                                  })
                                }
                                name='observaciones'
                                value={recepcion.Observaciones || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='causaAceptacion'
                                label='Causa de Aceptación'
                                fullWidth
                                onChange={(e) =>
                                  setRecepcion({
                                    ...recepcion,
                                    CausaAcep: e.target.value,
                                  })
                                }
                                name='causaAceptacion'
                                value={recepcion.CausaAcep || ''}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalleRecepcion}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalleRecepcion[selectedRows.data[i].dataIndex]
                                  .ID,
                              );
                            }
                            getRecepcion();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Grid item xs={12}>
              <Widget>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap>
                    Evidencias
                  </Typography>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        setEdit(false);
                        handleSubmitArchivos();
                      }}
                    >
                      Guardar Archivos
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ margin: '0 auto' }}>
                      <div
                        className={`dropzone ${drag ? 'drag' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragEnd}
                        onDrop={handleDrop}
                      >
                        <input
                          type='file'
                          onChange={(e) => handleUpload(e)}
                          hidden
                          multiple
                          ref={inputRef}
                        />
                        <Fab
                          color='primary'
                          variant='extended'
                          aria-label='upload'
                          size='small'
                          onClick={() => inputRef.current.click()}
                        >
                          <FileUpload /> Cargar Archivo
                        </Fab>
                        <Typography
                          sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                        >
                          o arrastra los archivos
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      {files?.map((file) => (
                        <Chip
                          key={file.name}
                          label={file.name}
                          onDelete={() => handleDeleteFile(file.id)}
                        ></Chip>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <Widget>
              <Typography
                variant='h3'
                color='text'
                noWrap
                sx={{ mt: 2, mb: 2 }}
              >
                Archivos
              </Typography>
              <MUIDataTable
                title='Archivos'
                data={archivos}
                columns={columnsFiles}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default Carton;
