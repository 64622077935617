import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';

function ModalPDF({ state, dispatch, loading, pdf }) {
  return (
    <Dialog
      open={state.toggleInputModal}
      onClose={() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='xl'
    >
      {loading ? (
        <Box sx={{ m: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle id='form-dialog-title'>Documento</DialogTitle>
          <DialogContent>
            <PDFViewer width={'100%'} height={window.innerHeight - 210}>
              {/* <CarrosLimpiezaPDF id={id} /> */}
              {pdf}
            </PDFViewer>
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
            >
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ModalPDF;
