import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ModalLab from 'components/Modal/ModalLab';
import {
  CalendarMonth,
  Check,
  DeleteSweep,
  Info,
  Report,
} from '@mui/icons-material';
import AlertError from 'components/AlertError/AlertError';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatDatetime } from 'functions';
import Swal from 'sweetalert2';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_CALENDAR':
      return {
        ...state,
        toggleModalCalendar: true,
      };
    case 'CLOSE_MODAL_CALENDAR':
      return {
        ...state,
        toggleModalCalendar: false,
      };
    case 'OPEN_MODAL_CAJAS':
      return {
        ...state,
        toggleModalCajas: true,
      };
    case 'CLOSE_MODAL_CAJAS':
      return {
        ...state,
        toggleModalCajas: false,
      };
    case 'OPEN_MODAL_DEP':
      return {
        ...state,
        toggleModalDep: true,
      };
    case 'CLOSE_MODAL_DEP':
      return {
        ...state,
        toggleModalDep: false,
      };
    default:
      return null;
  }
};

const ViewInfoLab = ({
  title,
  columns,
  data,
  formato,
  handleSubmit,
  registro,
  setRegistro,
  setLoading,
  loading,
  errorModal,
  setErrorModal,
  error,
  cuarentena = false,
  almacenamiento = false,
  evaluacionesPendientes = [],
  getCajas,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalCalendar: false,
    toggleModalCajas: false,
    toggleModalDep: false,
  });
  const [filtros, setFiltros] = useState({
    tarima: null,
    caja: '',
    fechaini: null,
    fechafin: null,
  });
  const [tarimas, setTarimas] = useState([]);
  const initialCaja = {
    // userid: sessionStorage.getItem('user_id'),
    // detailid: 0,
    // almacenamientocuaid: 0,
    // estatusid: 171,
    // fechadep: null,
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    almacenamientocuaid: 0,
    tarimacuaid: 0,
    fechaprod: null,
    registrodiaela: 0,
    fechaing: null,
    loteprod: null,
    presentacionid: 0,
    productoid: 0,
    estatusid: 171,
    fechadep: null,
  };
  const [cajaSel, setCajaSel] = useState({ ...initialCaja });

  const getEstatusEv = (fecha) => {
    // const fechaEv = new Date(fecha).toLocaleDateString();
    const fechaEv = new Date(fecha);
    // const dateHoy = new Date(new Date().toLocaleDateString());
    const dateHoy = new Date();
    fechaEv.setHours(0, 0, 0, 0);
    dateHoy.setHours(0, 0, 0, 0);
    if (dateHoy > fechaEv) {
      return {
        color: '#f44336',
        icon: <Report color='white' />,
        label: 'Vencido',
      };
    } else if (dateHoy < fechaEv) {
      let diffDays = 0;
      const diffTime = Math.abs(dateHoy - fechaEv);
      diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      return {
        color: '#8bc34a',
        icon: <Check color='white' />,
        label: `En ${diffDays} días`,
      };
    } else {
      return {
        color: '#2196f3',
        icon: <Info color='white' />,
        label: 'Hoy',
      };
    }
  };

  const handleEvaluacion = (id) => {
    history.push(`/app/etapas/laboratorio/evaluacion-cuarentena-no-${id}`);
  };

  const handleDepuracion = (caja) => {
    dispatch({ type: 'OPEN_MODAL_DEP' });
    setCajaSel({
      ...cajaSel,
      detailid: caja.detailid,
      almacenamientocuaid: caja.almacenamientocuaid,
      tarimacuaid: caja.tarimacuaid,
      fechaprod: caja.fechaprod,
      registrodiaela: caja.registrodiaelaid,
      fechaing: caja.fechaing,
      loteprod: caja.loteprod,
      presentacionid: caja.presentacionid,
      productoid: caja.productoid,
      fechadep: formatDatetime(new Date()),
    });
  };

  const handleSubmitDepuracion = (e) => {
    e.preventDefault();

    // setLoading(true);
    setIsLoading(true);

    axios
      .post('/laboratorio/almacenacua/detail', cajaSel)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        dispatch({ type: 'CLOSE_MODAL_DEP' });
        getCajas();
        // setLoading(false);
        setIsLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_DEP' });
        dispatch({ type: 'CLOSE_MODAL_CAJAS' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setIsLoading(false);
      });
  };

  const tarimasProps = {
    options: tarimas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getTarimas = () => {
    axios.get('/catalogo/tarimacua', {}).then((res) => {
      setTarimas(res.data.data);
    });
  };

  useEffect(() => {
    getTarimas();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                '& a': {
                  textDecoration: 'none',
                  color: '#fff',
                },
              }}
            >
              <Typography variant='h3' color='text' noWrap>
                {title}
              </Typography>
              <Box sx={{ float: 'right', mr: 1.5 }}>
                <Grid container spacing={1}>
                  {cuarentena ? (
                    <>
                      <Grid item lg={6} xs={12}>
                        <Button
                          variant='contained'
                          onClick={() => {
                            dispatch({
                              type: !almacenamiento
                                ? 'OPEN_MODAL_CALENDAR'
                                : 'OPEN_MODAL_CAJAS',
                            });
                          }}
                        >
                          {!almacenamiento ? (
                            <CalendarMonth />
                          ) : (
                            <DeleteSweep />
                          )}
                        </Button>
                      </Grid>
                      <Grid item lg={6} xs={12}>
                        <Button
                          variant='contained'
                          onClick={() => {
                            dispatch({ type: 'OPEN_INPUT_MODAL' });
                          }}
                        >
                          Nuevo
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <Grid item lg={6} xs={12}>
                      <Button
                        variant='contained'
                        onClick={() => {
                          dispatch({ type: 'OPEN_INPUT_MODAL' });
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Box>
              {/* MODAL DE EVALUACIÓN DE CUARENTENA */}
              {cuarentena ? (
                <Dialog
                  open={state.toggleModalCalendar}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_CALENDAR' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  <DialogTitle id='form-dialog-title'>
                    Calendario Evaluaciones Pendientes
                  </DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {error ? (
                        <Grid item xs={12}>
                          <AlertError message={error} />
                        </Grid>
                      ) : (
                        ''
                      )}
                      {evaluacionesPendientes.map((evaluacion) => (
                        <Grid item xs={4} key={evaluacion.evaluacioncuarenpt}>
                          <Box>
                            <Card variant='outlined'>
                              <React.Fragment>
                                <CardContent>
                                  <Typography
                                    sx={{ fontSize: 20 }}
                                    color='text.secondary'
                                    gutterBottom
                                  >
                                    {evaluacion.evaluacioncuarenpt}
                                    <Chip
                                      sx={{
                                        ml: 1,
                                        backgroundColor: getEstatusEv(
                                          evaluacion.fechaevaest,
                                        ).color,
                                        color: 'white',
                                      }}
                                      icon={
                                        getEstatusEv(evaluacion.fechaevaest)
                                          .icon
                                      }
                                      label={
                                        getEstatusEv(evaluacion.fechaevaest)
                                          .label
                                      }
                                    />
                                  </Typography>
                                  <Typography variant='body2'>
                                    Inicio de Incubación:
                                  </Typography>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    color='text.secondary'
                                  >
                                    {evaluacion.fechainiinc}
                                  </Typography>
                                  <Typography variant='body2'>
                                    Estimación de Evaluación:
                                  </Typography>
                                  <Typography color='text.secondary'>
                                    {evaluacion.fechaevaest}
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                  <Button
                                    size='small'
                                    onClick={() =>
                                      handleEvaluacion(
                                        evaluacion.evaluacioncuarenptid,
                                      )
                                    }
                                  >
                                    Ir a Evaluación
                                  </Button>
                                </CardActions>
                              </React.Fragment>
                            </Card>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => dispatch({ type: 'CLOSE_MODAL_CALENDAR' })}
                    >
                      Cerrar
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : (
                ''
              )}
              {almacenamiento ? (
                <>
                  {/* MODAL DE ALMACENAMIENTO DE CUARENTENA - VISTA DE CAJAS */}
                  <Dialog
                    open={state.toggleModalCajas}
                    onClose={() => {
                      dispatch({ type: 'CLOSE_MODAL_CAJAS' });
                    }}
                    aria-labelledby='form-dialog-title'
                    fullWidth
                    fullScreen
                  >
                    <DialogTitle id='form-dialog-title'>
                      <Typography variant='h5'>Depuración</Typography>
                      <Typography sx={{ mt: 0.5 }}>Filtros</Typography>
                      <Box sx={{ mt: 0.5 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Autocomplete
                              fullWidth
                              id='tarima'
                              {...tarimasProps}
                              renderInput={(params) => (
                                <TextField {...params} label='Tarima' />
                              )}
                              value={filtros.tarima}
                              onChange={(event, newValue) => {
                                setFiltros({
                                  ...filtros,
                                  tarima: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='caja'
                              name='caja'
                              label='Caja'
                              fullWidth
                              value={filtros.caja}
                              onChange={(e) => {
                                setFiltros({
                                  ...filtros,
                                  caja: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                id='fechaini'
                                name='fechaini'
                                label='Fecha Inicio'
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: { fullWidth: true },
                                  required: true,
                                  actionBar: {
                                    actions: ['clear'],
                                  },
                                }}
                                value={
                                  filtros.fechaini
                                    ? dayjs(new Date(filtros.fechaini))
                                    : null
                                }
                                onChange={(date) => {
                                  setFiltros({
                                    ...filtros,
                                    fechaini: date
                                      ? formatDatetime(new Date(date))
                                      : null,
                                  });
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                id='fechafin'
                                name='fechafin'
                                label='Fecha Fin'
                                format='DD/MM/YYYY'
                                slotProps={{
                                  textField: { fullWidth: true },
                                  required: true,
                                  actionBar: {
                                    actions: ['clear'],
                                  },
                                }}
                                value={
                                  filtros.fechafin
                                    ? dayjs(new Date(filtros.fechafin))
                                    : null
                                }
                                onChange={(date) =>
                                  setFiltros({
                                    ...filtros,
                                    fechafin: date
                                      ? formatDatetime(new Date(date))
                                      : null,
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogTitle>
                    <DialogContent>
                      <Grid container spacing={2}>
                        {error ? (
                          <Grid item xs={12}>
                            <AlertError message={error} />
                          </Grid>
                        ) : (
                          ''
                        )}
                        {evaluacionesPendientes
                          .filter(
                            (tarima) =>
                              tarima.tarimacuaid ===
                              (filtros.tarima !== null
                                ? filtros.tarima.id
                                : tarima.tarimacuaid),
                          )
                          .filter(
                            (tarima) =>
                              tarima.cajanum ===
                              (filtros.caja !== ''
                                ? filtros.caja
                                : tarima.cajanum),
                          )
                          .filter(
                            (tarima) =>
                              new Date(tarima.fechaing) >=
                              (filtros.fechaini
                                ? new Date(filtros.fechaini)
                                : new Date(tarima.fechaing)),
                          )
                          .filter(
                            (tarima) =>
                              new Date(tarima.fechaing) <=
                              (filtros.fechafin
                                ? new Date(filtros.fechafin)
                                : new Date(tarima.fechaing)),
                          )
                          .map((evaluacion) => (
                            <Grid item xs={4} key={evaluacion.id}>
                              <Box>
                                <Card variant='outlined'>
                                  <React.Fragment>
                                    <CardContent>
                                      <Typography
                                        sx={{ fontSize: 20 }}
                                        color='text.secondary'
                                        gutterBottom
                                      >
                                        {evaluacion.almacenamientocua}
                                        <Chip
                                          sx={{
                                            ml: 1,
                                            backgroundColor: '#2196f3',
                                            color: 'white',
                                          }}
                                          label={`Tarima ${evaluacion.tarimacua}`}
                                        />
                                        <Chip
                                          sx={{
                                            ml: 1,
                                            backgroundColor: '#8bc34a',
                                            color: 'white',
                                          }}
                                          label={`Caja ${evaluacion.cajanum}`}
                                        />
                                      </Typography>
                                      <Typography variant='body2'>
                                        Fecha de Producción:
                                      </Typography>
                                      <Typography
                                        sx={{ mb: 1 }}
                                        color='text.secondary'
                                      >
                                        {evaluacion.fechaprod}
                                      </Typography>
                                      <Typography variant='body2'>
                                        Fecha de Ingreso:
                                      </Typography>
                                      <Typography
                                        sx={{ mb: 1 }}
                                        color='text.secondary'
                                      >
                                        {evaluacion.fechaing}
                                      </Typography>
                                      <Typography variant='body2'>
                                        Lote de Producción:
                                      </Typography>
                                      <Typography
                                        sx={{ mb: 1 }}
                                        color='text.secondary'
                                      >
                                        {evaluacion.loteprod}
                                      </Typography>
                                      <Typography variant='body2'>
                                        Producto:
                                      </Typography>
                                      <Typography color='text.secondary'>
                                        {evaluacion.producto}
                                      </Typography>
                                    </CardContent>
                                    <CardActions>
                                      <Button
                                        size='small'
                                        onClick={() => {
                                          handleDepuracion(evaluacion);
                                        }}
                                      >
                                        Depurar
                                      </Button>
                                    </CardActions>
                                  </React.Fragment>
                                </Card>
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          dispatch({ type: 'CLOSE_MODAL_CAJAS' });
                        }}
                      >
                        Cerrar
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {/* MODAL DE DEPURACIÓN DE CAJAS */}
                  <Dialog
                    open={state.toggleModalDep}
                    onClose={() => {
                      dispatch({ type: 'CLOSE_MODAL_DEP' });
                    }}
                    aria-labelledby='form-dialog-title'
                    fullWidth
                  >
                    <DialogTitle id='form-dialog-title'>Depuración</DialogTitle>
                    <form onSubmit={handleSubmitDepuracion}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechadep'
                                name='fechadep'
                                label='Fecha de Depuración'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                value={
                                  cajaSel.fechadep
                                    ? dayjs(new Date(cajaSel.fechadep))
                                    : null
                                }
                                onChange={(date) =>
                                  setCajaSel({
                                    ...cajaSel,
                                    fechadep: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => dispatch({ type: 'CLOSE_MODAL_DEP' })}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </Dialog>
                </>
              ) : (
                ''
              )}
              <ModalLab
                open={state.toggleInputModal}
                dispatch={dispatch}
                formato={formato}
                handleSubmit={handleSubmit}
                registro={registro}
                setRegistro={setRegistro}
                err={errorModal}
                setErr={setErrorModal}
                load={loading}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {loading ? <LinearProgress /> : ''}
            <MUIDataTable
              data={data}
              columns={columns}
              options={{ responsive: 'standard', selectableRows: 'none' }}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ViewInfoLab;
