import React from 'react';
import UsersTable from 'pages/CRUD/Users/table/UsersTable';

const UsersTablePage = () => {
  return (
    <div>
      <UsersTable />
    </div>
  );
};

export default UsersTablePage;
