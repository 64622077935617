export const mockUser = {
  avatars: [],
  id: '98cea92c-84e5-4c0d-ac21-9998f4b23883',
  firstName: 'Admin',
  authenticationUid: null,
  avatar: null,
  createdAt: '2020-05-12T11:04:00.864Z',
  createdById: null,
  deletedAt: null,
  disabled: false,
  email: 'admin@flatlogic.com',
  emailVerificationToken: null,
  emailVerificationTokenExpiresAt: null,
  emailVerified: true,
  importHash: null,
  lastName: null,
  password: '$2b$12$EFuj2XoxivlGr.oiIvnvDulsE5iIBTngrLlrXMM/PkO//iInslWNW',
  passwordResetToken: null,
  passwordResetTokenExpiresAt: null,
  phoneNumber: null,
  provider: 'local',
  role: 'admin',
  updatedAt: '2020-05-12T11:04:00.864Z',
  updatedById: null,
};
