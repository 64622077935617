import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import { formatDatetime } from 'functions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ModalOCB = ({
  open,
  title = 'Nuevo Registro',
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lineas, setLineas] = useState([]);

  const lineaProps = {
    options: lineas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineas = () => {
    setLoading(true);

    axios
      .get('/catalogo/lineaprod', {})
      .then((res) => {
        setLineas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getLineas();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          dispatch({ type: 'CLOSE_MODAL' });
          setErr(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      carrosLimpieza: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                // format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                // required
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                // format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='condiciones'
                              label='Condiciones'
                              fullWidth
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  condiciones: e.target.value,
                                })
                              }
                              name='condiciones'
                              value={registro.condiciones || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='acomodo'
                              label='Acomodo'
                              fullWidth
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  acomodo: e.target.value,
                                })
                              }
                              name='acomodo'
                              value={registro.acomodo || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='evalua'
                              label='Evaluación Pescado'
                              fullWidth
                              // required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  evalua: e.target.value,
                                })
                              }
                              name='evalua'
                              value={registro.evalua || ''}
                            />
                          </Grid>
                        </>
                      ),
                      fecha: (
                        <>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  fullWidth
                                  checked={registro.lineaSn || false}
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      lineaSn: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label='Capturar Línea de Producción'
                            />
                          </Grid>
                          {registro.lineaSn ? (
                            <Grid item xs={12}>
                              <Autocomplete
                                required={registro.lineaSn ? true : false}
                                fullWidth
                                id='linea'
                                {...lineaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Línea Producción'
                                  />
                                )}
                                value={registro.linea}
                                onChange={(event, newValue) => {
                                  setRegistro({
                                    ...registro,
                                    linea: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                id='fechaHora'
                                name='fechaHora'
                                label='Fecha'
                                format='DD/MM/YYYY HH:mm:ss'
                                // format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      fechaNombre: (
                        <>
                          <Grid item xs={12}>
                            <TextField
                              id='nombre'
                              label='Nombre'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  Nombre: e.target.value,
                                })
                              }
                              name='nombre'
                              value={registro.Nombre || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                id='fechaHora'
                                name='fechaHora'
                                label='Fecha y hora'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      fechaHora: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                required
                                id='fecha'
                                name='fecha'
                                label='Fecha'
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora actual'>
                                <Button variant='contained'>
                                  <AccessTimeIcon />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  required
                                  id='horaInicio'
                                  name='horaInicio'
                                  label='Hora Inicio Registro'
                                  slotProps={{
                                    textField: { fullWidth: true },
                                  }}
                                  value={dayjs(new Date(registro.horaInicio))}
                                  onChange={(date) =>
                                    setRegistro({
                                      ...registro,
                                      horaInicio: formatDatetime(
                                        new Date(date),
                                      ),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </>
                      ),
                      evaluacionOCB: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                required
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                // required
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora actual'>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setRegistro({
                                      ...registro,
                                      horaini: dayjs(new Date()),
                                    });
                                  }}
                                >
                                  <AccessTimeIcon />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  // required
                                  id='horaInicio'
                                  name='horaInicio'
                                  label='Hora Inicio Registro'
                                  slotProps={{
                                    textField: { fullWidth: true },
                                  }}
                                  value={
                                    registro.horaini
                                      ? dayjs(registro.horaini)
                                      : null
                                  }
                                  onChange={(time) => {
                                    setRegistro({
                                      ...registro,
                                      horaini: time,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </>
                      ),
                      pesoMuerto: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                required
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                required
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora arranque'>
                                <Button
                                  variant='contained'
                                  onClick={() =>
                                    setRegistro({
                                      ...registro,
                                      horaarr: new Date(),
                                    })
                                  }
                                >
                                  <AccessTimeIcon />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  required
                                  id='horaarr'
                                  name='horaarr'
                                  label='Hora Arranque'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={
                                    registro.horaarr
                                      ? dayjs(registro.horaarr)
                                      : null
                                  }
                                  onChange={(time) =>
                                    setRegistro({
                                      ...registro,
                                      horaarr: time,
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    dispatch({ type: 'CLOSE_MODAL' });
                    setErr(null);
                  }}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalOCB;
