import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { isEditable } from 'functions';
import { Cancel, Delete } from '@mui/icons-material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EvaluacionET() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsEnv, setSelectedRowsEnv] = useState([]);
  const [header, setHeader] = useState({
    evaluacionenvtapid: 0,
    evaluacionenvtap: '',
    fecha: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    documentoid: 0,
    loteprod: '',
    certificadocal: '',
    presentacionenvid: 0,
    presentacionenv: '',
    tipoenvid: 0,
    tipoenv: '',
    horaini: '',
    horafin: null,
    observaciones: null,
  });
  const [detalleTapa, setDetalleTapa] = useState([]);
  const [detalleEnvase, setDetalleEnvase] = useState([]);
  const [mediciones, setMediciones] = useState([]);
  const [valores, setValores] = useState({
    m1: {
      m1: 0,
      m2: 0,
      m3: 0,
      m4: 0,
    },
    m2: {
      m1: 0,
      m2: 0,
      m3: 0,
      m4: 0,
    },
    m3: {
      m1: 0,
      m2: 0,
      m3: 0,
      m4: 0,
    },
  });
  const [contCumple, setContCumple] = useState(0);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    // tipoenvid: null,
    // tipoenv: null,
    componenteid: 1,
    componente: 1,
    lote: '',
    medicionuno: 0,
    cumple1: 0,
    mediciondos: 0,
    cumple2: 0,
    mediciontres: 0,
    cumple3: 0,
    medicioncuatro: 0,
    cumple4: 0,
    cumple: 'NO',
    observaciones: '',
  });
  const [tipoEnv, setTipoEnv] = useState([]);
  const [componente, setComponente] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const columnsTapa = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    // { label: 'Tipo Envase', name: 'tipoenv' },
    // { label: 'Componente', name: 'componente' },
    { label: 'Lote', name: 'lote' },
    { label: 'Canal de rizo (0.142-0.156)', name: 'promedio1' },
    { label: 'Altura de rizo (0.073-0.089)', name: 'promedio2' },
    { label: 'Diám. de rizado (3.6440-3.6600)', name: 'promedio3' },
    { label: 'Countersink (0.163-0.179)', name: 'promedio4' },
    { label: '¿Cumple?', name: 'cumple' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEditTapa(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsEnvase = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    // { label: 'Tipo Envase', name: 'tipoenv' },
    // { label: 'Componente', name: 'componente' },
    { label: 'Lote', name: 'lote' },
    { label: 'Altura del cuerpo (1.3330-1.3530)', name: 'promedio1' },
    { label: 'Ancho de pestaña (0.0900-0.1100)', name: 'promedio2' },
    { label: 'Diám. Interior (3.2800-3.2900)', name: 'promedio3' },
    { label: 'Countersink (0.1100-0.1210)', name: 'promedio4' },
    { label: '¿Cumple?', name: 'cumple' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEditEnvase(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/enlatado/evaluaenvtap/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setHeader(res.data.data.Header);
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setDetalleTapa(res.data.data.DetailTapa);
        setDetalleEnvase(res.data.data.DetailEnvase);
        if (res.data.data.Header) {
          getMediciones(res.data.data.Header.tipoenv, registro.componenteid);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getTipoEnv = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/tipoenv', {})
        .then((res) => {
          if (res !== null) {
            setTipoEnv(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getMediciones = (tipoenv, componente) => {
    setLoading(true);
    try {
      axios
        .get(`/catalogo/rangosevaenla/${tipoenv}/${componente}`, {})
        .then((res) => {
          if (res !== null) {
            setMediciones(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const tipoEnvProps = {
    options: tipoEnv.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const medicionesProps = {
    options: tipoEnv.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const cont =
      parseInt(registro.cumple1) +
      parseInt(registro.cumple2) +
      parseInt(registro.cumple3) +
      parseInt(registro.cumple4);
    const body = {
      ...registro,
      componente: registro.componenteid,
      medida11: valores.m1.m1,
      medida12: valores.m2.m1,
      medida13: valores.m3.m1,
      promedio1: registro.medicionuno,
      medida21: valores.m1.m2,
      medida22: valores.m2.m2,
      medida23: valores.m3.m2,
      promedio2: registro.mediciondos,
      medida31: valores.m1.m3,
      medida32: valores.m2.m3,
      medida33: valores.m3.m3,
      promedio3: registro.mediciontres,
      medida41: valores.m1.m4,
      medida42: valores.m2.m4,
      medida43: valores.m3.m4,
      promedio4: registro.medicioncuatro,
      cumple: cont >= 3 ? 'SI' : 'NO',
      evaluacionenvtapid: header.evaluacionenvtapid,
      // tipoenvid: 114,
    };
    console.log(body);
    console.log(cont);
    axios
      .post('/enlatado/evaluaenvtap/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEditTapa = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleTapa.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      // tipoenv: { id: detalle.tipoenvid, name: detalle.tipoenv } || '',
      componenteid: detalle.componenteid || '',
      componente: detalle.componente || '',
      lote: detalle.lote || '',
      medicionuno: detalle.promedio1 || '',
      cumple1: detalle.cumple1 || '',
      mediciondos: detalle.promedio2 || '',
      cumple2: detalle.cumple2 || '',
      mediciontres: detalle.promedio3 || '',
      cumple3: detalle.cumple3 || '',
      medicioncuatro: detalle.promedio4 || '',
      cumple4: detalle.cumple4 || '',
      cumple: detalle.cumple || '',
      observaciones: detalle.observaciones || '',
    });
    setValores({
      m1: {
        m1: detalle.medida11,
        m2: detalle.medida21,
        m3: detalle.medida31,
        m4: detalle.medida41,
      },
      m2: {
        m1: detalle.medida12,
        m2: detalle.medida22,
        m3: detalle.medida32,
        m4: detalle.medida42,
      },
      m3: {
        m1: detalle.medida13,
        m2: detalle.medida23,
        m3: detalle.medida33,
        m4: detalle.medida43,
      },
    });
  };

  const handleEditEnvase = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleEnvase.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      // tipoenv: { id: detalle.tipoenvid, name: detalle.tipoenv } || '',
      componenteid: detalle.componenteid || '',
      componente: detalle.componente || '',
      lote: detalle.lote || '',
      medicionuno: detalle.promedio1 || '',
      cumple1: detalle.cumple1 || '',
      mediciondos: detalle.promedio2 || '',
      cumple2: detalle.cumple2 || '',
      mediciontres: detalle.promedio3 || '',
      cumple3: detalle.cumple3 || '',
      medicioncuatro: detalle.promedio4 || '',
      cumple4: detalle.cumple4 || '',
      cumple: detalle.cumple || '',
      observaciones: detalle.observaciones || '',
    });

    setValores({
      m1: {
        m1: detalle.medida11,
        m2: detalle.medida21,
        m3: detalle.medida31,
        m4: detalle.medida41,
      },
      m2: {
        m1: detalle.medida12,
        m2: detalle.medida22,
        m3: detalle.medida32,
        m4: detalle.medida42,
      },
      m3: {
        m1: detalle.medida13,
        m2: detalle.medida23,
        m3: detalle.medida33,
        m4: detalle.medida43,
      },
    });
  };

  const handleDeleteTapa = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/evaluaenvtap/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleDeleteEnvase = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/evaluaenvtap/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/evaluaenvtap/document/finalizar', body)
          .then((res) => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      // tipoenvid: null,
      // tipoenv: null,
      componenteid: 1,
      componente: 1,
      lote: '',
      medicionuno: 0,
      cumple1: 0,
      mediciondos: 0,
      cumple2: 0,
      mediciontres: 0,
      cumple3: 0,
      medicioncuatro: 0,
      cumple4: 0,
      cumple: 'NO',
      observaciones: '',
    });
    setValores({
      ...valores,
      m1: {
        m1: 0,
        m2: 0,
        m3: 0,
        m4: 0,
      },
      m2: {
        m1: 0,
        m2: 0,
        m3: 0,
        m4: 0,
      },
      m3: {
        m1: 0,
        m2: 0,
        m3: 0,
        m4: 0,
      },
    });
  };

  const numeroLetra = (num) => {
    switch (num) {
      case 1:
        return 'uno';
        break;
      case 2:
        return 'dos';
        break;
      case 3:
        return 'tres';
        break;
      case 4:
        return 'cuatro';
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getRegistro();
    // getTipoEnv();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.evaluacionenvtap}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Lote'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Certificado de Calidad'
                    fullWidth
                    disabled
                    value={header.certificadocal}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Presentación'
                    fullWidth
                    disabled
                    value={header.presentacionenv}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Tipo de Envase'
                    fullWidth
                    disabled
                    value={header.tipoenv}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                  mt: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Evaluación de Envase y Tapa
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistro();
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <FormControl>
                                  <FormLabel id='demo-row-radio-buttons-group-label'>
                                    Componente
                                  </FormLabel>
                                  <RadioGroup
                                    disabled={disabledDoc}
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    value={registro.componenteid}
                                    onChange={(e) => {
                                      setRegistro({
                                        ...registro,
                                        componente: e.target.value,
                                        componenteid: e.target.value,
                                      });
                                      getMediciones(
                                        header.tipoenv,
                                        e.target.value,
                                      );
                                    }}
                                  >
                                    <FormControlLabel
                                      value='1'
                                      control={<Radio />}
                                      label='Tapa'
                                    />
                                    <FormControlLabel
                                      value='2'
                                      control={<Radio />}
                                      label='Envase'
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  label='Lote'
                                  id='lote'
                                  name='lote'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      lote: e.target.value,
                                    })
                                  }
                                  value={registro.lote || ''}
                                />
                              </Grid>
                              {mediciones.map((medicion, index) => (
                                <>
                                  <Grid item xs={12}>
                                    <Box>
                                      <Typography
                                        variant='h7'
                                        color='text'
                                        noWrap
                                      >
                                        {medicion.medicion}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography
                                        variant='h7'
                                        color='#7F8C8D'
                                        noWrap
                                      >
                                        {`${medicion.rangoinf} - ${medicion.rangosup}`}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        type: 'number',
                                        min: 0,
                                      }}
                                      label='Medición 1'
                                      id='medicionuno'
                                      name='medicionuno'
                                      fullWidth
                                      required
                                      onChange={(e) => {
                                        setValores({
                                          ...valores,
                                          m1: {
                                            ...valores.m1,
                                            [`m${index + 1}`]: e.target.value,
                                          },
                                        });
                                        setRegistro({
                                          ...registro,
                                          [`medicion${numeroLetra(index + 1)}`]:
                                            (parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                            3,
                                          [`cumple${index + 1}`]:
                                            (parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                              3 >=
                                              medicion.rangoinf &&
                                            (parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                              3 <=
                                              medicion.rangosup
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      value={valores.m1[`m${index + 1}`] || ''}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        type: 'number',
                                        min: 0,
                                      }}
                                      label='Medición 2'
                                      id='medicionuno'
                                      name='medicionuno'
                                      fullWidth
                                      required
                                      onChange={(e) => {
                                        setValores({
                                          ...valores,
                                          m2: {
                                            ...valores.m2,
                                            [`m${index + 1}`]: e.target.value,
                                          },
                                        });
                                        setRegistro({
                                          ...registro,
                                          [`medicion${numeroLetra(index + 1)}`]:
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                            3,
                                          [`cumple${index + 1}`]:
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                              3 >=
                                              medicion.rangoinf &&
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(e.target.value) +
                                              parseFloat(
                                                valores.m3[`m${index + 1}`],
                                              )) /
                                              3 <=
                                              medicion.rangosup
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      value={valores.m2[`m${index + 1}`] || ''}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        type: 'number',
                                        min: 0,
                                      }}
                                      label='Medición 3'
                                      id='medicionuno'
                                      name='medicionuno'
                                      fullWidth
                                      required
                                      onChange={(e) => {
                                        setValores({
                                          ...valores,
                                          m3: {
                                            ...valores.m3,
                                            [`m${index + 1}`]: e.target.value,
                                          },
                                        });
                                        setRegistro({
                                          ...registro,
                                          [`medicion${numeroLetra(index + 1)}`]:
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(e.target.value)) /
                                            3,
                                          [`cumple${index + 1}`]:
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(e.target.value)) /
                                              3 >=
                                              medicion.rangoinf &&
                                            (parseFloat(
                                              valores.m1[`m${index + 1}`],
                                            ) +
                                              parseFloat(
                                                valores.m2[`m${index + 1}`],
                                              ) +
                                              parseFloat(e.target.value)) /
                                              3 <=
                                              medicion.rangosup
                                              ? 1
                                              : 0,
                                        });
                                      }}
                                      value={valores.m3[`m${index + 1}`] || ''}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled={disabledDoc}
                                      label='Promedio'
                                      id='medicionuno'
                                      name='medicionuno'
                                      fullWidth
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            {registro[
                                              `medicion${numeroLetra(
                                                index + 1,
                                              )}`
                                            ] >= medicion.rangoinf &&
                                            registro[
                                              `medicion${numeroLetra(
                                                index + 1,
                                              )}`
                                            ] <= medicion.rangosup ? (
                                              <CheckCircleIcon />
                                            ) : (
                                              <Cancel />
                                            )}
                                          </InputAdornment>
                                        ),
                                      }}
                                      value={
                                        registro[
                                          `medicion${numeroLetra(index + 1)}`
                                        ] || ''
                                      }
                                    />
                                  </Grid>
                                </>
                              ))}
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='observaciones'
                                  name='observaciones'
                                  label='Observaciones'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      observaciones: e.target.value,
                                    })
                                  }
                                  value={registro.observaciones || ''}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                title='Evaluación de Tapa'
                data={detalleTapa}
                columns={columnsTapa}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDeleteTapa(
                              detalleTapa[selectedRows.data[i].dataIndex].id,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
              <Box sx={{ mt: 4 }}>
                <MUIDataTable
                  title='Evaluación de Envase'
                  data={detalleEnvase}
                  columns={columnsEnvase}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRowsEnv,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRowsEnv(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteEnvase(
                                detalleEnvase[selectedRows.data[i].dataIndex]
                                  .id,
                              );
                            }
                            getRegistro();
                            setSelectedRowsEnv([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Box>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EvaluacionET;
