import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Fab,
  Grid,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import { CheckCircle, Edit } from '@mui/icons-material';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Registro from 'components/RegistroLimpieza/Registro';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import { formatDatetime, isEditable } from 'functions';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_PREOP':
      return {
        ...state,
        toggleModalPreop: true,
      };
    case 'CLOSE_MODAL_PREOP':
      return {
        ...state,
        toggleModalPreop: false,
      };
    case 'OPEN_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: true,
      };
    case 'CLOSE_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: false,
      };
    default:
      return null;
  }
};

const initialEvaluacion = [];

function OperacionOCB() {
  const { id } = useParams();
  const history = useHistory();
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalPreop: false,
    toggleModalEdit: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [header, setHeader] = useState({
    limpieza: '',
    ubicacion: '',
    estatus: '',
    fecha: '',
    fechaprod: '',
    comentarios: '',
  });
  const [detalles, setDetalles] = useState([]);
  const [registroSel, setRegistroSel] = useState(null);
  const [tiposRegistro, setTiposRegistro] = useState([]);
  const [hora, setHora] = useState(null);
  const [evaluacion, setEvaluacion] = useState([]);
  const [cumplimiento, setCumplimiento] = useState([]);
  const [cumplimientoPreop, setCumplimientoPreop] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [registroEdit, setRegistroEdit] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    limpiezaid: id,
    tipolimpieza: '',
    descripcionelem: '',
    horario: '',
    evaluacionid: null,
    observaciones: '',
  });

  const columns = [
    {
      label: 'ID',
      name: 'limpiezadetid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Limpieza Tipo ID',
      name: 'limpiezatipid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Tipo Limpieza', name: 'tipolimpieza' },
    { label: 'Elemento', name: 'descripcionelem' },
    { label: 'Horario', name: 'horario' },
    { label: 'Evaluación', name: 'evaluacion' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => handleEdit(tableMeta.rowData[0])}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleEvaluacion = (hora) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let body = {};

    let preop = false;

    let err = false;
    let message = '';

    if (registroSel === tiposRegistro[0].Id) {
      preop = true;
    }

    for (let i = 0; i < evaluacion.length; i++) {
      if (evaluacion[i].evaluacionid !== null) {
        if (preop) {
          body = {
            userid: sessionStorage.getItem('user_id'),
            detailid: 0,
            limpiezaid: id,
            limpiezatipid: registroSel,
            conceptoid: evaluacion[i].conceptoid,
            horario: null,
            evaluacionid: evaluacion[i].evaluacionid.id,
            observaciones: evaluacion[i].observaciones,
          };
        } else {
          body = {
            userid: sessionStorage.getItem('user_id'),
            detailid: 0,
            limpiezaid: id,
            limpiezatipid: registroSel,
            conceptoid: evaluacion[i].conceptoid,
            horario: hora.$d
              ? formatDatetime(new Date(hora.$d))
              : formatDatetime(new Date(hora)),
            evaluacionid: evaluacion[i].evaluacionid.id,
            observaciones: evaluacion[i].observaciones,
          };
        }

        await axios.post('/ocb/limpiezaop/detail', body).catch((error) => {
          err = true;
          message = error.response?.data?.message;
          setLoading(false);
        });
      }
    }

    if (!err) {
      Swal.fire({
        title: 'Éxito',
        text: '¡Se ha guardado la información exitosamente!',
        icon: 'success',
        confirmButtonColor: '#196b86',
      });
    } else {
      Swal.fire({
        title: 'Error',
        text: message,
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }

    setLoading(false);
    getDocumento();
    dispatch({ type: 'CLOSE_MODAL_PREOP' });
    dispatch({ type: 'CLOSE_INPUT_MODAL' });
    setEvaluacion([]);
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_MODAL_EDIT' });
    let detalle = detalles.find((x) => x.limpiezadetid === id);
    setRegistroEdit({
      ...registroEdit,
      detailid: detalle.limpiezadetid,
      limpiezatipid: detalle.limpiezatipid,
      tipolimpieza: detalle.tipolimpieza,
      descripcionelem: detalle.descripcionelem,
      horario: detalle.horario,
      evaluacionid:
        { id: detalle.evaluacionid, name: detalle.evaluacion } || null,
      observaciones: detalle.observaciones || '',
    });
  };

  const editRegistro = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...registroEdit,
      evaluacionid: registroEdit.evaluacionid.id,
    };
    axios
      .post('/ocb/limpiezaop/detail', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
        getDocumento();
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
        dispatch({ type: 'CLOSE_MODAL_EDIT' });
      });
  };

  const handleDelete = async (selectedRows) => {
    setLoading(true);
    let err = false;
    let message = '';
    for (let i = 0; i < selectedRows.data.length; i++) {
      const limpiezadetid =
        detalles[selectedRows.data[i].dataIndex].limpiezadetid;
      await axios
        .post(`/ocb/delete/limpiezaop/detail/${limpiezadetid}`)
        .catch((error) => {
          err = true;
          message = error.response?.data?.message;
        });
    }
    if (!err) {
      Swal.fire({
        title: 'Éxito',
        text: '¡Se han eliminado los registros exitosamente!',
        icon: 'success',
        confirmButtonColor: '#196b86',
      });
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: message,
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
    getDocumento();
    setSelectedRows([]);
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };
        axios
          .post('/ocb/limpiezaop/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/ocb');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);
    axios
      .get(`/ocb/limpiezaop/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalles(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getElementosEvalua = () => {
    setLoading(true);
    axios
      .get('/catalogo/elementoevalua/6/36', {})
      .then((res) => {
        initialEvaluacion.length = 0;
        for (let i = 0; i < res.data.data.length; i++) { 
          initialEvaluacion.push({
            limpiezatipid: null,
            conceptoid: res.data.data[i].Id,
            concepto: res.data.data[i].Name,
            valor: 0,
            horario: null,
            evaluacionid: null,
            observaciones: '',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getCumplimiento = () => {
    axios
      .get('/catalogo/cumplimiento', {})
      .then((res) => {
        setCumplimiento(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getCumplimientoPreop = () => {
    axios
      .get('/catalogo/cumplimientopre', {})
      .then((res) => {
        setCumplimientoPreop(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getTipoLimpieza = () => {
    setLoading(true);
    axios
      .get('/catalogo/tipolimpieza', {})
      .then((res) => {
        setTiposRegistro(res.data.data);
        setRegistroSel(res.data.data[0].Id);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const cumplimientoProps = {
    options: cumplimiento.map((option) => ({
      id: option.Id,
      valor: option.valor,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const cumplimientoPreopProps = {
    options: cumplimientoPreop.map((option) => ({
      id: option.Id,
      valor: option.valor,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  useEffect(() => {
    getDocumento();
    getElementosEvalua();
    getCumplimiento();
    getCumplimientoPreop();
    getTipoLimpieza();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.limpieza || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha'
                    fullWidth
                    disabled
                    value={header.fecha || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod || ''}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Línea Producción'
                    fullWidth
                    disabled
                    value={header.linea || ''}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Registro
                disabledDoc={disabledDoc}
                title='Registro Diario de Limpieza y Operaciones Área de OCB'
                handleEvaluacion={handleEvaluacion}
                registroSel={registroSel}
                setRegistroSel={setRegistroSel}
                tiposRegistro={tiposRegistro}
                hora={hora}
                setHora={setHora}
                data={detalles}
                columns={columns}
                state={state}
                dispatch={dispatch}
                loading={loading}
                setLoading={setLoading}
                handleSubmit={handleSubmit}
                handleEdit={editRegistro}
                error={error}
                evaluacion={evaluacion}
                setEvaluacion={setEvaluacion}
                initialState={initialEvaluacion}
                cumplimientoProps={cumplimientoProps}
                cumplimientoPreopProps={cumplimientoPreopProps}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                handleDelete={handleDelete}
                registroEdit={registroEdit}
                setRegistroEdit={setRegistroEdit}
                maximo={54}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircle />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default OperacionOCB;
