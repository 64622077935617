import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import Widget from 'components/Widget/Widget';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import { isEditable } from 'functions';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { Delete } from '@mui/icons-material';
import Swal from 'sweetalert2';

const EtapaPRMDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [detalleRde, setDetalleRde] = useState([]);
  const [inventarioPes, setInventarioPes] = useState([]);
  const [selectedRowsT, setSelectedRowsT] = useState([]);
  const [selectedRowsD, setSelectedRowsD] = useState([]);
  const [header, setHeader] = useState({
    registrodiaelaid: 0,
    registrodiaela: '',
    fechareg: '',
    fechaprod: '',
    loteprod: '',
    horaini: '',
    horafin: '',
  });

  const columnsRde = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    // { label: 'Proveedor', name: 'proveedorid' },
    { label: 'Número de Tina', name: 'Tinanum' },
    { label: 'Fecha Recepción', name: 'fecharec' },
    { label: 'Peso Neto', name: 'pesoneto' },
    { label: 'Talla', name: 'talla' },
    { label: 'Especie', name: 'especie' },
    { label: 'Proveedor', name: 'proveedor' },
  ];

  const columnsInvPes = [
    {
      label: 'Id',
      name: 'detalleid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Inventario',
      name: 'inventarioid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Número de Tina', name: 'tinanum' },
    { label: 'Fecha Recepción', name: 'fecharecepcion' },
    { label: 'Peso Neto', name: 'pesoneto' },
    { label: 'Talla', name: 'talla' },
    {
      label: 'Talla ID',
      name: 'tallaid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Especie', name: 'especie' },
    {
      label: 'Especie ID',
      name: 'especieid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Proveedor ID',
      name: 'proveedorid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Proveedor', name: 'proveedor' },
  ];

  const getRdeDetalle = () => {
    setLoading(true);
    axios
      .get(`/prm/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalleRde(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getInventarioPes = () => {
    // console.log('inventario');
    setLoading(true);
    axios
      .get(`/prm/rde/inventario`, {})
      .then((res) => {
        setInventarioPes(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  // const handleSubmitTransferencia = async (
  //   tinanum,
  //   tallaid,
  //   especieid,
  //   pesoneto,
  //   inventarioid,
  //   proveedorid,
  // ) => {
  //   const body = {
  //     userid: sessionStorage.getItem('user_id'),
  //     detailid: 0,
  //     registrodiaelaid: header.registrodiaelaid,
  //     proveedorid: proveedorid,
  //     tinanum: tinanum,
  //     tallaid: tallaid,
  //     especieid: especieid,
  //     pesoneto: pesoneto,
  //     estatusid: 30,
  //     fechaprod: header.fechaprod,
  //     inventarioid: inventarioid,
  //   };
  //   await axios.post(`/prm/rde/detail`, body).catch((error) => {
  //     setError(true);
  //     Swal.fire({
  //       title: 'Advertencia',
  //       text: error.response?.data?.message,
  //       icon: 'warning',
  //       confirmButtonColor: '#196b86',
  //     });
  //     setLoading(false);
  //   });
  // };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };
        axios
          .post('/prm/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push(`/app/etapas/planeacion-materiales`);
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const handleDeleteDetalle = async (id, inventarioid) => {
    let err = false;
    let message = '';
    await axios
      .post(`/prm/delete/rde/detail/${id}/${inventarioid}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  useEffect(() => {
    getRdeDetalle();
    getInventarioPes();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.registrodiaela}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fechareg}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote de Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Pescado Programado para Producción
                </Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  <MUIDataTable
                    data={inventarioPes}
                    columns={columnsInvPes}
                    options={{
                      responsive: 'standard',
                      selectableRows: disabledDoc ? 'none' : 'multiple',
                      rowsSelected: selectedRowsT,
                      onRowSelectionChange: (
                        rowsSelectedData,
                        allRows,
                        rowsSelected,
                      ) => {
                        setSelectedRowsT(rowsSelected);
                      },
                      customToolbarSelect: (selectedRows) => (
                        <Tooltip title='Transferir'>
                          <IconButton
                            onClick={async () => {
                              setLoading(true);
                              let err = false;
                              let message = '';
                              for (
                                let i = 0;
                                i < selectedRows.data.length;
                                i++
                              ) {
                                // await handleSubmitTransferencia(
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .detalleid,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .tinanum,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .tallaid,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .especieid,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .pesoneto,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .inventarioid,
                                //   inventarioPes[selectedRows.data[i].dataIndex]
                                //     .proveedorid,
                                // );
                                const body = {
                                  userid: sessionStorage.getItem('user_id'),
                                  detailid:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].detalleid,
                                  registrodiaelaid: header.registrodiaelaid,
                                  proveedorid:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].proveedorid,
                                  tinanum:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].tinanum,
                                  tallaid:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].tallaid,
                                  especieid:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].especieid,
                                  pesoneto:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].pesoneto,
                                  estatusid: 30,
                                  fechaprod: header.fechaprod,
                                  inventarioid:
                                    inventarioPes[
                                      selectedRows.data[i].dataIndex
                                    ].inventarioid,
                                };
                                await axios
                                  .post(`/prm/rde/detail`, body)
                                  .catch((error) => {
                                    err = true;
                                    message = error.response?.data.message;
                                    setLoading(false);
                                  });
                              }
                              getRdeDetalle();
                              getInventarioPes();
                              setSelectedRowsT([]);
                              if (!err) {
                                Swal.fire({
                                  title: 'Éxito',
                                  text: '¡Se han transferido los registros exitosamente!',
                                  icon: 'success',
                                  confirmButtonColor: '#196b86',
                                });
                              } else {
                                Swal.fire({
                                  title: 'Advertencia',
                                  text: message,
                                  icon: 'warning',
                                  confirmButtonColor: '#196b86',
                                });
                              }
                              // getRdeDetalle();
                              // actualizaInventario();
                            }}
                            style={{
                              marginRight: '24px',
                            }}
                          >
                            <SwapHorizIcon />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <MUIDataTable
                    data={detalleRde}
                    columns={columnsRde}
                    options={{
                      responsive: 'standard',
                      selectableRows: disabledDoc ? 'none' : 'multiple',
                      rowsSelected: selectedRowsD,
                      onRowSelectionChange: (
                        rowsSelectedData,
                        allRows,
                        rowsSelected,
                      ) => {
                        setSelectedRowsD(rowsSelected);
                      },
                      customToolbarSelect: (selectedRows) => (
                        <Tooltip title='Eliminar'>
                          <IconButton
                            onClick={async () => {
                              setLoading(true);
                              let response;
                              for (
                                let i = 0;
                                i < selectedRows.data.length;
                                i++
                              ) {
                                response = await handleDeleteDetalle(
                                  detalleRde[selectedRows.data[i].dataIndex].id,
                                  detalleRde[selectedRows.data[i].dataIndex]
                                    .inventarioid,
                                );
                              }
                              getRdeDetalle();
                              getInventarioPes();
                              setSelectedRowsD([]);
                              if (!response.err) {
                                Swal.fire({
                                  title: 'Éxito',
                                  text: '¡Se han eliminado los registros exitosamente!',
                                  icon: 'success',
                                  confirmButtonColor: '#196b86',
                                });
                              } else {
                                Swal.fire({
                                  title: 'Advertencia',
                                  text: response.message,
                                  icon: 'warning',
                                  confirmButtonColor: '#196b86',
                                });
                              }
                            }}
                            style={{
                              marginRight: '24px',
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
};

export default EtapaPRMDetail;
