import {
  Cancel,
  Check,
  CheckCircle,
  Clear,
  Delete,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import { isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EmpaqueTerciario() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    evaluaempterc: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    producto: '',
    loteprod: '',
    marca: '',
    marcaokid: '0',
    loteokid: '0',
    codigookid: '0',
    fechacadid: '0',
    observaciones: '',
    horaini: '',
    horafin: '',
  });
  const initialProceso = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluaemptercid: id,
    carton: '0',
    tarima: '0',
    papeleta: '0',
    observaciones: '',
  };
  const [evaluacion, setEvaluacion] = useState({ ...initialProceso });
  const [detalles, setDetalles] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Tarima Num.', name: 'tarimanum' },
    {
      label: 'Cartón',
      name: 'cartonid',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === '0' ? (
              <Cancel />
            ) : value === '1' ? (
              <CheckCircle />
            ) : (
              'N/A'
            )}
          </>
        ),
      },
    },
    {
      label: 'Tarima',
      name: 'tarimaid',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === '0' ? (
              <Cancel />
            ) : value === '1' ? (
              <CheckCircle />
            ) : (
              'N/A'
            )}
          </>
        ),
      },
    },
    {
      label: 'Papeleta',
      name: 'papeletaid',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === '0' ? (
              <Cancel />
            ) : value === '1' ? (
              <CheckCircle />
            ) : (
              'N/A'
            )}
          </>
        ),
      },
    },
    { label: 'Observaciones', name: 'observaciones' },
    { label: 'Hora Registro', name: 'horareg' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .post('/etiquetado/empaqueterc/detail', evaluacion)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleUpdateHeader = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      marcaok: header.marcaokid ? header.marcaokid : '0',
      loteok: header.loteokid ? header.loteokid : '0',
      codigook: header.codigookid ? header.codigookid : '0',
      fechacadok: header.fechacadid ? header.fechacadid : '0',
      observaciones: header.observaciones,
    };

    axios
      .post('/etiquetado/empaqueterc/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: id,
      carton: detalle.cartonid || '0',
      tarima: detalle.tarimaid || '0',
      papeleta: detalle.papeletaid || '0',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/etiquetado/delete/empaqueterc/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/etiquetado/empaqueterc/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/etiquetado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/etiquetado/empaqueterc/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumento();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.evaluaempterc}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producto'
                    fullWidth
                    disabled
                    value={header.producto}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Lote Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Marca'
                    fullWidth
                    disabled
                    value={header.marca}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Evaluación de Empaque Terciario
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setEvaluacion({ ...initialProceso });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={4}>
                              <Typography variant='h6'>Cartón</Typography>
                              <ToggleButtonGroup
                                disabled={disabledDoc}
                                color='primary'
                                value={evaluacion.carton || '0'}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) {
                                    setEvaluacion({
                                      ...evaluacion,
                                      carton: newValue,
                                    });
                                  }
                                }}
                                aria-label='Cartón'
                              >
                                <ToggleButton value='0'>
                                  <Clear />
                                </ToggleButton>
                                <ToggleButton value='1'>
                                  <Check />
                                </ToggleButton>
                                <ToggleButton value='2'>N/A</ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant='h6'>Tarima</Typography>
                              <ToggleButtonGroup
                                disabled={disabledDoc}
                                color='primary'
                                value={evaluacion.tarima || '0'}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) {
                                    setEvaluacion({
                                      ...evaluacion,
                                      tarima: newValue,
                                    });
                                  }
                                }}
                                aria-label='Tarima'
                              >
                                <ToggleButton value='0'>
                                  <Clear />
                                </ToggleButton>
                                <ToggleButton value='1'>
                                  <Check />
                                </ToggleButton>
                                <ToggleButton value='2'>N/A</ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant='h6'>Papeleta</Typography>
                              <ToggleButtonGroup
                                disabled={disabledDoc}
                                color='primary'
                                value={evaluacion.papeleta || '0'}
                                exclusive
                                onChange={(e, newValue) => {
                                  if (newValue !== null) {
                                    setEvaluacion({
                                      ...evaluacion,
                                      papeleta: newValue,
                                    });
                                  }
                                }}
                                aria-label='Papeleta'
                              >
                                <ToggleButton value='0'>
                                  <Clear />
                                </ToggleButton>
                                <ToggleButton value='1'>
                                  <Check />
                                </ToggleButton>
                                <ToggleButton value='2'>N/A</ToggleButton>
                              </ToggleButtonGroup>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={evaluacion.observaciones || ''}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <form onSubmit={handleUpdateHeader}>
                <Grid container spacing={2} sx={{ mt: 1.5 }}>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Marca impresa corresponde a la marca etiquetada.
                    </Typography>
                    <ToggleButtonGroup
                      disabled={disabledDoc}
                      color='primary'
                      value={header.marcaokid || '0'}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setHeader({
                            ...header,
                            marcaokid: newValue,
                          });
                        }
                      }}
                      aria-label='Marca'
                    >
                      <ToggleButton value='0'>
                        <Clear />
                      </ToggleButton>
                      <ToggleButton value='1'>
                        <Check />
                      </ToggleButton>
                      <ToggleButton value='2'>N/A</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Lote impreso corresponde al etiquetado.
                    </Typography>
                    <ToggleButtonGroup
                      disabled={disabledDoc}
                      color='primary'
                      value={header.loteokid || '0'}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setHeader({
                            ...header,
                            loteokid: newValue,
                          });
                        }
                      }}
                      aria-label='Lote'
                    >
                      <ToggleButton value='0'>
                        <Clear />
                      </ToggleButton>
                      <ToggleButton value='1'>
                        <Check />
                      </ToggleButton>
                      <ToggleButton value='2'>N/A</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Código de caja corresponde a la marca y presentación.
                    </Typography>
                    <ToggleButtonGroup
                      disabled={disabledDoc}
                      color='primary'
                      value={header.codigookid || '0'}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setHeader({
                            ...header,
                            codigookid: newValue,
                          });
                        }
                      }}
                      aria-label='Lote'
                    >
                      <ToggleButton value='0'>
                        <Clear />
                      </ToggleButton>
                      <ToggleButton value='1'>
                        <Check />
                      </ToggleButton>
                      <ToggleButton value='2'>N/A</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant='h6'>
                      Fecha de consumo preferente corresponde a la etiquetada.
                    </Typography>
                    <ToggleButtonGroup
                      disabled={disabledDoc}
                      color='primary'
                      value={header.fechacadid || '0'}
                      exclusive
                      onChange={(e, newValue) => {
                        if (newValue !== null) {
                          setHeader({
                            ...header,
                            fechacadid: newValue,
                          });
                        }
                      }}
                      aria-label='Lote'
                    >
                      <ToggleButton value='0'>
                        <Clear />
                      </ToggleButton>
                      <ToggleButton value='1'>
                        <Check />
                      </ToggleButton>
                      <ToggleButton value='2'>N/A</ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='observaciones'
                      name='observaciones'
                      label='Observaciones'
                      multiline
                      rows={2}
                      value={header.observaciones || ''}
                      onChange={(e) => {
                        setHeader({ ...header, observaciones: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
                {!disabledDoc ? (
                  <Box sx={{ float: 'right', mt: 2 }}>
                    <Button variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EmpaqueTerciario;
