import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Widget from 'components/Widget/Widget';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  AccessTime,
  Cancel,
  CheckCircle,
  Delete,
  Edit,
} from '@mui/icons-material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import MUIDataTable from 'mui-datatables';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { formatDatetime, isEditable } from 'functions';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EvaluacionDobleCierre() {
  const { id } = useParams();
  const history = useHistory();
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [header, setHeader] = useState({
    evaluaciondoblecie: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    presentacionenv: '',
    horaini: '',
    horafin: '',
    vaciolat: '',
    observaciones: '',
  });
  const [detalles, setDetalles] = useState([]);
  const initialState = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluaciondobcieid: id,
    lineaid: null,
    registrodiaelaid: null,
    loteprod: null,
    cabeza: 0,
    rangosevaenlaid: null,
    hora: null,
    medida11: 0,
    medida12: 0,
    medida13: 0,
    promedio1: 0,
    cumple1: 0,
    medida21: 0,
    medida22: 0,
    medida23: 0,
    promedio2: 0,
    cumple2: 0,
    medida31: 0,
    medida32: 0,
    medida33: 0,
    promedio3: 0,
    cumple3: 0,
    medida41: 0,
    medida42: 0,
    medida43: 0,
    promedio4: 0,
    cumple4: 0,
    longitudtras: 0,
    gradoarrugaid: null,
  };
  const [evaluacion, setEvaluacion] = useState({ ...initialState });
  const [lineas, setLineas] = useState([]);
  const [lotePessa, setLotePessa] = useState([]);
  const [gradosArruga, setGradosArruga] = useState([]);
  const [mediciones, setMediciones] = useState([
    { medicion: '1' },
    { medicion: '2' },
    { medicion: '3' },
    { medicion: '4' },
  ]);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Línea', name: 'linea' },
    { label: 'Lote Pessa', name: 'loteprod' },
    { label: 'Cabeza', name: 'cabeza' },
    { label: 'Medición', name: 'rangosevaenla' },
    { label: 'Hora Evaluación', name: 'horaev' },
    { label: `Promedio ${mediciones[0]?.medicion}`, name: 'promedio1' },
    { label: `Promedio ${mediciones[1]?.medicion}`, name: 'promedio2' },
    { label: `Promedio ${mediciones[2]?.medicion}`, name: 'promedio3' },
    { label: `Promedio ${mediciones[3]?.medicion}`, name: 'promedio4' },
    { label: 'Longitud del Traslape', name: 'longitudtras' },
    { label: 'Grado de Arruga', name: 'gradoarruga' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...evaluacion,
      lineaid: evaluacion.lineaid.id,
      registrodiaelaid: evaluacion.loteprod.id,
      loteprod: evaluacion.loteprod.name,
      hora: evaluacion.hora
        ? evaluacion.hora.$d
          ? formatDatetime(evaluacion.hora.$d)
          : formatDatetime(evaluacion.hora)
        : null,
      gradoarrugaid: evaluacion.gradoarrugaid.id,
      rangosevaenlaid: 17, // Dato de prueba, va a cambiar
    };

    axios
      .post('/enlatado/evaluadoblecie/detail', body)
      .then(() => {
        getDocumento();
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: detalle.id || '',
      lineaid: { id: detalle.lineaid, name: detalle.linea } || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      cabeza: detalle.cabeza || '',
      rangosevaenlaid: detalle.rangosevaenlaid || '',
      hora: new Date(detalle.horaev) || '',
      medida11: detalle.medida11 || '',
      medida12: detalle.medida12 || '',
      medida13: detalle.medida13 || '',
      promedio1: detalle.promedio1 || '',
      cumple1: detalle.cumple1 || '',
      medida21: detalle.medida21 || '',
      medida22: detalle.medida22 || '',
      medida23: detalle.medida23 || '',
      promedio2: detalle.promedio2 || '',
      cumple2: detalle.cumple2 || '',
      medida31: detalle.medida31 || '',
      medida32: detalle.medida32 || '',
      medida33: detalle.medida33 || '',
      promedio3: detalle.promedio3 || '',
      cumple3: detalle.cumple3 || '',
      medida41: detalle.medida41 || '',
      medida42: detalle.medida42 || '',
      medida43: detalle.medida43 || '',
      promedio4: detalle.promedio4 || '',
      cumple4: detalle.cumple4 || '',
      longitudtras: detalle.longitudtras || '',
      gradoarrugaid:
        { id: detalle.gradoarrugaid, name: detalle.gradoarruga } || null,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/evaluadoblecie/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleUpdateHeader = () => {
    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      vaciolat: header.vaciolat,
      observaciones: header.observaciones,
    };

    axios
      .post('/enlatado/evaluadoblecie/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/evaluadoblecie/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/enlatado/evaluadoblecie/document/${id}`)
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
          getLotePessa(res.data.data.Header.fechaprod);
        }
        setHeader(res.data.data.Header);
        setDetalles(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const lineaProps = {
    options: lineas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const lotePessaProps = {
    options: lotePessa.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const gradoArrugaProps = {
    options: gradosArruga.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineas = () => {
    setLoading(true);

    axios
      .get('/catalogo/lineaprod', {})
      .then((res) => {
        setLineas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLotePessa = (fecha) => {
    setLoading(true);

    axios
      .get(`/mezcla/lotesprd/${fecha}`, {})
      .then((res) => {
        setLotePessa(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getMediciones = () => {
    setLoading(true);

    axios
      .get('/catalogo/rangosevaenla/NA/3', {})
      .then((res) => {
        setMediciones(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getGradosArruga = () => {
    setLoading(true);

    axios
      .get('/catalogo/arruga', {})
      .then((res) => {
        setGradosArruga(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLongitudTraslape = (index, medida, e) => {
    let longitudTraslape = 0;
    switch (index) {
      case 0:
        longitudTraslape =
        parseFloat(evaluacion.promedio4) +
        parseFloat(evaluacion.promedio3) +
        0.008 -
        parseFloat(evaluacion.promedio2);
        break;
      case 1:
        longitudTraslape =
        (parseFloat(
          medida === 1 ? e.target.value : evaluacion[`medida${index + 1}1`],
        ) +
          parseFloat(
            medida === 2 ? e.target.value : evaluacion[`medida${index + 1}2`],
          ) +
          parseFloat(
            medida === 3 ? e.target.value : evaluacion[`medida${index + 1}3`],
          )) /
          3 +
          parseFloat(evaluacion.promedio3) +
          0.008 -
          parseFloat(evaluacion.promedio2);
        break;
      case 2:
        longitudTraslape =
          parseFloat(evaluacion.promedio4) +
          (parseFloat(
            medida === 1 ? e.target.value : evaluacion[`medida${index + 1}1`],
          ) +
            parseFloat(
              medida === 2 ? e.target.value : evaluacion[`medida${index + 1}2`],
            ) +
            parseFloat(
              medida === 3 ? e.target.value : evaluacion[`medida${index + 1}3`],
            )) /
            3 +
          0.008 -
          parseFloat(evaluacion.promedio2);
          break;
      case 3:
        longitudTraslape =
          (parseFloat(
            medida === 1 ? e.target.value : evaluacion[`medida${index + 1}1`],
          ) +
            parseFloat(
              medida === 2 ? e.target.value : evaluacion[`medida${index + 1}2`],
            ) +
            parseFloat(
              medida === 3 ? e.target.value : evaluacion[`medida${index + 1}3`],
            )) /
            3 +
          parseFloat(evaluacion.promedio3) +
          0.008 -
          parseFloat(evaluacion.promedio2);
          break;
      default:
        break;
    }
    return longitudTraslape;
  };

  useEffect(() => {
    getDocumento();
    getLineas();
    getMediciones();
    getGradosArruga();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.evaluaciondoblecie}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Fecha de registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Fecha de producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Presentación'
                    fullWidth
                    disabled
                    value={header.presentacionenv}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={3} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Evaluación del Doble Cierre
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setEvaluacion({ ...initialState });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='linea'
                                {...lineaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Línea'
                                  />
                                )}
                                value={evaluacion.lineaid}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    lineaid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='lotepessa'
                                {...lotePessaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote Pessa'
                                  />
                                )}
                                value={evaluacion.loteprod}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number', min: 0, max: 6 }}
                                id='cabeza'
                                name='cabeza'
                                label='Cabeza'
                                fullWidth
                                required
                                value={evaluacion.cabeza || ''}
                                onChange={(e) =>
                                  setEvaluacion({
                                    ...evaluacion,
                                    cabeza: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setEvaluacion({
                                        ...evaluacion,
                                        hora: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora Evaluación'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    value={
                                      evaluacion.hora
                                        ? dayjs(evaluacion.hora)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setEvaluacion({
                                        ...evaluacion,
                                        hora: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            {mediciones.map((medicion, index) => (
                              <React.Fragment key={medicion.Id}>
                                <Grid item xs={12}>
                                  <Typography variant='h6' color='text'>
                                    {medicion.medicion}
                                  </Typography>
                                  <Typography variant='h8' color='text'>
                                    {medicion.rangoinf} - {medicion.rangosup}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    disabled={disabledDoc}
                                    inputProps={{ type: 'number' }}
                                    id={`medida${index + 1}1`}
                                    name={`medida${index + 1}1`}
                                    label='Medida 1'
                                    fullWidth
                                    required
                                    value={
                                      evaluacion[`medida${index + 1}1`] || ''
                                    }
                                    onChange={(e) =>
                                      setEvaluacion({
                                        ...evaluacion,
                                        [`medida${index + 1}1`]: e.target.value,
                                        [`promedio${index + 1}`]:
                                          (parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                          3,
                                        [`cumple${index + 1}`]:
                                          (parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                            3 >=
                                            medicion.rangoinf &&
                                          (parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                            3 <=
                                            medicion.rangosup
                                            ? 1
                                            : 0,
                                        longitudtras: getLongitudTraslape(
                                          index,
                                          1,
                                          e,
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    disabled={disabledDoc}
                                    inputProps={{ type: 'number' }}
                                    id={`medida${index + 1}2`}
                                    name={`medida${index + 1}2`}
                                    label='Medida 2'
                                    fullWidth
                                    required
                                    value={
                                      evaluacion[`medida${index + 1}2`] || ''
                                    }
                                    onChange={(e) =>
                                      setEvaluacion({
                                        ...evaluacion,
                                        [`medida${index + 1}2`]: e.target.value,
                                        [`promedio${index + 1}`]:
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                          3,
                                        [`cumple${index + 1}`]:
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                            3 >=
                                            medicion.rangoinf &&
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(e.target.value) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}3`],
                                            )) /
                                            3 <=
                                            medicion.rangosup
                                            ? 1
                                            : 0,
                                        longitudtras: getLongitudTraslape(
                                          index,
                                          2,
                                          e,
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    disabled={disabledDoc}
                                    inputProps={{ type: 'number' }}
                                    id={`medida${index + 1}3`}
                                    name={`medida${index + 1}3`}
                                    label='Medida 3'
                                    fullWidth
                                    required
                                    value={
                                      evaluacion[`medida${index + 1}3`] || ''
                                    }
                                    onChange={(e) =>
                                      setEvaluacion({
                                        ...evaluacion,
                                        [`medida${index + 1}3`]: e.target.value,
                                        [`promedio${index + 1}`]:
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(e.target.value)) /
                                          3,
                                        [`cumple${index + 1}`]:
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(e.target.value)) /
                                            3 >=
                                            medicion.rangoinf &&
                                          (parseFloat(
                                            evaluacion[`medida${index + 1}1`],
                                          ) +
                                            parseFloat(
                                              evaluacion[`medida${index + 1}2`],
                                            ) +
                                            parseFloat(e.target.value)) /
                                            3 <=
                                            medicion.rangosup
                                            ? 1
                                            : 0,
                                        longitudtras: getLongitudTraslape(
                                          index,
                                          3,
                                          e,
                                        ),
                                      })
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    inputProps={{ type: 'number' }}
                                    id={`promedio${index + 1}4`}
                                    name={`promedio${index + 1}4`}
                                    label={`Promedio ${medicion.medicion}`}
                                    fullWidth
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='end'>
                                          {evaluacion[`promedio${index + 1}`] >=
                                            medicion.rangoinf &&
                                          evaluacion[`promedio${index + 1}`] <=
                                            medicion.rangosup ? (
                                            <CheckCircle color='primary' />
                                          ) : (
                                            <Cancel sx={{ color: 'red' }} />
                                          )}
                                        </InputAdornment>
                                      ),
                                    }}
                                    value={
                                      evaluacion[`promedio${index + 1}`] || 0
                                    }
                                  />
                                </Grid>
                              </React.Fragment>
                            ))}
                            <Grid item xs={12}>
                              <TextField
                                inputProps={{ type: 'number' }}
                                id='longitud'
                                name='longitud'
                                label='Longitud del Traslape'
                                fullWidth
                                disabled
                                value={evaluacion.longitudtras || ''}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='gradoarruga'
                                {...gradoArrugaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Grado de Arruga'
                                  />
                                )}
                                value={evaluacion.gradoarrugaid}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    gradoarrugaid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? false : true,
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1.5 }}>
                <Grid item lg={3} xs={12}>
                  <TextField
                    disabled={disabledDoc}
                    inputProps={{ type: 'number' }}
                    fullWidth
                    id='vacio'
                    name='vacio'
                    label='Vacío Lata'
                    value={header.vaciolat || ''}
                    onChange={(e) => {
                      setHeader({ ...header, vaciolat: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item lg={9} xs={12}>
                  <TextField
                    disabled={disabledDoc}
                    fullWidth
                    id='observaciones'
                    name='observaciones'
                    label='Observaciones'
                    multiline
                    rows={4}
                    value={header.observaciones || ''}
                    onChange={(e) => {
                      setHeader({ ...header, observaciones: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {!disabledDoc ? (
                <Box sx={{ float: 'right', mt: 2 }}>
                  <Button variant='contained' onClick={handleUpdateHeader}>
                    Guardar
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EvaluacionDobleCierre;
