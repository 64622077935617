import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import AlertError from 'components/AlertError/AlertError';
import { formatDatetime } from 'functions';

const ModalRMP = ({
  open,
  title = 'Nueva Recepción',
  handleSubmit,
  dispatch,
  recepcion,
  setRecepcion,
  load = false,
  err = null,
  setErr,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [proveedores, setProveedores] = useState([]);

  const proveedoresProps = {
    options: proveedores.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProveedores = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/proveedores', {})
        .then((res) => {
          if (res !== null) {
            setProveedores(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getProveedores();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
          setErr(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      fullWidth
                      id='proveedor'
                      {...proveedoresProps}
                      renderInput={(params) => (
                        <TextField required {...params} label='Proveedor' />
                      )}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          setRecepcion({
                            ...recepcion,
                            proveedorId: newValue.id,
                          });
                        } else {
                          setRecepcion({ ...recepcion, proveedorId: null });
                        }
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        required
                        id='fechaHora'
                        name='fechaHora'
                        label='Fecha y hora'
                        format='DD/MM/YYYY HH:mm:ss'
                        slotProps={{ textField: { fullWidth: true } }}
                        defaultValue={new Date(recepcion.fecha)}
                        value={dayjs(new Date(recepcion.fecha))}
                        onChange={(date) =>
                          setRecepcion({
                            ...recepcion,
                            fecha: formatDatetime(new Date(date)),
                          })
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                    setErr(null);
                  }}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalRMP;
