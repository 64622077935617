import React from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from 'components/Widget/Widget';
import {
  Biotech,
  Checklist,
  InvertColors,
  Whatshot,
} from '@mui/icons-material';
import CalidadAguaTab from './CalidadAgua/CalidadAguaTab';
import AguaAutoclavesTab from './AguaAutoclaves/AguaAutoclavesTab';
import ProcTermicoTab from './ProcTermico/ProcTermicoTab';
import BitacoraCalderaTab from './BitacoraCaldera/BitacoraCalderaTab';

function EtapaEsterilizado() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('esterilizado')
      ? parseInt(localStorage.getItem('esterilizado'))
      : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('esterilizado', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='full width tabs example'
            >
              <Tab label='Control de Calidad del Agua' icon={<Biotech />} />
              <Tab
                label='Tratamiento de Agua en Autoclaves'
                icon={<InvertColors />}
              />
              <Tab label='Proceso Térmico' icon={<Whatshot />} />
              <Tab label='Bitácora de Caldera' icon={<Checklist />} />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <CalidadAguaTab />,
              1: <AguaAutoclavesTab />,
              2: <ProcTermicoTab />,
              3: <BitacoraCalderaTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaEsterilizado;
