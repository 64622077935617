import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Modal from 'components/Modal/Modal';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, isEditable } from 'functions';
import { AccessTime, Delete, Edit, Label } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import InputAdornment from '@mui/material/InputAdornment';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_INPUT_MODAL_EVALUACION':
      return {
        ...state,
        toggleInputModalEval: true,
      };
    case 'CLOSE_INPUT_MODAL_EVALUACION':
      return {
        ...state,
        toggleInputModalEval: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function OCBLimpiezaPes() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsEv, setSelectedRowsEv] = useState([]);
  const [header, setHeader] = useState({
    evaluacionocb: 0,
    Eocbpieza: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    documentoid: 0,
    fecha: '',
    fechaprod: '',
    comentarios: '',
  });
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    hora: null,
    revisadora: '',
    limpiadora: '',
    cumpletec: 'NO',
    temperatura: 0,
    pesaje: 0,
    defectos: 0,
    gramosrev: 0,
    observaciones: '',
  });
  const [terminar, setTerminar] = useState({
    horafin: null,
    comentarios: '',
  });
  const [productos, setProductos] = useState([]);
  const [detalleRegistroEval, setDetalleRegistroEval] = useState([]);
  const [registroEval, setRegistroEval] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    revisadora: '',
    productoid: null,
    producto: null,
    evaluacion: 'NO',
    gramosdef: 0,
    observaciones: '',
  });
  const [totales, setTotales] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    evaluacionocbid: id,
    visceras: 0,
    diesel: 0,
    panalillo: 0,
    histamina: 0,
    golpeado: 0,
    oxidacion: 0,
    crudo: 0,
    olor: 0,
    amoniaco: 0,
    guano: 0,
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const columns = [
    {
      label: 'Id',
      name: 'evaluacionocbdetid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha Hora', name: 'hora' },
    { label: 'Revisadora', name: 'revisadora' },
    { label: 'Limpiadora', name: 'limpiadora' },
    { label: '¿Cumple con la Técnica?', name: 'cumpletec' },
    { label: 'Temp. °C', name: 'temperatura' },
    { label: 'Pesaje (grs.)', name: 'Pesaje' },
    { label: 'Defectos (grs.)', name: 'defectos' },
    { label: 'Rendimiento', name: 'gramosrev' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const columnsEval = [
    {
      label: 'Id',
      name: 'evaluacionrevocbid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Revisadora', name: 'revisadora' },
    { label: 'Producto', name: 'nomproducto' },
    { label: '¿Cumple con la Técnica?', name: 'evaluacion' },
    { label: 'Defectos (grs.)', name: 'gramosdef' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEditEval(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/ocb/evaluacionocb/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setDetalleRegistroEval(res.data.data.EvaluaRev);
        setTotales({
          ...totales,
          detailid: res.data.data.Totales[0]?.totaldesechoocbid,
          ...res.data.data.Totales[0],
        });
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getProductos = () => {
    // setLoading(true);
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...registro,
      evaluacionocbid: header.evaluacionocb,
      hora:
        registro.hora !== null
          ? registro.hora.$d
            ? formatDatetime(new Date(registro.hora.$d))
            : formatDatetime(new Date(registro.hora))
          : null,
    };
    axios
      .post('/ocb/evaluacionocb/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.evaluacionocbdetid === id);
    setRegistro({
      ...registro,
      detailid: id,
      hora: detalle.hora || '',
      revisadora: detalle.revisadora || '',
      limpiadora: detalle.limpiadora || '',
      cumpletec: detalle.cumpletec || '',
      temperatura: detalle.temperatura || '',
      pesaje: detalle.Pesaje || '',
      defectos: detalle.defectos || '',
      gramosrev: detalle.gramosrev || '',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/ocb/delete/evaluacionocb/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleSubmitEval = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...registroEval,
      evaluacionocbid: header.evaluacionocb,
      productoid: registroEval.producto.id,
    };

    axios
      .post('/ocb/evaluacionrevocb/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL_EVALUACION' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistroEval();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL_EVALUACION' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEditEval = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL_EVALUACION' });
    let detalle = detalleRegistroEval.find((x) => x.evaluacionrevocbid === id);
    setRegistroEval({
      ...registroEval,
      detailid: id,
      revisadora: detalle.revisadora || '',
      producto: { id: detalle.productoid, name: detalle.nomproducto } || '',
      evaluacion: detalle.evaluacion || '',
      gramosdef: detalle.gramosdef || '',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDeleteEval = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/ocb/delete/evaluacionrevocb/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = (e) => {
    e.preventDefault();

    // Swal.fire({
    //   title: '¿Está seguro de terminar el proceso?',
    //   showCancelButton: true,
    //   cancelButtonText: 'Cancelar',
    //   confirmButtonText: 'Sí',
    //   confirmButtonColor: '#196b86',
    // }).then((result) => {
    //   if (result.isConfirmed) {
    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      horafin: terminar.horafin
        ? terminar.horafin.$d
          ? formatDatetime(terminar.horafin.$d)
          : formatDatetime(terminar.horafin)
        : null,
      comentarios: terminar.comentarios,
    };
    axios
      .post(`/ocb/evaluacionocb/document/finalizar`, body)
      .then((res) => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push(`/app/etapas/ocb`);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
    //   }
    // });
  };

  const handleSubmitTotales = (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post('/ocb/totalesocb/detail', totales)
      .then((res) => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        setLoading(false);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      hora: null,
      revisadora: '',
      limpiadora: '',
      cumpletec: 'NO',
      temperatura: 0,
      pesaje: 0,
      defectos: 0,
      gramosrev: 0,
      observaciones: '',
    });
  };

  const limpiarRegistroEval = () => {
    setRegistroEval({
      ...registroEval,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      revisadora: '',
      productoid: null,
      producto: null,
      evaluacion: 'NO',
      gramosdef: 0,
      observaciones: '',
    });
  };

  const limpiarTerminar = () => {
    setTerminar({ horafin: null, comentarios: '' });
  };

  useEffect(() => {
    getRegistro();
    getProductos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.Eocbpieza}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Fecha Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Fecha Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                  mt: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Evaluación de OCB y Limpieza de Pescado
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    onClick={() => {
                      limpiarRegistro();
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Modal
                  open={state.toggleInputModal}
                  dispatch={dispatch}
                  handleSubmit={handleSubmit}
                  disabledDoc={disabledDoc}
                  form={
                    <>
                      {/* <Grid container spacing={2}> */}
                      {error ? (
                        <Grid item xs={12}>
                          <AlertError message={error} />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title='Hora Actual'>
                            <Button
                              disabled={disabledDoc}
                              variant='contained'
                              onClick={() => {
                                setRegistro({
                                  ...registro,
                                  hora: new Date(),
                                });
                              }}
                            >
                              <AccessTimeIcon />
                            </Button>
                          </Tooltip>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              disabled={disabledDoc}
                              required
                              id='horaEntrada'
                              name='horaEntrada'
                              label='Hora de Entrada'
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  required: true,
                                },
                              }}
                              value={
                                registro.hora ? dayjs(registro.hora) : null
                              }
                              onChange={(time) => {
                                setRegistro({ ...registro, hora: time });
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Revisadora'
                          id='revisadora'
                          name='revisadora'
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              revisadora: e.target.value,
                            })
                          }
                          value={registro.revisadora || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Limpiadora'
                          id='limpiadora'
                          name='limpiadora'
                          fullWidth
                          required
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              limpiadora: e.target.value,
                            })
                          }
                          value={registro.limpiadora || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ textAlign: 'left' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={disabledDoc}
                                checked={
                                  registro.cumpletec === 'SI' ? true : false
                                }
                                onChange={(e) =>
                                  setRegistro({
                                    ...registro,
                                    cumpletec: e.target.checked ? 'SI' : 'NO',
                                  })
                                }
                              />
                            }
                            label='Cumple con la técnica'
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Temperatura'
                          id='temperatura'
                          fullWidth
                          required
                          InputProps={{
                            type: 'number',
                            endAdornment: (
                              <InputAdornment position='end'>°C</InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            setRegistro({
                              ...registro,
                              temperatura: e.target.value,
                            });
                          }}
                          value={registro.temperatura || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Pesaje'
                          id='pesaje'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                grs.
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              pesaje: e.target.value,
                            })
                          }
                          value={registro.pesaje || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Defectos'
                          id='defectos'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                grs.
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              defectos: e.target.value,
                            })
                          }
                          value={registro.defectos || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          label='Rendimiento'
                          id='gramosrev'
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                grs.
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              gramosrev: e.target.value,
                            })
                          }
                          value={registro.gramosrev || ''}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled={disabledDoc}
                          id='observaciones'
                          name='observaciones'
                          label='Observaciones'
                          fullWidth
                          onChange={(e) =>
                            setRegistro({
                              ...registro,
                              observaciones: e.target.value,
                            })
                          }
                          value={registro.observaciones || ''}
                        />
                      </Grid>
                      {/* </Grid> */}
                    </>
                  }
                />
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .evaluacionocbdetid,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                  mt: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Evaluación Revisadora
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistroEval();
                        dispatch({ type: 'OPEN_INPUT_MODAL_EVALUACION' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModalEval}
                    dispatch={dispatch}
                    onClose={() =>
                      dispatch({ type: 'CLOSE_INPUT_MODAL_EVALUACION' })
                    }
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmitEval}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  label='Revisadora'
                                  id='revisadora'
                                  name='revisadora'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistroEval({
                                      ...registroEval,
                                      revisadora: e.target.value,
                                    })
                                  }
                                  value={registroEval.revisadora || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='producto'
                                  {...productosProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Producto'
                                    />
                                  )}
                                  value={registroEval.producto}
                                  onChange={(event, newValue) => {
                                    if (newValue !== null) {
                                      setRegistroEval({
                                        ...registroEval,
                                        producto: {
                                          id: newValue.id,
                                          name: newValue.name,
                                        },
                                      });
                                    } else {
                                      setRegistroEval({
                                        ...registroEval,
                                        producto: null,
                                      });
                                    }
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ textAlign: 'left' }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={disabledDoc}
                                        checked={
                                          registroEval.evaluacion === 'SI'
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setRegistroEval({
                                            ...registroEval,
                                            evaluacion: e.target.checked
                                              ? 'SI'
                                              : 'NO',
                                          })
                                        }
                                      />
                                    }
                                    label='Cumple con la técnica'
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  label='Defectos'
                                  id='defectos'
                                  fullWidth
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        grs.
                                      </InputAdornment>
                                    ),
                                  }}
                                  onChange={(e) =>
                                    setRegistroEval({
                                      ...registroEval,
                                      gramosdef: e.target.value,
                                    })
                                  }
                                  value={registroEval.gramosdef || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='observaciones'
                                  name='observaciones'
                                  label='Observaciones'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistroEval({
                                      ...registroEval,
                                      observaciones: e.target.value,
                                    })
                                  }
                                  value={registroEval.observaciones || ''}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL_EVALUACION',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistroEval}
                columns={columnsEval}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRowsEv,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRowsEv(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDeleteEval(
                              detalleRegistroEval[
                                selectedRows.data[i].dataIndex
                              ].evaluacionrevocbid,
                            );
                          }
                          getRegistro();
                          setSelectedRowsEv([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                  mt: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Limpieza de Pescado
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button variant='contained' onClick={handleSubmitTotales}>
                      Guardar
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    disabled={disabledDoc}
                    label='Visceras'
                    id='visceras'
                    fullWidth
                    // sx={{ mt: 1.5 }}
                    InputProps={{
                      type: 'number',
                      min: 0,
                      endAdornment: (
                        <InputAdornment position='end'>kg</InputAdornment>
                      ),
                    }}
                    onChange={(e) => {
                      setTotales({
                        ...totales,
                        visceras: e.target.value,
                      });
                      if (e.target.value < 0) {
                        setTotales({
                          ...totales,
                          visceras: 0,
                        });
                      }
                    }}
                    value={totales.visceras || ''}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Diesel'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          diesel: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            diesel: 0,
                          });
                        }
                      }}
                      value={totales.diesel || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Panalillo'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          panalillo: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            panalillo: 0,
                          });
                        }
                      }}
                      value={totales.panalillo || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Histamina'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          histamina: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            histamina: 0,
                          });
                        }
                      }}
                      value={totales.histamina || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Golpeado'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          golpeado: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            golpeado: 0,
                          });
                        }
                      }}
                      value={totales.golpeado || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Oxidación'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          oxidacion: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            oxidacion: 0,
                          });
                        }
                      }}
                      value={totales.oxidacion || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Crudo'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          crudo: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            crudo: 0,
                          });
                        }
                      }}
                      value={totales.crudo || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Olor'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          olor: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            olor: 0,
                          });
                        }
                      }}
                      value={totales.olor || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Amoniaco'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          amoniaco: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            amoniaco: 0,
                          });
                        }
                      }}
                      value={totales.amoniaco || ''}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} xs={6}>
                  <Box sx={{ display: 'flex' }}>
                    <TextField
                      disabled={disabledDoc}
                      label='Guano'
                      fullWidth
                      // sx={{ mt: 1.5 }}
                      InputProps={{
                        type: 'number',
                        min: 0,
                        endAdornment: (
                          <InputAdornment position='end'>kg</InputAdornment>
                        ),
                      }}
                      onChange={(e) => {
                        setTotales({
                          ...totales,
                          guano: e.target.value,
                        });
                        if (e.target.value < 0) {
                          setTotales({
                            ...totales,
                            guano: 0,
                          });
                        }
                      }}
                      value={totales.guano || ''}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={() => {
                    limpiarTerminar();
                    dispatch({ type: 'OPEN_MODAL_END' });
                  }}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
          <Dialog
            open={state.toggleModalEnd}
            onClose={() => {
              limpiarTerminar();
              dispatch({ type: 'CLOSE_MODAL_END' });
            }}
            aria-labelledby='form-dialog-title'
          >
            {loading ? (
              <Box sx={{ m: 20 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <DialogTitle id='form-dialog-title'>Terminar</DialogTitle>
                <form onSubmit={handleTerminar}>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {error ? (
                        <Grid item xs={12}>
                          <AlertError message={error} />
                        </Grid>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                          <Tooltip title='Hora actual'>
                            <Button
                              variant='contained'
                              onClick={() => {
                                setTerminar({
                                  ...terminar,
                                  horafin: dayjs(new Date()),
                                });
                              }}
                            >
                              <AccessTime />
                            </Button>
                          </Tooltip>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              required
                              id='horafin'
                              name='horafin'
                              label='Hora Fin'
                              slotProps={{
                                textField: { fullWidth: true, required: true },
                              }}
                              value={
                                terminar.horafin
                                  ? dayjs(terminar.horafin)
                                  : null
                              }
                              onChange={(time) => {
                                setTerminar({
                                  ...terminar,
                                  horafin: time,
                                });
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex' }}>
                          <TextField
                            label='Comentarios'
                            fullWidth
                            // required
                            onChange={(e) => {
                              setTerminar({
                                ...terminar,
                                comentarios: e.target.value,
                              });
                              if (e.target.value < 0) {
                                setTerminar({
                                  ...terminar,
                                  comentarios: 0,
                                });
                              }
                            }}
                            value={terminar.comentarios || ''}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() => {
                        limpiarTerminar();
                        dispatch({ type: 'CLOSE_MODAL_END' });
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button color='primary' variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </DialogActions>
                </form>
              </>
            )}
          </Dialog>
        </Grid>
      )}
    </>
  );
}

export default OCBLimpiezaPes;
