import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import { AccessTime, Delete, Edit } from '@mui/icons-material';
import Widget from 'components/Widget/Widget';
import AlertError from 'components/AlertError/AlertError';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import axios from 'axios';
import { formatDatetime, isEditable } from 'functions';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL_LOTES':
      return {
        ...state,
        toggleModalLotes: true,
      };
    case 'CLOSE_MODAL_LOTES':
      return {
        ...state,
        toggleModalLotes: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function VerifCodigos() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModalLotes: false,
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRowsL, setSelectedRowsL] = useState([]);
  const [selectedRowsA, setSelectedRowsA] = useState([]);
  const [selectedRowsD, setSelectedRowsD] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    verificacioncod: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    marca: '',
    descripcionprod: '',
    horaini: '',
    horafin: '',
  });
  const initialStateLotes = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    verificacioncodid: id,
    registrodiaelaid: null,
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    loteprod: null,
    verificaconsupref: false,
    verificacodimp: false,
    firmadec: null,
  };
  const initialStateVerif = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    verificacioncodid: id,
    lineaid: null,
    registrodiaelaid: null,
    loteprod: null,
    horaev: null,
    muestranum: 0,
    cumpleevalua: false,
    observaciones: '',
  };
  const [lote, setLote] = useState({ ...initialStateLotes });
  const [verificacion, setVerificacion] = useState({ ...initialStateVerif });
  const [lotes, setLotes] = useState([]);
  const [detallesAntes, setDetallesAntes] = useState([]);
  const [detallesDespues, setDetallesDespues] = useState([]);
  const [momentoEvalua, setMomentoEvalua] = useState(1);
  const [lineas, setLineas] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const columnsLotes = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha de Registro', name: 'fecha' },
    { label: 'Fecha de Producción', name: 'fechaprod' },
    { label: 'Verificación de Consumo Preferente', name: 'verificaconsupref' },
    { label: 'Verificación de Código Impreso', name: 'verificacodimp' },
    { label: 'Nombre Firma', name: 'nombrefir' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditLotes(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];
  const columnsAntes = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Evaluación', name: 'momentoevalua' },
    { label: 'Muestra Número', name: 'muestranum' },
    { label: 'Lata Número', name: 'latanum' },
    { label: 'Línea', name: 'linea' },
    { label: 'Lote Pessa', name: 'loteprod' },
    { label: 'Hora Evaluación', name: 'horaev' },
    { label: 'Cumple Evaluación', name: 'cumpleevalua' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setMomentoEvalua(1);
                  handleEditAntes(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];
  const columnsDespues = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Evaluación', name: 'momentoevalua' },
    { label: 'Muestra Número', name: 'muestranum' },
    { label: 'Lata Número', name: 'latanum' },
    { label: 'Línea', name: 'linea' },
    { label: 'Lote Pessa', name: 'loteprod' },
    { label: 'Hora Evaluación', name: 'horaev' },
    { label: 'Cumple Evaluación', name: 'cumpleevalua' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  setMomentoEvalua(2);
                  handleEditDespues(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmitLotes = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...lote,
      fecha: formatDatetime(new Date(lote.fecha)),
      fechaprod: formatDatetime(new Date(lote.fechaprod)),
      registrodiaelaid: lote.loteprod.id,
      loteprod: lote.loteprod.name,
      verificaconsupref: lote.verificaconsupref ? 1 : 0,
      verificacodimp: lote.verificacodimp ? 1 : 0,
      firmadec: lote.firmadec.id,
    };

    axios
      .post('/enlatado/verificagencod/detail', body)
      .then(() => {
        setLoading(false);
        dispatch({ type: 'CLOSE_MODAL_LOTES' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_LOTES' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitVerificacion = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...verificacion,
      momentoevalua: momentoEvalua,
      lineaid: verificacion.lineaid.id,
      registrodiaelaid: verificacion.loteprod.id,
      loteprod: verificacion.loteprod.name,
      horaev: verificacion.horaev
        ? verificacion.horaev.$d
          ? formatDatetime(verificacion.horaev.$d)
          : formatDatetime(verificacion.horaev)
        : null,
      cumpleevalua: verificacion.cumpleevalua ? 1 : 0,
    };

    axios
      .post('/enlatado/verificacod/detail', body)
      .then((res) => {
        setLoading(false);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        console.log(error);
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEditLotes = (id) => {
    dispatch({ type: 'OPEN_MODAL_LOTES' });
    let detalle = lotes.find((x) => x.id === id);
    setLote({
      ...lote,
      detailid: id,
      fecha: new Date(detalle.fecha) || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      verificaconsupref:
        detalle.verificaconsupref === 'SI' ? true : false || false,
      verificacodimp: detalle.verificacodimp === 'SI' ? true : false || false,
      firmadec: { id: detalle.firmadecid, name: detalle.nombrefir } || null,
    });
  };

  const handleEditAntes = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detallesAntes.find((x) => x.id === id);
    setVerificacion({
      ...verificacion,
      detailid: id,
      lineaid: { id: detalle.lineaid, name: detalle.linea } || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      horaev: detalle.horaev ? new Date(detalle.horaev) : null || null,
      muestranum: detalle.muestranum || null,
      cumpleevalua: detalle.cumpleevalua === 'SI' ? true : false || false,
      observaciones: detalle.observaciones || '',
    });
  };

  const handleEditDespues = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detallesDespues.find((x) => x.id === id);
    setVerificacion({
      ...verificacion,
      detailid: id,
      lineaid: { id: detalle.lineaid, name: detalle.linea } || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      horaev: detalle.horaev ? new Date(detalle.horaev) : null || null,
      muestranum: detalle.muestranum || null,
      cumpleevalua: detalle.cumpleevalua === 'SI' ? true : false || false,
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDeleteLotes = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/verificagencod/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleDeleteVerif = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/enlatado/delete/verificacod/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/verificacod/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/enlatado/verificacod/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setLotes(res.data.data?.Lotes);
        setDetallesAntes(res.data.data?.DetailAntes);
        setDetallesDespues(res.data.data?.DetailDespues);
        getLoteProd(res.data.data?.Header.fechaprod);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const lineasProps = {
    options: lineas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const usuariosProps = {
    options: usuarios.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineas = () => {
    axios
      .get('/catalogo/lineaprod', {})
      .then((res) => {
        setLineas(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getUsuarios = () => {
    axios
      .get('/catalogo/usuario', {})
      .then((res) => {
        setUsuarios(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getLineas();
    getUsuarios();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.verificacioncod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Marca'
                    fullWidth
                    disabled
                    value={header.marca}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producto'
                    fullWidth
                    disabled
                    value={header.descripcionprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Códigos
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setLote({ ...initialStateLotes });
                      setEdit(false);
                      dispatch({ type: 'OPEN_MODAL_LOTES' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleModalLotes}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_LOTES' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitLotes}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  required
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha de Registro'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={dayjs(new Date(lote.fecha))}
                                  onChange={(date) => {
                                    setLote({
                                      ...lote,
                                      fecha: formatDatetime(new Date(date)),
                                    });
                                    setError(null);
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  required
                                  disabled
                                  disableFuture
                                  id='fechaprod'
                                  name='fechaprod'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={dayjs(new Date(header.fechaprod))}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote Pessa'
                                  />
                                )}
                                value={lote.loteprod}
                                onChange={(event, newValue) => {
                                  setLote({
                                    ...lote,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={disabledDoc}
                                    checked={lote.verificaconsupref}
                                    onChange={(e) => {
                                      setLote({
                                        ...lote,
                                        verificaconsupref: e.target.checked,
                                      });
                                    }}
                                  />
                                }
                                label='Consumo Preferente Verificado'
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={disabledDoc}
                                    checked={lote.verificacodimp}
                                    onChange={(e) => {
                                      setLote({
                                        ...lote,
                                        verificacodimp: e.target.checked,
                                      });
                                    }}
                                  />
                                }
                                label='Código Impreso Verificado'
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='firmadec'
                                {...usuariosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Usuario de Codificado'
                                  />
                                )}
                                value={lote.firmadec}
                                onChange={(event, newValue) => {
                                  setLote({
                                    ...lote,
                                    firmadec: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_MODAL_LOTES' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={lotes}
                  columns={columnsLotes}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRowsL,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRowsL(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteLotes(
                                lotes[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRowsL([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Box>
                  <Typography variant='h3' color='text' noWrap>
                    Reporte de Verificación de Códigos
                  </Typography>
                </Box>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setVerificacion({ ...initialStateVerif });
                      setEdit(false);
                      setMomentoEvalua(1);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit
                          ? `Editar Registro (${
                              momentoEvalua === 1
                                ? 'Antes de Esterilizado'
                                : 'Después de la Autoclave'
                            })`
                          : `Nuevo Registro (${
                              momentoEvalua === 1
                                ? 'Antes de Esterilizado'
                                : 'Después de la Autoclave'
                            })`}
                      </DialogTitle>
                      <form onSubmit={handleSubmitVerificacion}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='lineaid'
                                {...lineasProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Línea'
                                  />
                                )}
                                value={verificacion.lineaid}
                                onChange={(event, newValue) => {
                                  setVerificacion({
                                    ...verificacion,
                                    lineaid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  required
                                  disabled
                                  disableFuture
                                  id='fechaprod'
                                  name='fechaprod'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  value={dayjs(new Date(header.fechaprod))}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote Pessa'
                                  />
                                )}
                                value={verificacion.loteprod}
                                onChange={(event, newValue) => {
                                  setVerificacion({
                                    ...verificacion,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setVerificacion({
                                        ...verificacion,
                                        horaev: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora Evaluación'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    value={
                                      verificacion.horaev
                                        ? dayjs(verificacion.horaev)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setVerificacion({
                                        ...verificacion,
                                        horaev: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id='muestranum'
                                name='muestranum'
                                label='Muestra Número'
                                fullWidth
                                required
                                value={verificacion.muestranum || ''}
                                onChange={(e) => {
                                  setVerificacion({
                                    ...verificacion,
                                    muestranum: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={disabledDoc}
                                    checked={verificacion.cumpleevalua}
                                    onChange={(e) => {
                                      setVerificacion({
                                        ...verificacion,
                                        cumpleevalua: e.target.checked,
                                      });
                                    }}
                                  />
                                }
                                label='Cumple Evaluación'
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={verificacion.observaciones || ''}
                                onChange={(e) => {
                                  setVerificacion({
                                    ...verificacion,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detallesAntes}
                  columns={columnsAntes}
                  title={
                    'Evaluación de la Impresión del Texto antes del Esterilizado (cada 3 horas)'
                  }
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRowsA,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRowsA(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteVerif(
                                detallesAntes[selectedRows.data[i].dataIndex]
                                  .id,
                              );
                            }
                            getDocumento();
                            setSelectedRowsA([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Box>
                  <Typography variant='h3' color='text' noWrap>
                    Reporte de Verificación de Códigos
                  </Typography>
                </Box>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setVerificacion({ ...initialStateVerif });
                      setEdit(false);
                      setMomentoEvalua(2);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detallesDespues}
                  columns={columnsDespues}
                  title={
                    'Evaluación de la Impresión del Texto después de la Autoclave'
                  }
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRowsD,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRowsD(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDeleteVerif(
                                detallesDespues[selectedRows.data[i].dataIndex]
                                  .id,
                              );
                            }
                            getDocumento();
                            setSelectedRowsD([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default VerifCodigos;
