import React, { useEffect, useState } from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import pessa from '../../../../images/PESSA.jpg';
import {
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableHeader,
} from '@david.kucsai/react-pdf-table';
import axios from 'axios';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingBottom: 88,
    paddingTop: 35,
    paddingHorizontal: 25,
  },
  title: {
    borderWidth: 1,
    padding: 5,
    backgroundColor: 'lightgray',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  image: {
    width: 100,
    marginTop: 20,
    marginLeft: 150,
  },
  contentHeader: {
    padding: 5,
    alignSelf: 'center',
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
  },
  content: {
    '@media max-width: 400': {
      flexDirection: 'column',
    },
    '@media min-width: 400': {
      flexDirection: 'row',
    },
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
  },

  row: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    fontSize: 10,
    textAlign: 'center',
  },

  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 24,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: 10,
  },
  smallTitle: {
    width: '10%',
    borderRightWidth: 1,
  },
  mediumTitle: {
    width: '25%',
  },
  small: {
    width: '10%',
    borderRightWidth: 1,
  },
  medium: {
    width: '25%',
  },

  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
    borderWidth: 1,
  },

  header: {
    fontSize: 10,
    textAlign: 'center',
    fontStyle: 'bold',
    backgroundColor: 'lightgray',
  },
  detail: {
    fontSize: 10,
    marginLeft: 1,
  },
  footer: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  numberPage: {
    position: 'absolute',
    fontSize: 12,
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  detailFooter: {
    fontSize: 10,
    textAlign: 'center',
  },
});

// Create Document Component
const EvaluacionCuarentenaPDF = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [lotes, setLotes] = useState([]);
  const [footer, setFooter] = useState([]);

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/laboratorio/evacuarentena/document/${id}`, {})
      .then((res) => {
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLotes(res.data.data?.Lotes);
        setFooter(res.data.data?.Quality);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumento();
  }, []);

  return (
    <>
      {loading ? (
        <Document>
          <Page size='A4'>
            <Text>Cargando información...</Text>
            <Text>Cierre la ventana y vuelva a abrir el documento.</Text>
          </Page>
        </Document>
      ) : (
        <Document>
          <Page size='A4' orientation='landscape' style={styles.page}>
            <View style={styles.contentHeader}>
              <Text style={styles.title}>
                REGISTRO DE EVALUACIÓN DE CUARENTENA DE PRODUCTO TERMINADO
              </Text>
            </View>
            <View style={styles.content}>
              <Text style={[styles.text]}>
                Folio: {header.evaluacioncuarenpt}
              </Text>
              <Text style={[styles.text]}>Fecha: {header.fecha}</Text>
              <Text style={[styles.text]}>
                Fecha Producción: {header.fechaprod}
              </Text>
              <Text style={[styles.text]}>
                Presentación: {header.presentacionenv}
              </Text>
            </View>
            <View style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Table data={detalles}>
                <TableHeader>
                  <TableCell weighting={0.1} style={styles.header}>
                    AUTOCLAVE
                  </TableCell>
                  <TableCell weighting={0.1} style={styles.header}>
                    DENOMINACIÓN PRODUCTO
                  </TableCell>
                  <TableCell weighting={0.05} style={styles.header}>
                    PH
                  </TableCell>
                  <TableCell weighting={0.05} style={styles.header}>
                    APARIENCIA
                  </TableCell>
                  <TableCell weighting={0.05} style={styles.header}>
                    COLOR
                  </TableCell>
                  <TableCell weighting={0.05} style={styles.header}>
                    OLOR
                  </TableCell>
                  <TableCell weighting={0.1} style={styles.header}>
                    PRESENCIA DE GAS O ESPUMA
                  </TableCell>
                  <TableCell weighting={0.1} style={styles.header}>
                    GRADO DE HINCHAZÓN
                  </TableCell>
                  <TableCell weighting={0.1} style={styles.header}>
                    RESULTADO DE EVALUACIÓN
                  </TableCell>
                  <TableCell weighting={0.3} style={styles.header}>
                    OBSERVACIONES
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell
                    weighting={0.1}
                    style={styles.detail}
                    getContent={(r) => r.procautoclave}
                  />
                  <DataTableCell
                    weighting={0.1}
                    style={styles.detail}
                    getContent={(r) => r.denominacionprod}
                  />
                  <DataTableCell
                    weighting={0.05}
                    style={styles.detail}
                    getContent={(r) => r.phreg}
                  />
                  <DataTableCell
                    weighting={0.05}
                    style={styles.detail}
                    getContent={(r) => r.apariencia}
                  />
                  <DataTableCell
                    weighting={0.05}
                    style={styles.detail}
                    getContent={(r) => r.color}
                  />
                  <DataTableCell
                    weighting={0.05}
                    style={styles.detail}
                    getContent={(r) => r.olor}
                  />
                  <DataTableCell
                    weighting={0.1}
                    style={styles.detail}
                    getContent={(r) => r.gasesp}
                  />
                  <DataTableCell
                    weighting={0.1}
                    style={styles.detail}
                    getContent={(r) => r.gradohin}
                  />
                  <DataTableCell
                    weighting={0.1}
                    style={styles.detail}
                    getContent={(r) => r.evaluacion}
                  />
                  <DataTableCell
                    weighting={0.3}
                    style={styles.detail}
                    getContent={(r) => r.observaciones}
                  />
                </TableBody>
              </Table>
            </View>
            <View style={styles.content}>
              <Text style={[styles.text, { fontSize: 12, fontStyle: 'bold' }]}>
                Lotes
              </Text>
            </View>
            <View style={{ paddingLeft: 15, paddingRight: 15 }}>
              <Table data={lotes}>
                <TableHeader>
                  <TableCell weighting={0.25} style={styles.header}>
                    FECHA PRODUCCIÓN
                  </TableCell>
                  <TableCell weighting={0.25} style={styles.header}>
                    MARCA
                  </TableCell>
                  <TableCell weighting={0.25} style={styles.header}>
                    PRODUCTO
                  </TableCell>
                  <TableCell weighting={0.25} style={styles.header}>
                    LOTE
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell
                    weighting={0.25}
                    style={styles.detail}
                    getContent={(r) => r.fechaprod}
                  />
                  <DataTableCell
                    weighting={0.25}
                    style={styles.detail}
                    getContent={(r) => r.marca}
                  />
                  <DataTableCell
                    weighting={0.25}
                    style={styles.detail}
                    getContent={(r) => r.producto}
                  />
                  <DataTableCell
                    weighting={0.25}
                    style={styles.detail}
                    getContent={(r) => r.loteprod}
                  />
                </TableBody>
              </Table>
            </View>
            <View style={[styles.contentHeader, styles.footer]}>
              <Text
                style={[styles.block, { width: 80, borderWidth: 1 }]}
              ></Text>
              <Table
                data={[
                  {
                    vigencia: `VIGENTE\n${footer.vigente}`,
                    clave: `CLAVE DEL DOCUMENTO\n${footer.clavepant}`,
                    elaboro: 'ELABORÓ\n\n\nASEG. CONTROL Y CALIDAD',
                    registro:
                      'REGISTRÓ\n\n\nDTO. ASEGURAMIENTO DE CONTROL DE CALIDAD',
                    verifico: 'VERIFICÓ\n\n\nAUDITORIA INTERNA',
                  },
                ]}
              >
                <TableHeader>
                  <TableCell style={{ fontSize: 10, textAlign: 'center' }}>
                    ASEGURAMIENTO DE CALIDAD
                  </TableCell>
                </TableHeader>
                <TableBody>
                  <DataTableCell
                    weighting={0.12}
                    style={[styles.detailFooter, { paddingTop: 15 }]}
                    getContent={(r) => r.vigencia}
                  />
                  <DataTableCell
                    weighting={0.18}
                    style={[styles.detailFooter, { paddingTop: 12 }]}
                    getContent={(r) => r.clave}
                  />
                  <DataTableCell
                    weighting={0.2}
                    style={[styles.detailFooter, { paddingTop: 2 }]}
                    getContent={(r) => r.elaboro}
                  />
                  <DataTableCell
                    weighting={0.25}
                    style={[styles.detailFooter, { paddingTop: 2 }]}
                    getContent={(r) => r.registro}
                  />
                  <DataTableCell
                    weighting={0.25}
                    style={[styles.detailFooter, { paddingTop: 2 }]}
                    getContent={(r) => r.verifico}
                  />
                </TableBody>
              </Table>
            </View>
            <Image
              style={[
                styles.footer,
                { width: 68, marginLeft: 6, marginBottom: 8 },
              ]}
              src={pessa}
            />
            {/* {footer.firmaelaid ? (
              <Image
                style={[
                  styles.footer,
                  { width: 80, marginLeft: 344, marginBottom: 26 },
                ]}
                src={footer.firmaelaid}
              />
            ) : (
              ''
            )} */}
            {footer.firmaregid ? (
              <Image
                style={[
                  styles.footer,
                  { width: 80, marginLeft: 512, marginBottom: 26 },
                ]}
                src={footer.firmaregid}
              />
            ) : (
              ''
            )}
            {footer.firmaverid ? (
              <Image
                style={[
                  styles.footer,
                  { width: 80, marginLeft: 702, marginBottom: 26 },
                ]}
                src={footer.firmaverid}
              />
            ) : (
              ''
            )}
          </Page>
        </Document>
      )}
    </>
  );
};

export default EvaluacionCuarentenaPDF;
